import { useState } from "react";
import { Option } from "fp-ts/Option";
import {
  LocalizedString,
  Box,
  Button,
  Heading,
  FileIcon,
  FieldIssues,
  Launcher,
  Stack,
  Issues,
} from "design-system";
import { useFormatMessage } from "../../intl";
import { boolean, option, task, taskEither } from "fp-ts";
import { constant, constNull, pipe } from "fp-ts/function";
import { FileImageDialog } from "../../Common/Dialogs/FileImageDialog";
import { ScannedDocument } from "../../UploadDocuments/domain";
import { GenericDocumentScanDialog } from "../../UploadDocuments/GenericDocumentScanDialog";
import { UploadModeDialog } from "../../UploadDocuments/UploadModeDialog";
import { UploadModeDialogWithMobile } from "../../UploadDocuments/UploadModeDialogWithMobile";
import { useAppContext } from "../../useAppContext";
import { UploadMode } from "../../UploadDocuments/state";
import { CoApplicantInput } from "../../globalDomain";

type Props = {
  onChange: (scannedDocument: Option<ScannedDocument>) => unknown;
  value: Option<ScannedDocument>;
  uploaded: boolean;
  disabled: boolean;
  issues: Option<Issues>;
  id?: string;
  label: LocalizedString;
  disableMobileUpload?: boolean;
} & CoApplicantInput;

type ScanStep =
  | { id: "Ready" }
  | { id: "ChooseMode" }
  | { id: "Scanning"; mode: UploadMode };

function foldScanStep<T>(
  scanStep: ScanStep,
  match: {
    whenReady: () => T;
    whenChooseMode: () => T;
    whenScanning: (mode: UploadMode) => T;
  }
) {
  switch (scanStep.id) {
    case "Ready":
      return match.whenReady();
    case "ChooseMode":
      return match.whenChooseMode();
    case "Scanning":
      return match.whenScanning(scanStep.mode);
  }
}

export function DocumentUploadField(props: Props) {
  const formatMessage = useFormatMessage();

  const {
    config: { externalCommunication },
  } = useAppContext();

  const [scanStep, setScanStep] = useState<ScanStep>({ id: "Ready" });
  const [isContentModalOpen, setIsContentModalOpen] = useState(false);

  const onDocumentAcquired = (scannedDocument: ScannedDocument) => {
    setScanStep({ id: "Ready" });
    props.onChange(
      pipe(
        scannedDocument,
        option.fromPredicate(scannedDocument => Boolean(scannedDocument.base64))
      )
    );
  };

  const onUploadModalConfirm = ({ mode }: { mode: UploadMode }) =>
    setScanStep({ id: "Scanning", mode });

  const onUploadModalDismiss = () => setScanStep({ id: "Ready" });

  const launcherOrDocument = pipe(
    props.uploaded,
    boolean.fold(
      () => (
        <Launcher
          variant="small"
          heading={props.label}
          buttonLabel={formatMessage("Upload")}
          action={() => setScanStep({ id: "ChooseMode" })}
          icon={FileIcon}
        />
      ),
      () => (
        <Stack fluid vAlignContent="center">
          <Heading size="x-small" weight="regular">
            {props.label}
          </Heading>
          <Box>
            <Button
              variant="text"
              size="default"
              label={formatMessage("Remove")}
              action={() => props.onChange(option.none)}
              disabled={props.disabled}
            />
          </Box>
        </Stack>
      )
    )
  );

  return (
    <Box column shrink>
      {launcherOrDocument}
      {foldScanStep(scanStep, {
        whenReady: constNull,
        whenChooseMode: () =>
          externalCommunication && !props.disableMobileUpload ? (
            <UploadModeDialogWithMobile
              onConfirm={onUploadModalConfirm}
              onDismiss={onUploadModalDismiss}
              coApplicant={props.coApplicant}
              type="DocumentUpload"
            />
          ) : (
            <UploadModeDialog
              onConfirm={onUploadModalConfirm}
              onDismiss={onUploadModalDismiss}
              mobileFeatureStatus="hidden"
            />
          ),
        whenScanning: (mode: UploadMode) => {
          switch (mode) {
            case "Scanner":
            case "_MockScanner":
              return (
                <GenericDocumentScanDialog
                  _mockScanner={mode === "_MockScanner"}
                  onDocumentAcquired={scannedDocument =>
                    taskEither.fromTask(
                      task.fromIO(() => onDocumentAcquired(scannedDocument))
                    )
                  }
                  onDismiss={() => setScanStep({ id: "Ready" })}
                />
              );
            case "Mobile":
              return null;
          }
        },
      })}
      {isContentModalOpen && (
        <FileImageDialog
          file={pipe(
            props.value,
            option.map(v => v.base64),
            taskEither.fromOption(constant("MissingDocument"))
          )}
          title={props.label}
          onDismiss={() => setIsContentModalOpen(false)}
          fileName={props.label}
        />
      )}
      {pipe(
        props.issues,
        option.map(issues => <FieldIssues issues={issues} />),
        option.toNullable
      )}
    </Box>
  );
}
