import { useState } from "react";
import { Option } from "fp-ts/Option";
import {
  Box,
  Button,
  FieldIssues,
  FileIcon,
  Heading,
  Issues,
  Launcher,
  LocalizedString,
  Stack,
} from "design-system";
import { boolean, option, taskEither } from "fp-ts";
import { pipe } from "fp-ts/function";
import { CoApplicantInput } from "../../globalDomain";
import { MultipleDocumentsDialog } from "./MultipleDocumentsDialog";
import { NonResidentsAdditionalDocumentsType } from "../../UKontoSecondPart/domain";
import {
  useFormatMessage,
  useFormatNonResidentsAdditionalDocumentsLabel,
} from "../../intl";
import { TaskEither } from "fp-ts/TaskEither";

type Props = {
  requiredDocuments: Array<NonResidentsAdditionalDocumentsType>;
  onUploadComplete: TaskEither<unknown, unknown>;
  onRemove: (
    requiredDocuments: Array<NonResidentsAdditionalDocumentsType>
  ) => unknown;
  uploaded: boolean;
  disabled: boolean;
  issues: Option<Issues>;
  id?: string;
  disableMobileUpload?: boolean;
} & CoApplicantInput;

export function MultipleDocumentsField(props: Props) {
  const formatMessage = useFormatMessage();
  const formatNonResidentsAdditionalDocumentsLabel = useFormatNonResidentsAdditionalDocumentsLabel();

  const [isContentModalOpen, setIsContentModalOpen] = useState(false);

  const label = props.requiredDocuments
    .map(doc => formatNonResidentsAdditionalDocumentsLabel(doc))
    .join(" + ");

  const launcherOrDocument = pipe(
    props.uploaded,
    boolean.fold(
      () => (
        <Launcher
          variant="small"
          heading={label as LocalizedString}
          buttonLabel={formatMessage("Upload")}
          action={() => setIsContentModalOpen(true)}
          icon={FileIcon}
        />
      ),
      () => (
        <Stack fluid vAlignContent="center">
          <Heading size="x-small" weight="regular">
            {label as LocalizedString}
          </Heading>
          <Box>
            <Button
              variant="text"
              size="default"
              label={formatMessage("Remove")}
              action={() => props.onRemove(props.requiredDocuments)}
              disabled={props.disabled}
            />
          </Box>
        </Stack>
      )
    )
  );

  return (
    <Box column shrink>
      {launcherOrDocument}
      {isContentModalOpen && (
        <MultipleDocumentsDialog
          requiredDocuments={props.requiredDocuments}
          onUploadComplete={taskEither.fromIO(() => {
            setIsContentModalOpen(false);
            props.onUploadComplete();
          })}
          title={label as LocalizedString}
          onDismiss={() => setIsContentModalOpen(false)}
          coApplicant={props.coApplicant}
        />
      )}
      {pipe(
        props.issues,
        option.map(issues => <FieldIssues issues={issues} />),
        option.toNullable
      )}
    </Box>
  );
}
