import { ComputedFieldProps } from "design-system";
import { useFormatMessage } from "../../intl";
import { addressWriteToDropdownOption } from "../addressFormUtils";
import { pipe } from "fp-ts/function";
import { option } from "fp-ts";
import { DropdownField } from "../../Common/DropdownField/DropdownField";
import { AddressWrite } from "../domain";
import { Option } from "fp-ts/Option";

type Props = ComputedFieldProps<Option<AddressWrite>> & {
  addresses: Array<AddressWrite>;
};

export function AddressSuggestionsDropdown(props: Props) {
  const formatMessage = useFormatMessage();

  return (
    <DropdownField
      {...props}
      value={pipe(props.value, option.map(addressWriteToDropdownOption))}
      onChange={value => {
        if (option.isSome(value)) {
          props.onChange(option.some(value.value.value));
        }
      }}
      label={formatMessage("Identification.address.address")}
      placeholder={formatMessage("Identification.address.address")}
      options={props.addresses.map(addressWriteToDropdownOption)}
      searchable
      clearable={false}
    />
  );
}
