import {
  ComputedFieldProps,
  DropdownOption,
  ReadOnlyField,
  Space,
} from "design-system";
import { Option } from "fp-ts/Option";
import { DropdownField } from "../../../Common/DropdownField/DropdownField";
import { array, nonEmptyArray, option, ord, record } from "fp-ts";
import {
  constant,
  constFalse,
  constVoid,
  identity,
  pipe,
} from "fp-ts/function";
import { NonEmptyArray } from "fp-ts/NonEmptyArray";
import { useFormatCitizenship, useFormatMessage } from "../../../intl";
import { useQuery } from "../../../useAPI";
import * as idUploadApi from "../../api";
import * as remoteData from "../../../RemoteData";
import { AllCitizenships } from "../../domain";
import { dropdownOptionToValue } from "../../../Common/selectDropdownOption";
import { YesNoRadioGroupField } from "../../../Common/YesNoRadioGroup/YesNoRadioGroupField";

type Props =
  | {
      secondCitizenshipRadio: ComputedFieldProps<Option<boolean>>;
      secondCitizenshipField: ComputedFieldProps<Option<AllCitizenships>>;
      readonly?: false;
    }
  | {
      secondCitizenshipField: Option<AllCitizenships>;
      readonly: true;
    };

export function SecondCitizenshipField(props: Props) {
  const formatCitizenship = useFormatCitizenship();
  const formatMessage = useFormatMessage();
  const countries = pipe(
    useQuery(idUploadApi.allCountries),
    ([result]) => result,
    remoteData.fold(constant([]), constant([]), identity)
  );

  const options = pipe(
    AllCitizenships.keys,
    rec => record.keys(rec) as NonEmptyArray<AllCitizenships>,
    nonEmptyArray.map(citizenship => ({
      value: citizenship,
      label: formatCitizenship(citizenship, countries),
    })),
    array.sort(
      pipe(
        ord.ordString,
        ord.contramap(
          ({ label }: DropdownOption<AllCitizenships>) => label + ""
        )
      )
    )
  );

  return !!props.readonly ? (
    <ReadOnlyField
      size="medium"
      label={formatMessage("SecondCitizenship.otherCitizenship.label")}
      value={pipe(
        props.secondCitizenshipField,
        option.fold(constant("-"), citizenship =>
          formatCitizenship(citizenship, countries)
        )
      )}
    />
  ) : (
    <>
      <YesNoRadioGroupField
        {...props.secondCitizenshipRadio}
        label={formatMessage("SecondCitizenship.label")}
      />
      {pipe(
        props.secondCitizenshipRadio.value,
        option.getOrElse(constFalse)
      ) && (
        <>
          <Space units={1} />
          <DropdownField
            {...props.secondCitizenshipField}
            value={pipe(
              props.secondCitizenshipField.value,
              option.map(selectedCitizenship =>
                pipe(
                  options,
                  array.findFirst(
                    country => country.value === selectedCitizenship
                  )
                )
              ),
              option.flatten
            )}
            onBlur={constVoid}
            options={options}
            onChange={v =>
              props.secondCitizenshipField.onChange(dropdownOptionToValue(v))
            }
            label={formatMessage("SecondCitizenship.dropdown.label")}
            placeholder={formatMessage(
              "SecondCitizenship.dropdown.placeholder"
            )}
            clearable={false}
            searchable
          />
        </>
      )}
    </>
  );
}
