import * as remoteData from "../RemoteData";
import { RemoteData } from "../RemoteData";
import {
  DropdownOption,
  LocalizedString,
  SuggestionsStatus,
  unsafeLocalizedString,
} from "design-system";
import { constant, pipe } from "fp-ts/function";
import {
  Address,
  AddressCountries,
  AddressOCR,
  AddressWrite,
  CitySuggestion,
} from "./domain";
import { NonEmptyString } from "io-ts-types/lib/NonEmptyString";
import { option } from "fp-ts";

import { CountryCode } from "../globalDomain";
import { useAppContext } from "../useAppContext";
import { CountryFlag } from "../Common/CountryFlag/CountryFlag";
import { Option } from "fp-ts/Option";

export function autocompleteInputStatusFromRemoteData<I, O>(
  query: RemoteData<unknown, I[]>,
  suggestionsMap: (suggestion: I) => DropdownOption<O>
): SuggestionsStatus<O> {
  return pipe(
    query,
    remoteData.fold(
      constant({
        status: "loading",
      } as SuggestionsStatus<O>),
      constant({ status: "ready", suggestions: [] }),
      (suggestions, isLoading) =>
        isLoading
          ? {
              status: "loading",
            }
          : {
              status: "ready",
              suggestions: suggestions.map(suggestionsMap),
            }
    )
  );
}

export type AddressFormState = {
  streetName: string;
  streetNumber: string;
  city: string;
  provinceName: string;
  zipCode: string;
  country: CountryCode;
  confirmed: boolean;
};

export type AddressSuggestionFormState = {
  address: Option<AddressWrite>;
};

export function formStateFromAddress(address: Address): AddressFormState {
  return {
    ...address,
    city: pipe(
      address.city,
      option.fold(constant(""), () =>
        formatProvinceCity(address.provinceName, address.city)
      )
    ),
    zipCode: pipe(address.zipCode, option.getOrElse(constant(""))),
    streetName: pipe(address.streetName, option.getOrElse(constant(""))),
    streetNumber: pipe(address.streetNumber, option.getOrElse(constant(""))),
    provinceName: pipe(address.provinceName, option.getOrElse(constant(""))),
    confirmed: false,
  };
}

export function formStateFromAddressOCR(
  address: AddressOCR,
  defaultCountry: CountryCode
): AddressFormState {
  return {
    streetName: pipe(address.streetName, option.getOrElse(constant(""))),
    streetNumber: pipe(address.streetNumber, option.getOrElse(constant(""))),
    city: formatProvinceCity(address.provinceName, address.city),
    zipCode: pipe(address.zipCode, option.getOrElse(constant(""))),
    country: pipe(address.country, option.getOrElse(constant(defaultCountry))),
    provinceName: pipe(address.provinceName, option.getOrElse(constant(""))),
    confirmed: false,
  };
}

export function countryToDropdownOption(
  country: AddressCountries[number]
): DropdownOption<CountryCode> {
  return {
    value: country.countryCode,
    label: country.label,
    icon: pipe(
      country.tenant,
      option.map(tenant => <CountryFlag tenant={tenant} />),
      option.toUndefined
    ),
  };
}

export function addressWriteToDropdownOption(
  address: AddressWrite
): DropdownOption<AddressWrite> {
  const unwrapOptionalString = (value: Option<NonEmptyString>) =>
    pipe(
      value,
      option.getOrElse(() => "")
    );

  return {
    value: address,
    label: unsafeLocalizedString(
      `${unwrapOptionalString(address.streetName)}, ${
        address.provinceName
      }, ${unwrapOptionalString(address.streetNumber)}, ${unwrapOptionalString(
        address.zipCode
      )} ${unwrapOptionalString(address.city)}`
    ),
  };
}

export function mapCountriesToOptions(
  countries: AddressCountries
): Array<DropdownOption<CountryCode>> {
  return countries.map(countryToDropdownOption);
}

export function getCitySuggestionLabel({
  cityName,
  provinceName,
}: CitySuggestion) {
  return unsafeLocalizedString(`${cityName}, ${provinceName}`);
}

export function formatProvinceCity(
  provinceName: Option<NonEmptyString>,
  cityName: Option<NonEmptyString>
): LocalizedString {
  return pipe(
    cityName,
    option.map(city =>
      pipe(provinceName, option.fold(constant(city), constant(city)))
    ),
    option.getOrElse(constant("")),
    unsafeLocalizedString
  );
}

export function useDefaultCountryCode(): CountryCode {
  const {
    apiParameters: { tenant },
  } = useAppContext();
  switch (tenant) {
    case "CZ":
      return "CZE" as CountryCode;
    case "SK":
      return "SVK" as CountryCode;
  }
}

export function addressWriteToAddress(a: AddressWrite): Address {
  return { ...a };
}

export function addressWriteToAddressOCR(a: AddressWrite): AddressOCR {
  return {
    ...a,
    streetName: a.streetName,
    streetNumber: a.streetNumber,
    city: a.city,
    country: option.some(a.country),
    provinceName: a.provinceName,
  };
}
