import {
  Body,
  Box,
  Space,
  LocalizedString,
  ErrorBanner,
  Loader,
  Dialog,
} from "design-system";
import { array, option, task, taskEither } from "fp-ts";
import {
  useFormatMessage,
  useFormatNonResidentsAdditionalDocumentsLabel,
} from "../../intl";
import { constant, constNull, constVoid, pipe } from "fp-ts/function";
import { NextButton } from "../../Common/NextButton";
import { useCommand, useQuery } from "../../useAPI";

import { Option } from "fp-ts/Option";
import { ScannedDocument } from "../../UploadDocuments/domain";
import { CoApplicantInput } from "../../globalDomain";
import * as uploadApi from "../api";
import { DocumentUploadField } from "./DocumentUploadField";
import { NonResidentsAdditionalDocumentsType } from "../../UKontoSecondPart/domain";
import { Task } from "fp-ts/Task";
import { base64FileSize } from "../PermanentAddress/base64FileSize";
import { useState } from "react";
import * as ukontoApi from "../../UKontoSecondPart/api";
import * as remoteData from "../../RemoteData";
import { TaskEither } from "fp-ts/TaskEither";

type Error = "SizeExceeding" | "FailureUpload";

type Props = {
  requiredDocuments: Array<NonResidentsAdditionalDocumentsType>;
  onUploadComplete: TaskEither<unknown, unknown>;
  onDismiss: () => unknown;
  title: LocalizedString;
  id?: string;
} & CoApplicantInput;

export function MultipleDocumentsDialog(props: Props) {
  const formatMessage = useFormatMessage();
  const formatNonResidentsAdditionalDocumentsLabel = useFormatNonResidentsAdditionalDocumentsLabel();

  const [error, setError] = useState<Option<Error>>(option.none);
  const uploadDocument = useCommand(uploadApi.additionalDocumentUpload);
  const removeDocument = useCommand(uploadApi.additionalDocumentRemove);
  const checkAllDocsUploaded = useCommand(ukontoApi.checkAllDocsUploaded);
  const [getAdditionalRequiredDocuments, refreshList] = useQuery(
    ukontoApi.getAdditionalRequiredDocuments,
    {
      requiredKeys: option.some(props.requiredDocuments),
    }
  );
  const [allDocumentsUploaded, setAllDocumentsUploaded] = useState(false);

  const maxMB = 20000000;

  const formatError = (error: Error): LocalizedString => {
    switch (error) {
      case "FailureUpload":
        return formatMessage("Identification.UploadDocuments.failure.message");
      case "SizeExceeding":
        return formatMessage(
          "Identification.UploadDocuments.failure.sizeExceeding"
        );
    }
  };

  const onDocumentUpload = (
    documentKey: NonResidentsAdditionalDocumentsType,
    fileContent: ScannedDocument
  ): Task<unknown> => {
    return pipe(
      fileContent,
      taskEither.fromPredicate(
        scannedDocument => base64FileSize(scannedDocument.base64) < maxMB,
        constant<Error>("SizeExceeding")
      ),
      taskEither.chain(file =>
        pipe(
          uploadDocument({
            documentKey,
            fileContent: file.base64,
            coApplicant: props.coApplicant,
          }),
          taskEither.mapLeft(constant<Error>("FailureUpload"))
        )
      ),
      taskEither.fold(
        m => taskEither.fromIO(() => setError(option.some(m))),
        () =>
          pipe(
            taskEither.fromIO(() => {
              setError(option.none);
              refreshList();
            }),
            taskEither.chain(() =>
              checkAllDocsUploaded({ requiredKeys: props.requiredDocuments })
            ),
            taskEither.chain(response =>
              taskEither.fromIO(() => {
                setAllDocumentsUploaded(response.allDocsUploaded);
              })
            )
          )
      )
    );
  };

  const onDocumentRemove = (
    documentKey: NonResidentsAdditionalDocumentsType
  ): Task<unknown> => {
    return pipe(
      removeDocument({ documentKey, coApplicant: props.coApplicant }),
      taskEither.fold(
        () => task.fromIO(constVoid),
        () =>
          task.fromIO(() => {
            setError(option.none);
            setAllDocumentsUploaded(false);
            refreshList();
          })
      )
    );
  };

  const onDocumentChange = (
    documentKey: NonResidentsAdditionalDocumentsType
  ) => (fileContent: Option<ScannedDocument>): unknown => {
    return pipe(
      fileContent,
      option.fold(
        () => onDocumentRemove(documentKey),
        file => onDocumentUpload(documentKey, file)
      )
    )();
  };

  const loadingError = () => (
    <ErrorBanner>
      {formatMessage("StandardLoan.UploadDocuments.loadingError")}
    </ErrorBanner>
  );

  const loader = () => (
    <Box hAlignContent="center">
      <Loader />
    </Box>
  );

  const renderDocUploadField = (
    documentKey: NonResidentsAdditionalDocumentsType,
    uploaded: boolean
  ) => (
    <Box column grow shrink>
      <Space units={4} />
      <Box column>
        <DocumentUploadField
          onChange={onDocumentChange(documentKey)}
          value={option.none}
          uploaded={uploaded}
          issues={option.none}
          label={formatNonResidentsAdditionalDocumentsLabel(documentKey)}
          disabled={false}
          disableMobileUpload
          coApplicant={props.coApplicant}
        />
      </Box>
    </Box>
  );

  const documentList = () => {
    return pipe(
      getAdditionalRequiredDocuments,
      remoteData.fold(loader, loadingError, response => {
        return pipe(
          response.additionalRequiredDocuments,
          option.fold(loadingError, documents => {
            return (
              <Box column grow shrink>
                {pipe(
                  documents,
                  array.map(document =>
                    option.isSome(document.documentKey)
                      ? renderDocUploadField(
                          document.documentKey.value,
                          document.uploaded
                        )
                      : loadingError()
                  )
                )}
              </Box>
            );
          })
        );
      })
    );
  };

  const content = pipe(
    <Box column grow shrink>
      <Box column grow shrink>
        <Body size="small" weight="regular">
          {formatMessage(
            "Identification.UploadDocuments.step1.otherDocument.description"
          )}
        </Body>
        <Space units={4} />
        {documentList()}
      </Box>
      <Space units={10} />
      <Box hAlignContent="right">
        <NextButton
          action={props.onUploadComplete}
          disabled={!allDocumentsUploaded}
        />
      </Box>
    </Box>
  );

  return (
    <Dialog
      id={props.id}
      variant="left"
      size="medium"
      title={props.title}
      onDismiss={option.some(props.onDismiss)}
      actions={[]}
    >
      <Box column grow shrink>
        {content}
        {pipe(
          error,
          option.fold(constNull, e => (
            <ErrorBanner>{formatError(e)}</ErrorBanner>
          ))
        )}
      </Box>
    </Dialog>
  );
}
