import { Option } from "fp-ts/Option";
import {
  ComputedFieldProps,
  Box,
  Form,
  FormRow,
  FormSection,
} from "design-system";

import { CountryCode } from "../../../globalDomain";
import { CountryOfBirthDropdown } from "../CountryOfBirthDropdown";

type Props = {
  countryOfBirthFieldProps: ComputedFieldProps<Option<CountryCode>>;
};

export function AdditionalPersonalDataForm(props: Props) {
  return (
    <Box column>
      <Form data-test-id="additional_personal_data">
        <FormSection>
          <FormRow type="1-1">
            <CountryOfBirthDropdown
              readOnly={false}
              {...props.countryOfBirthFieldProps}
            />
            <></>
          </FormRow>
        </FormSection>
      </Form>
    </Box>
  );
}
