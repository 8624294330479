import * as t from "io-ts";
import { Option } from "fp-ts/Option";
import { option } from "fp-ts";
import { optionFromUndefined } from "../../globalDomain";
import { sharedReducerConfig } from "../../BranchExperience/useSharedReducer";
import { Address, PersonalDocumentProofOfAddress } from "../domain";
import { DocumentType } from "../../UploadDocuments/domain";
import { ProofOfAddressSubmitError } from "../api";

const SimpleError = t.keyof({
  MissingPermanentAddressError: true,
  GenericError: true,
});
const Error = t.union([SimpleError, ProofOfAddressSubmitError]);
type Error = t.TypeOf<typeof Error>;

const ProofOfAddress = t.type({
  documentType: DocumentType,
  document: optionFromUndefined(PersonalDocumentProofOfAddress),
  isForeign: t.boolean,
});
type ProofOfAddress = t.TypeOf<typeof ProofOfAddress>;

const State = t.type({
  formInEditing: t.boolean,
  error: optionFromUndefined(Error),
  permanentAddress: optionFromUndefined(Address),
  proofOfAddress: ProofOfAddress,
  shared: t.boolean,
});
type State = t.TypeOf<typeof State>;

const SetErrorAction = t.type({
  id: t.literal("SetError"),
  payload: optionFromUndefined(Error),
});

const SetPermanentAddressAction = t.type({
  id: t.literal("SetPermanentAddress"),
  payload: optionFromUndefined(Address),
});

const SetEditingAction = t.type({
  id: t.literal("SetEditing"),
  payload: t.boolean,
});

const ShareWithClientAction = t.type({
  id: t.literal("ShareWithClient"),
  payload: ProofOfAddress,
});

const ProofOfAddressChangeAction = t.type({
  id: t.literal("ProofOfAddressChange"),
});

const Action = t.union([
  SetErrorAction,
  SetPermanentAddressAction,
  SetEditingAction,
  ShareWithClientAction,
  ProofOfAddressChangeAction,
]);
type Action = t.TypeOf<typeof Action>;

export function setErrorAction(error: Option<Error>): Action {
  return {
    id: "SetError",
    payload: error,
  };
}

export function setPermanentAddressAction(address: Option<Address>): Action {
  return {
    id: "SetPermanentAddress",
    payload: address,
  };
}

export function setEditingAction(editing: boolean): Action {
  return {
    id: "SetEditing",
    payload: editing,
  };
}

export function shareWithClientAction(proofOfAddress: ProofOfAddress): Action {
  return {
    id: "ShareWithClient",
    payload: proofOfAddress,
  };
}

export function proofOfAddressChangeAction(): Action {
  return {
    id: "ProofOfAddressChange",
  };
}

function reducer(state: State, action: Action): State {
  switch (action.id) {
    case "SetError":
      return {
        ...state,
        error: action.payload,
      };
    case "SetPermanentAddress":
      return {
        ...state,
        permanentAddress: action.payload,
        shared: false,
        error: option.none,
        formInEditing: false,
      };
    case "SetEditing":
      return {
        ...state,
        formInEditing: action.payload,
      };
    case "ShareWithClient":
      return {
        ...state,
        proofOfAddress: action.payload,
        shared: true,
      };
    case "ProofOfAddressChange":
      return {
        ...state,
        shared: false,
      };
  }
}

export const reducerConfig = sharedReducerConfig(
  "PermanentAddress",
  State,
  Action,
  reducer
);
