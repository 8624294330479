import { option, task } from "fp-ts";
import { constNull, constVoid, pipe } from "fp-ts/function";
import { Box, Button, Heading } from "design-system";
import { useFormatMessage } from "../../intl";
import { useState } from "react";
import { ClientDataCheck } from "../../UploadDocuments/domain";
import { Option } from "fp-ts/Option";
import { FraudCheckDialog } from "../../UploadDocuments/FraudCheckDialog";
import { CoApplicant, DocumentIdentificationFlow } from "../../globalDomain";
import { ChooseMobileDeviceDialog } from "../../UploadDocuments/ChooseMobileDeviceDialog";
import { MobileRecipientOptionType } from "../../UploadDocuments/state";
import { RemoteMobileDeviceDialog } from "../../UploadDocuments/RemoteMobileDeviceDialog";
import { useAppContext } from "../../useAppContext";
import { usePortalStatusContext } from "../../PortalStatusContext";

type DialogStatus =
  | {
      id: "StartFraudCheck";
    }
  | {
      id: "FraudCheck";
      fraudCheck: ClientDataCheck;
      recipient: MobileRecipientOptionType;
    }
  | {
      id: "AdditionalFraudCheck";
      fraudCheck: ClientDataCheck;
    };

type Props = {
  canUploadAgain: boolean;
  documentIdentificationFlow: DocumentIdentificationFlow;
  coApplicant: Option<CoApplicant>;
  onUploadAgain: () => unknown;
  onAbort: (reason: "GenericError" | "MaxAttemptsReached") => unknown;
};

export function AdditionalChecks(props: Props) {
  const formatMessage = useFormatMessage();
  const [dialogStatus, setDialogStatus] = useState<Option<DialogStatus>>(
    option.none
  );

  const {
    apiParameters: { channel },
  } = useAppContext();
  const { portalBlocked } = usePortalStatusContext();

  return (
    <Box column>
      <Heading size="small" weight="medium">
        {formatMessage("Identification.UploadDocuments.additionalControls")}
      </Heading>
      <Box>
        <Button
          variant="text"
          size="default"
          label={formatMessage(
            "Identification.UploadDocuments.makeAdditionalControl"
          )}
          action={() => setDialogStatus(option.some({ id: "StartFraudCheck" }))}
          disabled={portalBlocked}
        />
        {pipe(
          dialogStatus,
          option.fold(constNull, status => {
            switch (status.id) {
              case "StartFraudCheck":
                const fraudCheck = "HologramFraudCheck";
                return channel === "3P_InPerson" ? (
                  <RemoteMobileDeviceDialog
                    onDismiss={() => setDialogStatus(option.none)}
                    onSelectRecipient={recipient =>
                      setDialogStatus(
                        option.some({
                          id: "FraudCheck",
                          fraudCheck,
                          recipient,
                        })
                      )
                    }
                    onUploadFromSameDevice={constVoid}
                    onMockScannerUpload={option.none}
                    mobileRecipientType="Banker"
                    type={fraudCheck}
                    coApplicant={props.coApplicant}
                  />
                ) : (
                  <ChooseMobileDeviceDialog
                    type={fraudCheck}
                    onUploadAgain={option.none}
                    onDismiss={() => setDialogStatus(option.none)}
                    onSelect={recipient =>
                      setDialogStatus(
                        option.some({
                          id: "FraudCheck",
                          fraudCheck,
                          recipient,
                        })
                      )
                    }
                    onMockScannerUpload={option.none}
                    coApplicant={props.coApplicant}
                  />
                );
              case "FraudCheck":
                return (
                  <FraudCheckDialog
                    canUploadAgain={props.canUploadAgain}
                    documentIdentificationFlow={
                      props.documentIdentificationFlow
                    }
                    coApplicant={props.coApplicant}
                    recipient={status.recipient}
                    fraudCheck={status.fraudCheck}
                    onDismiss={() => setDialogStatus(option.none)}
                    onError={props.onAbort}
                    onFraudCheck={fraudCheck =>
                      setDialogStatus(
                        option.some({
                          id: "AdditionalFraudCheck",
                          fraudCheck,
                        })
                      )
                    }
                    onContinue={task.fromIO(() => setDialogStatus(option.none))}
                    onUploadAgain={props.onUploadAgain}
                    onAbort={() => props.onAbort("GenericError")}
                  />
                );
              case "AdditionalFraudCheck":
                return channel === "3P_InPerson" ? (
                  <RemoteMobileDeviceDialog
                    onDismiss={constVoid}
                    onSelectRecipient={recipient =>
                      setDialogStatus(
                        option.some({
                          id: "FraudCheck",
                          fraudCheck: status.fraudCheck,
                          recipient,
                        })
                      )
                    }
                    onUploadFromSameDevice={constVoid}
                    onMockScannerUpload={option.none}
                    mobileRecipientType="Banker"
                    type={status.fraudCheck}
                    coApplicant={props.coApplicant}
                  />
                ) : (
                  <ChooseMobileDeviceDialog
                    type={status.fraudCheck}
                    onUploadAgain={
                      props.canUploadAgain
                        ? option.some(task.fromIO(props.onUploadAgain))
                        : option.none
                    }
                    onDismiss={constVoid}
                    onSelect={recipient =>
                      setDialogStatus(
                        option.some({
                          id: "FraudCheck",
                          fraudCheck: status.fraudCheck,
                          recipient,
                        })
                      )
                    }
                    onMockScannerUpload={option.none}
                    coApplicant={props.coApplicant}
                  />
                );
            }
          })
        )}
      </Box>
    </Box>
  );
}
