import { useState } from "react";
import { useFormatMessage } from "../intl";
import {
  Banner,
  Body,
  Box,
  FlowAccordion,
  Heading,
  NonNegativeInteger,
  Space,
  Stack,
  useIsMobileLayout,
} from "design-system";
import { AdditionalAddresses } from "./Addresses/AdditionalAddresses";
import { PermanentAddress } from "./PermanentAddress/PermanentAddress";
import { UploadDocuments } from "../UploadDocuments/UploadDocuments";
import { array, boolean, nonEmptyArray, option, taskEither } from "fp-ts";
import { Option } from "fp-ts/Option";
import { TaskEither } from "fp-ts/TaskEither";
import { ReaderTaskEither } from "fp-ts/ReaderTaskEither";
import { ConfirmClientData } from "./ClientData/ConfirmClientData";
import { NonEmptyArray } from "fp-ts/NonEmptyArray";
import {
  constant,
  constFalse,
  constNull,
  constTrue,
  constVoid,
  flow,
  pipe,
} from "fp-ts/function";
import {
  CoApplicantInput,
  DocumentIdentificationFlow,
  foldTenant,
  genericError,
  GenericError,
  UploadDocumentFlowType,
  useCheckTenant,
} from "../globalDomain";
import * as uploadDocumentApi from "../UploadDocuments/api";
import { useCommand } from "../useAPI";
import {
  ClientExistencyCheckData,
  ClientExistencyCheckDataPartial,
  ClientExistencyCommand,
  convertToCheckDataBn,
} from "../UploadDocuments/clientExistenceUtils";
import { CheckClientDataResult } from "../UploadDocuments/useCheckClientDataResult";
import {
  ClientDataOutput,
  PersonalInfoEditError,
  PersonalInfoEditInput,
  PersonalInfoSubmitInput,
  ResidencySubmitInput,
} from "./api";
import { useParentSharedReducer } from "../BranchExperience/useSharedReducer";
import {
  confirmAdditionalAddressessAction,
  confirmAdditionalDocumentsAction,
  confirmDataAction,
  confirmPermanentAddressAction,
  confirmSelfieAction,
  confirmUploadedDocumentsAction,
  foldState,
  reducerConfig,
  removeUploadedDocumentsAction,
  setActiveItemAction,
  setBiometricConsentAction,
  setConfirmDataStepAction,
  setShowGdprConsentAction,
  uploadAgainAction,
} from "./state";
import { useAppContext } from "../useAppContext";
import { BankerOPUAndCEXCard } from "./BankerOPUAndCEXCard/BankerOPUAndCEXCard";
import { SelfieCheck } from "./SelfieCheck/SelfieCheck";
import { palette } from "design-system/lib/styleConstants";
import {
  AllSupportedCitizenships,
  PersonalInfoError,
  PersonalProfileDocUploadSubmitError,
} from "./domain";
import { NonEmptyString } from "io-ts-types/lib/NonEmptyString";
import { foldCitizenship } from "../ClientProfile/utils";
import {
  FlowAccordionItem,
  FlowAccordionMacro,
} from "../Common/Stepper/FlowAccordion/FlowAccordionMacro";
import * as ukontoApi from "../UKontoSecondPart/api";
import { RestoreApplicationData } from "../UKontoSecondPart/api";
import { mergeExtractDataDocumentsOutput } from "../UploadDocuments/utils";
import { useScrollTopOnChange } from "../Common/useScrollTopOnChange";
import { selectedMainApplicant } from "../MortgageDashboard/mortgageDashboardUtils";
import { sequenceS } from "fp-ts/Apply";
import { AdditionalDocuments } from "./AdditionalDocument/AdditionalDocuments";
import { GDPR } from "../GDPR/GDPR";
import { useBranchExperienceContext } from "../BranchExperience/BranchExperienceContext";
import { DocumentDigitalId } from "../UploadDocuments/DocumentDigitalId";
import { BranchExperienceParentSwitch } from "../BranchExperience/BranchExperienceParentSwitch";

type Props = {
  onExit?: (action: Option<() => unknown>) => unknown;
  onComplete: (
    clientExistencyCheckData: Option<ClientExistencyCheckData>
  ) => TaskEither<unknown, unknown>;
  onFailure: (
    reason: "GenericError" | "MaxAttemptsReached" | "ValidationError"
  ) => unknown;
  onShouldClientContinue: Option<
    (
      clientExists: boolean,
      duplicateContacts: boolean,
      hasBlockingNotes: boolean,
      personalNumberMatch: boolean,
      userID: Option<string>,
      clientNumber: Option<string>
    ) => TaskEither<unknown, boolean>
  >;
  documentIdentificationFlow: DocumentIdentificationFlow;
  excludeAdditionalAddress?: boolean;
  hideBiometricConsent?: boolean;
  extractData: TaskEither<unknown, ClientDataOutput>;
  rejectData: TaskEither<unknown, unknown>;
  isExistingClient?: boolean;
  clientExistencyCommand: ClientExistencyCommand;
  checkOpuAndCex?: boolean;
  submitConfirmedDataCommand: ReaderTaskEither<
    PersonalInfoSubmitInput,
    PersonalProfileDocUploadSubmitError | PersonalInfoError | GenericError,
    unknown
  >;
  editPersonalInfoCommand: ReaderTaskEither<
    PersonalInfoEditInput,
    PersonalInfoEditError | GenericError,
    unknown
  >;
  submitResidencyCommand: ReaderTaskEither<
    ResidencySubmitInput,
    unknown,
    unknown
  >;
  documentsMismatch: boolean;
  onMismatchDialogDismiss: () => unknown;
  showMismatchDialog: () => unknown;
  noGoBack?: boolean;
  onReset: () => unknown;
  forceMobileRecipientSelection?: boolean;
  productType: Option<UploadDocumentFlowType>;
  isThirdParty?: boolean;
  restoredData?: RestoreApplicationData;
  resetUpload?: TaskEither<unknown, unknown>;
  foreignSupported?: boolean;
  isUkonto?: boolean;
} & CoApplicantInput;

export function IdUpload(props: Props) {
  const formatMessage = useFormatMessage();
  const uploadToServer = useCommand(uploadDocumentApi.uploadDocument);
  const saveApplication = useCommand(ukontoApi.saveWithParams);
  const resetAfterScannerCancel = useCommand(
    uploadDocumentApi.resetUploadDocument
  );

  const {
    apiParameters: { tenant, channel },
    config: {
      useNewMobileIdUploadUx: newMobileIdUpload,
      localBirthNumberEnabled,
    },
  } = useAppContext();

  const {
    config: { OPUCEXAssignmentEnabled, enableDigitalId },
  } = useAppContext();

  const checkTenant = useCheckTenant();

  useScrollTopOnChange("auto");

  const [extractedData, setExtractedData] = useState<
    Option<CheckClientDataResult>
  >(
    props.restoredData &&
      (props.restoredData.feStep === "PERSONAL_DATA_CONFIRMATION" ||
        props.restoredData.feStep === "NON_RESIDENTS_ADDITIONAL_DOCUMENTS" ||
        props.restoredData.feStep === "PERMANENT_ADDRESS" ||
        props.restoredData.feStep === "PROOF_OF_ADDRESS" ||
        props.restoredData.feStep === "ADDITIONAL_ADDRESS")
      ? getRestoreData(props.restoredData.extractDataResult)
      : option.none
  );

  const [uploadIdKey, setUploadIdKey] = useState(0);

  const [uploadReset, setUploadReset] = useState(false);

  const [isSubmitResidency, setIsSubmitResidency] = useState(0);

  const [previousBiometricConsent, setPreviousBiometricConsent] = useState<
    Option<boolean>
  >(option.none);
  const [clientExistencyCheckData, setClientExistencyCheckData] = useState<
    Option<ClientExistencyCheckDataPartial>
  >(
    props.restoredData &&
      option.isSome(props.restoredData.extractDataResult) &&
      option.isSome(props.restoredData.extractDataResult.value.primary)
      ? option.some({
          name: (option.isSome(
            props.restoredData.extractDataResult.value.primary.value
              .personalData.name
          )
            ? props.restoredData.extractDataResult.value.primary.value
                .personalData.name.value
            : "NA") as NonEmptyString,
          surname: (option.isSome(
            props.restoredData.extractDataResult.value.primary.value
              .personalData.surname
          )
            ? props.restoredData.extractDataResult.value.primary.value
                .personalData.surname.value
            : "NA") as NonEmptyString,
          dateOfBirth: option.isSome(
            props.restoredData.extractDataResult.value.primary.value
              .personalData.dateOfBirth
          )
            ? props.restoredData.extractDataResult.value.primary.value
                .personalData.dateOfBirth.value
            : new Date(),
          birthNumber: option.some(
            (option.isSome(
              props.restoredData.extractDataResult.value.primary.value
                .personalData.birthNumber
            )
              ? props.restoredData.extractDataResult.value.primary.value
                  .personalData.birthNumber.value
              : "") as NonEmptyString
          ),
          citizenship: (option.isSome(
            props.restoredData.extractDataResult.value.primary.value
              .personalData.citizenship
          )
            ? props.restoredData.extractDataResult.value.primary.value
                .personalData.citizenship.value
            : "OTHER") as AllSupportedCitizenships,
          applicantIndex: 0 as NonNegativeInteger,
        })
      : option.none
  );
  const [opuAndCexLoaded, setOpuAndCexLoaded] = useState(false);
  const [opuAndCexValid, setOpuAndCexValid] = useState(
    !(OPUCEXAssignmentEnabled && props.checkOpuAndCex)
  );
  const [showOpuAndCexError, setShowOpuAndCexError] = useState(false);
  const [disableAccordionNavigation, setDisableAccordionNavigation] = useState(
    false
  );

  const [showGdprDialog, setShowGdprDialog] = useState(false);
  const [type, setType] = useState<"upload" | "digital">("upload");

  const isWaitingForOpuAndCex = !!(
    OPUCEXAssignmentEnabled &&
    props.checkOpuAndCex &&
    !opuAndCexLoaded
  );

  const hasResidency = pipe(
    props.restoredData,
    option.fromNullable,
    option.map(data => data.residency),
    option.flatten
  );

  const [isResident, setIsResident] = useState(
    pipe(
      hasResidency,
      option.fold(
        () => true,
        value => value
      )
    )
  );

  const resetUploadDocumentStep = () => setUploadIdKey(k => k + 1);

  const onComplete = (
    clientExistencyCheckData: Option<ClientExistencyCheckData>
  ) => {
    if (opuAndCexValid) {
      return props.onComplete(clientExistencyCheckData);
    } else {
      return pipe(
        taskEither.left("OPU_CEX_INCOMPLETE"),
        taskEither.mapLeft(() => {
          setShowOpuAndCexError(true);
          window.scrollTo({
            top: document.body.scrollHeight,
            behavior: "smooth",
          });
        })
      );
    }
  };

  const isPersonalProfile = pipe(
    props.productType,
    option.exists(item => item === "PersonalProfile")
  );

  const isLocalClient = (data: CheckClientDataResult) =>
    pipe(
      data.personalData.citizenship,
      option.map(
        foldCitizenship({
          CZE: () => checkTenant({ CZ: constTrue }),
          SVK: () => checkTenant({ SK: constTrue }),
          OTHER: constFalse,
        })
      ),
      option.getOrElse(constFalse)
    );

  const hasPermanentAddressStep = pipe(
    extractedData,
    option.fold(constFalse, data => {
      return (
        (!isPersonalProfile &&
          !props.isExistingClient &&
          option.isNone(data.permanentAddress) &&
          props.documentIdentificationFlow !== "Secondary") ||
        (!isPersonalProfile && !isLocalClient(data))
      );
    })
  );

  const hasAdditionalDocumentsStep = pipe(
    extractedData,
    option.fold(constFalse, data => {
      return !isLocalClient(data) && !isResident;
    })
  );

  const hasSelfieCheckStep = pipe(
    extractedData,
    option.fold(constFalse, data =>
      pipe(
        data.fraudCheck,
        option.exists(fraudCheck => fraudCheck === "SelfieFraudCheck")
      )
    )
  );

  const [state, dispatch] = useParentSharedReducer(
    reducerConfig,
    props.restoredData && !props.restoredData.restartDocumentsUpload
      ? props.restoredData.feStep === "PERSONAL_DATA_CONFIRMATION"
        ? {
            id: "ConfirmData",
            biometricConsent: props.restoredData.biometricConsent,
            activeIndex: option.some(1),
          }
        : props.restoredData.feStep === "NON_RESIDENTS_ADDITIONAL_DOCUMENTS"
        ? {
            id: "AdditionalDocuments",
            biometricConsent: props.restoredData.biometricConsent,
            activeIndex: option.some(2),
          }
        : props.restoredData.feStep === "PERMANENT_ADDRESS" ||
          props.restoredData.feStep === "PROOF_OF_ADDRESS"
        ? {
            id: "PermanentAddress",
            biometricConsent: props.restoredData.biometricConsent,
            activeIndex: option.some(hasAdditionalDocumentsStep ? 3 : 2),
          }
        : props.restoredData.feStep === "ADDITIONAL_ADDRESS"
        ? {
            id: "AdditionalAddresses",
            biometricConsent: props.restoredData.biometricConsent,
            activeIndex: option.some(
              hasPermanentAddressStep && hasAdditionalDocumentsStep
                ? 4
                : hasPermanentAddressStep || hasAdditionalDocumentsStep
                ? 3
                : 2
            ),
          }
        : {
            id: "UploadDocuments",
            biometricConsent: props.restoredData.biometricConsent,
            activeIndex: option.some(0),
          }
      : {
          id: "UploadDocuments",
          biometricConsent: option.none,
          activeIndex: option.some(0),
        }
  );
  const { branchExperienceFeaturesActive } = useBranchExperienceContext();

  const onOpuAndCexComplete = (cexCodeOption: Option<NonEmptyString>) => {
    setOpuAndCexLoaded(true);
    pipe(
      cexCodeOption,
      option.fold(
        () => {
          setOpuAndCexValid(false);
        },
        () => {
          setOpuAndCexValid(true);
          setShowOpuAndCexError(false);
        }
      )
    );
  };

  const digitalIdEnabled =
    checkTenant({ CZ: constTrue }) &&
    enableDigitalId &&
    channel === "Branch_InPerson";

  const isMobileLayout = useIsMobileLayout();
  const useNewMobileUx = isMobileLayout && newMobileIdUpload;
  const uploadDocumentsStep: FlowAccordionItem = {
    title: formatMessage("Identification.UploadDocuments.step1.title"),
    hideTitle: useNewMobileUx,
    content: ({ resetNextSteps }) => {
      return (
        <Stack column shrink grow units={10}>
          {!isMobileLayout && (
            <Body size="medium" weight="regular" color={palette.neutral700}>
              {formatMessage("Identification.upload.stepDescription")}
            </Body>
          )}
          {type === "upload" && (
            <UploadDocuments
              digitalIdSwitch={
                digitalIdEnabled
                  ? {
                      type: type,
                      setType: setType,
                    }
                  : undefined
              }
              productType={props.productType}
              key={uploadIdKey}
              onContinue={(data: CheckClientDataResult) => {
                setExtractedData(option.some(data));
                dispatch(confirmUploadedDocumentsAction(hasSelfieCheckStep));
              }}
              onRemove={() => {
                setClientExistencyCheckData(option.none);
                dispatch(removeUploadedDocumentsAction);
                resetNextSteps();
                if (props.resetUpload) {
                  props.resetUpload();
                }
                setUploadReset(true);
              }}
              onUploadAgain={props.rejectData}
              onFailure={props.onFailure}
              coApplicant={props.coApplicant}
              biometricConsent={{
                consent: state.biometricConsent,
                previousConsent: previousBiometricConsent,
              }}
              hideBiometricConsent={props.hideBiometricConsent}
              setBiometricConsent={biometricConsent => {
                pipe(
                  biometricConsent.consent,
                  option.map(flow(setBiometricConsentAction, dispatch))
                );
                setPreviousBiometricConsent(biometricConsent.previousConsent);
              }}
              documentIdentificationFlow={props.documentIdentificationFlow}
              uploadCommand={uploadToServer}
              resetAfterScannerCancel={resetAfterScannerCancel(
                pipe(
                  props.coApplicant,
                  option.map(coApplicant => ({
                    applicantIndex: coApplicant.index,
                  }))
                )
              )}
              extractCommand={props.extractData}
              forceMobileRecipientSelection={
                props.forceMobileRecipientSelection
              }
              allowCopyIdDocumentAgreementEdit={foldState(state, {
                whenUploadDocuments: constTrue,
                whenConfirmData: constFalse,
                whenAdditionalDocuments: constFalse,
                whenPermanentAddress: constFalse,
                whenAdditionalAddresses: constFalse,
                whenCompleted: constFalse,
                whenConfirmSelfie: constFalse,
                whenSelfieCheck: constFalse,
              })}
              restoredData={props.restoredData}
              supportForeign={props.foreignSupported}
              isUploadAgain={uploadIdKey > 0}
            />
          )}
          {type === "digital" && (
            <BranchExperienceParentSwitch disabled={false}>
              <DocumentDigitalId
                digitalIdSwitch={{
                  type: type,
                  setType: setType,
                }}
                onContinue={(data: CheckClientDataResult) => {
                  setExtractedData(option.some(data));
                  dispatch(confirmUploadedDocumentsAction(hasSelfieCheckStep));
                }}
                key={uploadIdKey}
                coApplicant={props.coApplicant}
                biometricConsent={{
                  consent: state.biometricConsent,
                  previousConsent: previousBiometricConsent,
                }}
                setBiometricConsent={biometricConsent => {
                  pipe(
                    biometricConsent.consent,
                    option.map(flow(setBiometricConsentAction, dispatch))
                  );
                  setPreviousBiometricConsent(biometricConsent.previousConsent);
                }}
              />
            </BranchExperienceParentSwitch>
          )}
        </Stack>
      );
    },
  };

  const confirmDataStep: FlowAccordionItem = {
    title: formatMessage("Identification.personalData.completeAndConfirm"),
    content: ({ move, resetNextSteps }) => {
      return pipe(
        extractedData,
        option.map(extractedData => (
          <ConfirmClientData
            productType={props.productType}
            extractedData={extractedData}
            documentIdentificationFlow={props.documentIdentificationFlow}
            clientExistencyCommand={props.clientExistencyCommand}
            onDataChange={() => {
              if (state.id !== "ConfirmData") {
                dispatch(setConfirmDataStepAction);
                resetNextSteps();
              }
            }}
            onContinue={clientExistencyCheckData =>
              pipe(
                taskEither.fromIO(() => {
                  setClientExistencyCheckData(clientExistencyCheckData);
                }),
                taskEither.chain(() => {
                  if (
                    !hasPermanentAddressStep &&
                    !hasAdditionalDocumentsStep &&
                    props.excludeAdditionalAddress
                  ) {
                    return pipe(
                      clientExistencyCheckData,
                      convertToCheckDataBn,
                      onComplete
                    );
                  }
                  return taskEither.right(undefined);
                }),
                taskEither.chain(() =>
                  taskEither.fromIO(() =>
                    dispatch(
                      confirmDataAction(
                        hasPermanentAddressStep,
                        !props.excludeAdditionalAddress,
                        hasAdditionalDocumentsStep
                      )
                    )
                  )
                )
              )
            }
            onUploadAgain={pipe(
              props.rejectData,
              taskEither.chain(() =>
                taskEither.fromIO(() => {
                  dispatch(uploadAgainAction);
                  resetUploadDocumentStep();
                  move(0, true);
                })
              )
            )}
            onAbort={props.onFailure}
            onShouldClientContinue={props.onShouldClientContinue}
            coApplicant={props.coApplicant}
            isWaitingForOpuAndCex={
              props.excludeAdditionalAddress &&
              checkIfInLastFlowStep() &&
              isWaitingForOpuAndCex
            }
            banner={(() => (
              <>
                {extractedData.showWarning ? (
                  <Banner
                    type="warning"
                    title={option.none}
                    actions={option.none}
                    onDismiss={option.none}
                    content={formatMessage(
                      "ClientProfile.clientData.IDDocument.warningMessageDescription"
                    )}
                  />
                ) : undefined}
                {state.id === "AdditionalAddresses" ||
                state.id === "PermanentAddress" ? (
                  <Banner
                    type="warning"
                    title={option.none}
                    actions={option.none}
                    onDismiss={option.none}
                    content={formatMessage(
                      "ClientProfile.clientData.IDDocument.warningResetStepDescription"
                    )}
                  />
                ) : undefined}
              </>
            ))()}
            submitDataCommand={props.submitConfirmedDataCommand}
            documentsMismatch={props.documentsMismatch}
            onMismatchDialogDismiss={props.onMismatchDialogDismiss}
            showMismatchDialog={props.showMismatchDialog}
            noGoBack={props.noGoBack}
            onReset={props.onReset}
            editPersonalInfoCommand={props.editPersonalInfoCommand}
            hasParentPadding={!isPersonalProfile}
            supportForeign={props.foreignSupported === true}
            submitResidencyCommand={residency => {
              setIsSubmitResidency(residency.residency ? 2 : 1);
              return props.submitResidencyCommand(residency);
            }}
            hasResidency={hasResidency}
            onResidencyChange={value => {
              resetNextSteps();
              pipe(
                value,
                option.fold(
                  () => setIsResident(false),
                  value => setIsResident(value)
                )
              );
            }}
          />
        )),
        option.toNullable
      );
    },
  };

  const SelfieCheckStep: FlowAccordionItem = {
    title: formatMessage("Identification.selfieCheck"),
    content: ({ move }) =>
      pipe(
        extractedData,
        option.fold(constNull, document => (
          <SelfieCheck
            productType={props.productType}
            documentIdentificationFlow={props.documentIdentificationFlow}
            coApplicant={props.coApplicant}
            canUploadAgain={document.canUploadAgain}
            onUploadAgain={pipe(
              props.rejectData,
              taskEither.chain(() =>
                taskEither.fromIO(() => {
                  dispatch(uploadAgainAction);
                  resetUploadDocumentStep();
                  move(0, true);
                })
              )
            )}
            resetProcess={() => move(0, true)}
            onContinue={() => {
              dispatch(confirmSelfieAction);
            }}
            onFailure={props.onFailure}
          />
        ))
      ),
  };

  const additionalDocumentsStep: FlowAccordionItem = {
    title: formatMessage("Identification.additionalDocument.title"),
    content: ({ resetNextSteps }) => (
      <AdditionalDocuments
        onContinue={pipe(
          taskEither.fromIO(() =>
            saveApplication({
              feStep: option.some(
                hasPermanentAddressStep
                  ? "PERMANENT_ADDRESS"
                  : "ADDITIONAL_ADDRESS"
              ),
              status: option.some("IN_PROGRESS"),
              incomeInfo: option.none,
              transactionsInfo: option.none,
            })()
          ),
          taskEither.chain(() =>
            taskEither.fromIO(() =>
              dispatch(
                confirmAdditionalDocumentsAction(
                  hasPermanentAddressStep,
                  !props.excludeAdditionalAddress
                )
              )
            )
          )
        )}
        onFailure={() => props.onFailure("GenericError")}
        coApplicant={props.coApplicant}
        disabled={false}
        onRemoveLastDoc={() => resetNextSteps()}
        restoredData={props.restoredData}
      />
    ),
  };

  const permanentAddressStep: FlowAccordionItem = {
    title: formatMessage("Identification.permanentAddress.stepTitle"),
    content: () => {
      return pipe(
        extractedData,
        option.fold(constNull, document => {
          const isNotCzSk = pipe(
            document.personalData.citizenship,
            option.fold(
              () => false,
              citizenship => citizenship !== "SVK" && citizenship !== "CZE"
            )
          );
          return (
            <PermanentAddress
              documentType={document.documentType}
              showLocalPersonalNumber={pipe(
                document.personalData.citizenship,
                option.map(
                  foldCitizenship({
                    CZE: () =>
                      foldTenant(
                        tenant,
                        constant(localBirthNumberEnabled),
                        constFalse
                      ),
                    SVK: () =>
                      foldTenant(
                        tenant,
                        constFalse,
                        constant(localBirthNumberEnabled)
                      ),
                    OTHER: () => true,
                  })
                ),
                option.getOrElseW(() => false)
              )}
              isForeign={!isLocalClient(document)}
              next={personalNumber => {
                const bn = option.isSome(personalNumber)
                  ? personalNumber.value
                  : undefined;
                let clientExistency = clientExistencyCheckData;

                if (isNotCzSk) {
                  return pipe(
                    Boolean(props.excludeAdditionalAddress),
                    boolean.fold(
                      () => {
                        if (bn && option.isSome(clientExistencyCheckData)) {
                          clientExistency = option.some({
                            ...clientExistencyCheckData.value,
                            birthNumber: option.some(bn),
                          });
                          setClientExistencyCheckData(clientExistency);
                        }
                        return taskEither.right(undefined);
                      },
                      () =>
                        pipe(
                          personalNumber,
                          option.fold(
                            () =>
                              onComplete(
                                convertToCheckDataBn(clientExistencyCheckData)
                              ),
                            personalNumber =>
                              onComplete(
                                convertToCheckDataBn(
                                  clientExistencyCheckData,
                                  personalNumber
                                )
                              )
                          )
                        )
                    ),
                    taskEither.chain(() =>
                      taskEither.fromIO(() =>
                        dispatch(
                          confirmPermanentAddressAction(
                            !props.excludeAdditionalAddress
                          )
                        )
                      )
                    )
                  );
                }

                if (bn) {
                  if (option.isSome(clientExistencyCheckData)) {
                    clientExistency = option.some({
                      ...clientExistencyCheckData.value,
                      birthNumber: option.some(bn),
                    });
                    setClientExistencyCheckData(clientExistency);
                  } else if (option.isSome(extractedData)) {
                    pipe(
                      extractedData,
                      option.fold(constVoid, extractedData => {
                        clientExistency = pipe(
                          {
                            dateOfBirth: extractedData.personalData.dateOfBirth,
                            name: extractedData.personalData.name,
                            surname: extractedData.personalData.surname,
                            citizenship: extractedData.personalData.citizenship,
                          },
                          sequenceS(option.option),
                          option.map(data => ({
                            dateOfBirth: data.dateOfBirth,
                            name: data.name,
                            surname: data.surname,
                            citizenship: data.citizenship as AllSupportedCitizenships,
                            birthNumber: option.some(bn),
                            applicantIndex: pipe(
                              props.coApplicant,
                              option.fold(
                                () => selectedMainApplicant.index,
                                i => i.index
                              )
                            ),
                          }))
                        );
                      })
                    );
                    setClientExistencyCheckData(clientExistency);
                  }
                }

                if (option.isNone(clientExistency)) {
                  //should never happen
                  return taskEither.left(genericError);
                }

                return pipe(
                  Boolean(props.excludeAdditionalAddress),
                  boolean.fold(
                    () => taskEither.right(undefined),
                    () =>
                      pipe(convertToCheckDataBn(clientExistency), onComplete)
                  ),
                  taskEither.chain(() =>
                    taskEither.fromIO(() =>
                      dispatch(
                        confirmPermanentAddressAction(
                          !props.excludeAdditionalAddress
                        )
                      )
                    )
                  )
                );
              }}
              coApplicant={props.coApplicant}
              productType={props.productType}
              isUkonto={props.isUkonto}
              restoredData={props.restoredData}
              isUploadAgain={uploadIdKey > 0 || uploadReset}
              isWithResidency={() => {
                return isSubmitResidency !== 0
                  ? isSubmitResidency === 2
                  : pipe(
                      hasResidency,
                      option.fold(
                        () => true,
                        value => value
                      )
                    );
              }}
            />
          );
        })
      );
    },
  };

  const onContinueAdditionalAddress = pipe(
    clientExistencyCheckData,
    convertToCheckDataBn,
    onComplete,
    taskEither.chain(() =>
      taskEither.fromIO(() => dispatch(confirmAdditionalAddressessAction))
    )
  );

  const onContinueAdditionalAddressWithGdpr = pipe(
    foldTenant(
      tenant,
      () =>
        !props.isExistingClient
          ? taskEither.fromIO(() => {
              setShowGdprDialog(true);
            })
          : onContinueAdditionalAddress,
      () => onContinueAdditionalAddress
    )
  );

  const additionalAddressStep: FlowAccordionItem = {
    title: formatMessage("Identification.additionalAddresses"),
    content: () => (
      <AdditionalAddresses
        onContinue={onContinueAdditionalAddressWithGdpr}
        isWaitingForOpuAndCex={isWaitingForOpuAndCex}
        coApplicant={props.coApplicant}
        onEditingChange={(isEditing: boolean) =>
          setDisableAccordionNavigation(isEditing)
        }
        foreignSupport={props.foreignSupported}
      />
    ),
  };

  const items: NonEmptyArray<FlowAccordionItem> = nonEmptyArray.concat(
    [uploadDocumentsStep],
    array.compact([
      pipe(SelfieCheckStep, option.fromPredicate(constant(hasSelfieCheckStep))),
      option.some(confirmDataStep),
      pipe(
        additionalDocumentsStep,
        option.fromPredicate(constant(hasAdditionalDocumentsStep))
      ),
      pipe(
        permanentAddressStep,
        option.fromPredicate(constant(hasPermanentAddressStep))
      ),
      pipe(
        additionalAddressStep,
        option.fromPredicate(constant(!props.excludeAdditionalAddress))
      ),
    ])
  );

  const checkIfInLastFlowStep = () =>
    pipe(
      state.activeIndex,
      option.getOrElse(constant(0)),
      flowIdx => flowIdx === items.length - 1
    );

  const isFromPersonalProfile = pipe(
    props.productType,
    option.exists(v => v === "PersonalProfile")
  );
  const flowAccordion =
    useNewMobileUx && isFromPersonalProfile ? (
      <FlowAccordionMacro
        items={items}
        value={state.activeIndex}
        onChange={flow(setActiveItemAction, dispatch)}
        disableNavigation={disableAccordionNavigation}
      />
    ) : (
      <FlowAccordion
        items={items}
        value={state.activeIndex}
        onChange={flow(setActiveItemAction, dispatch)}
        disableNavigation={disableAccordionNavigation}
      />
    );
  return (
    <Box column grow={isMobileLayout ? 1 : 0} shrink>
      {!isMobileLayout && (
        <>
          <Heading size="large" weight="medium" align="center">
            {formatMessage("Identification.UploadDocuments.mainContentTitle")}
          </Heading>
          <Space units={8} />
        </>
      )}
      {flowAccordion}
      {showGdprDialog && (
        <GDPR
          onDismiss={
            branchExperienceFeaturesActive && props.onExit
              ? () => props.onExit!(option.none)
              : () => {
                  setShowGdprDialog(false);
                  dispatch(setShowGdprConsentAction(false));
                }
          }
          onContinue={onContinueAdditionalAddress}
          onShareWithClient={
            branchExperienceFeaturesActive
              ? gdprData => dispatch(setShowGdprConsentAction(true, gdprData))
              : undefined
          }
          accepted={state.gdprAccepted}
        />
      )}
      {OPUCEXAssignmentEnabled &&
      props.checkOpuAndCex &&
      checkIfInLastFlowStep() ? (
        <>
          <Space units={6} />
          <BankerOPUAndCEXCard
            isThirdParty={props.isThirdParty}
            onOpuAndCexComplete={onOpuAndCexComplete}
            showOpuAndCexError={showOpuAndCexError}
            disableEdit={disableAccordionNavigation}
          />
        </>
      ) : null}
    </Box>
  );
}

function getRestoreData(
  extractData: Option<ClientDataOutput>
): Option<CheckClientDataResult> {
  return pipe(
    extractData,
    option.fold(
      () => option.none,
      data => {
        const documentPayload = mergeExtractDataDocumentsOutput(
          data.primary,
          data.secondary
        );
        const canUploadAgain = data.canUploadAgain;
        return pipe(
          documentPayload,
          option.fold(
            () => option.none,
            document => {
              switch (data.result) {
                case "EditData":
                  return option.some({
                    ...document,
                    canUploadAgain,
                    canEdit: true,
                    showWarning: false,
                    fraudCheck: option.none,
                  });
                case "EditDataWithWarning":
                  return option.some({
                    ...document,
                    canUploadAgain,
                    canEdit: true,
                    showWarning: true,
                    fraudCheck: option.none,
                  });
                case "ContinueWithWarning":
                  return option.some({
                    ...document,
                    canUploadAgain,
                    canEdit: false,
                    showWarning: true,
                    fraudCheck: option.none,
                  });
                case "Continue":
                  return option.some({
                    ...document,
                    canUploadAgain,
                    canEdit: false,
                    showWarning: false,
                    fraudCheck: option.none,
                  });
                case "HologramFraudCheck":
                case "SelfieFraudCheck":
                case "HologramAndSelfieFraudCheck":
                  return option.some({
                    ...document,
                    canUploadAgain,
                    canEdit: false,
                    showWarning: false,
                    fraudCheck: option.some(data.result),
                  });
                case "UploadAgain":
                case "Abort":
                  return option.none;
              }
            }
          )
        );
      }
    )
  );
}
