import { PersonalDocument, PersonalDocumentOCR } from "../../domain";
import {
  FormRow,
  Form,
  ReadOnlyField,
  TextField,
  DateField,
  FormSection,
  Banner,
  ComputedFieldProps,
  LocalizedString,
  ExtractFieldsOfType,
  ValidableDate,
  validableDate,
  Issue,
} from "design-system";
import { useFormatDocumentType, useFormatMessage } from "../../../intl";
import { Option } from "fp-ts/Option";
import { nonEmptyArray, option } from "fp-ts";
import { constant, constNull, pipe } from "fp-ts/function";
import { NonEmptyArray } from "fp-ts/NonEmptyArray";
import { AuthorityDropdown } from "./AuthorityDropdown";
import { DocumentType } from "../../../UploadDocuments/domain";
import { ReadOnlyDateField } from "../../../Common/ReadOnlyDateField/ReadOnlyDateField";
import {
  PrimaryDocumentIssue,
  SecondaryDocumentIssue,
} from "../../../ClientProfile/domain";
import { foldIssue } from "../../../ClientProfile/utils";
import {
  ReworkCustomer,
  ReworkDocumentDetails,
} from "../../../MortgageDashboard/domain";
import { useFormatReworkData } from "../../../MortgageDashboard/useFormatReworkData";

export type FormState = {
  [k in Exclude<
    keyof PersonalDocument,
    | "dateOfIssuing"
    | "validUntil"
    | "authority"
    | "authorityMandatory"
    | "issuer"
    | "issuerMandatory"
  >]: string;
} & {
  authority: Option<string>;
  authorityMandatory: boolean;
  issuer: Option<string>;
  issuerMandatory: boolean;
  dateOfIssuing: Option<ValidableDate>;
  validUntil: Option<ValidableDate>;
};

export function hasAuthority(documentType: DocumentType) {
  return documentType === "IDCard";
}

export function formStateFromPersonalDocument(
  personalDocument: PersonalDocument
): FormState {
  return {
    ...personalDocument,
    dateOfIssuing: option.some(
      validableDate.fromDate(personalDocument.dateOfIssuing)
    ),
    validUntil: pipe(
      personalDocument.validUntil,
      option.map(validableDate.fromDate)
    ),
  };
}

export function formStateFromPersonalDocumentOCR(
  personalDocument: PersonalDocumentOCR
): FormState {
  return {
    idNumber: pipe(personalDocument.idNumber, option.getOrElse(constant(""))),
    authority: personalDocument.authority,
    issuer: personalDocument.issuer,
    dateOfIssuing: pipe(
      personalDocument.dateOfIssuing,
      option.map(validableDate.fromDate)
    ),
    validUntil: pipe(
      personalDocument.validUntil,
      option.map(validableDate.fromDate)
    ),
    authorityMandatory: personalDocument.authorityMandatory,
    issuerMandatory: personalDocument.issuerMandatory,
  };
}

type Props = {
  documentType: DocumentType;
  errors: Option<NonEmptyArray<Issue>>;
  heading?: React.ComponentProps<typeof FormSection>["heading"];
  showDocumentType?: boolean;
  issue: Option<PrimaryDocumentIssue | SecondaryDocumentIssue>;
  checkLostStolen?: boolean;
  reworkDocumentDetails: Option<ReworkDocumentDetails>;
  reworkClientProfile: Option<ReworkCustomer>;
  authorityMandatory: boolean;
  issuerMandatory: boolean;
} & (
  | {
      readOnly: true;
      values: FormState;
    }
  | {
      readOnly: false;
      canEditValidUntilField?: boolean;
      fieldProps: <K extends keyof FormState>(
        name: K
      ) => ComputedFieldProps<FormState[K]>;
    }
);

export function PersonalDocumentForm(props: Props) {
  const formatMessage = useFormatMessage();
  const formatDocumentType = useFormatDocumentType();

  const { formatIssues, formatFeedback } = useFormatReworkData(
    props.reworkDocumentDetails
  );
  const { formatFeedback: formatClientProfileFeedback } = useFormatReworkData(
    props.reworkClientProfile
  );

  const expiredBanner = (
    <Banner
      type="warning"
      actions={option.none}
      title={option.none}
      content={formatMessage("Identification.personalData.documentExpired")}
      onDismiss={option.none}
    />
  );

  const lostOrStolenBanner = (
    <Banner
      type="warning"
      actions={option.none}
      title={option.none}
      content={formatMessage(
        "Identification.personalData.documentLostOrStolen"
      )}
      onDismiss={option.none}
    />
  );

  const renderTextFieldOrReadOnly = ({
    name,
    label,
    placeholder,
  }: {
    name: ExtractFieldsOfType<FormState, string>;
    label: LocalizedString;
    placeholder: LocalizedString;
  }) =>
    props.readOnly ? (
      <ReadOnlyField
        size="medium"
        label={label}
        value={props.values[name]}
        feedback={formatFeedback(name)}
      />
    ) : (
      <TextField
        {...props.fieldProps(name)}
        label={label}
        placeholder={placeholder}
        issues={formatIssues(name, props.fieldProps(name).issues)}
      />
    );

  const renderDateFieldOrReadOnly = ({
    name,
    label,
    placeholder,
  }: {
    name: ExtractFieldsOfType<FormState, Option<ValidableDate>>;
    label: LocalizedString;
    placeholder: LocalizedString;
  }) =>
    props.readOnly ? (
      <ReadOnlyDateField
        format="dd-mm-yyyy"
        label={label}
        value={pipe(props.values[name], option.flatten)}
        feedback={formatFeedback(name)}
      />
    ) : (
      <DateField
        {...props.fieldProps(name)}
        label={label}
        placeholder={placeholder}
        displayFormat="YYYY-MM-DD"
        issues={formatIssues(name, props.fieldProps(name).issues)}
      />
    );

  const renderValidUntilField =
    props.readOnly || !props.canEditValidUntilField ? (
      <ReadOnlyDateField
        format="dd-mm-yyyy"
        label={formatMessage("Identification.personalData.validUntil")}
        {...(props.readOnly
          ? { value: pipe(props.values.validUntil, option.flatten) }
          : {
              value: pipe(props.fieldProps("validUntil").value, option.flatten),
            })}
        feedback={formatFeedback("validUntil")}
      />
    ) : (
      <DateField
        {...props.fieldProps("validUntil")}
        label={formatMessage("Identification.personalData.validUntil")}
        placeholder={formatMessage("Identification.personalData.validUntil")}
        displayFormat="YYYY-MM-DD"
        issues={formatIssues(
          "validUntil",
          props.fieldProps("validUntil").issues
        )}
      />
    );

  const renderAuthorityFieldOrReadOnly = props.readOnly ? (
    <ReadOnlyField
      size="medium"
      label={formatMessage("Identification.personalData.authority")}
      value={pipe(props.values.authority, option.getOrElse(constant("")))}
      feedback={formatFeedback("authority")}
    />
  ) : (
    <AuthorityDropdown
      {...props.fieldProps("authority")}
      label={formatMessage("Identification.personalData.authority")}
      placeholder={formatMessage("Identification.personalData.authority")}
      clearable
      issues={formatIssues("authority", props.fieldProps("authority").issues)}
    />
  );

  const idNumberField = renderTextFieldOrReadOnly({
    name: "idNumber",
    label: formatMessage("Identification.personalData.idNumber"),
    placeholder: formatMessage("Identification.personalData.idNumber"),
  });

  const documentTypeField = (
    <ReadOnlyField
      label={formatMessage("Identification.personalData.documentType")}
      value={formatDocumentType(props.documentType)}
      size="medium"
      feedback={formatClientProfileFeedback("documentType")}
    />
  );

  const dateOfIssuingField = renderDateFieldOrReadOnly({
    name: "dateOfIssuing",
    label: formatMessage("Identification.personalData.dateOfIssuing"),
    placeholder: formatMessage("Identification.personalData.dateOfIssuing"),
  });

  const rendeIssuerFieldOrReadOnly = props.readOnly ? (
    <ReadOnlyField
      size="medium"
      label={formatMessage("Identification.personalData.placeOfIssue")}
      value={pipe(props.values.issuer, option.getOrElse(constant("")))}
      feedback={formatFeedback("issuer")}
    />
  ) : (
    <TextField
      {...props.fieldProps("issuer")}
      onChange={value =>
        props.fieldProps("issuer").onChange(option.some(value))
      }
      value={pipe(
        props.fieldProps("issuer").value,
        option.getOrElse(constant(""))
      )}
      label={formatMessage("Identification.personalData.placeOfIssue")}
      placeholder={formatMessage("Identification.personalData.placeOfIssue")}
      issues={formatIssues("issuer", props.fieldProps("issuer").issues)}
    />
  );

  const showAuthority = props.readOnly
    ? props.authorityMandatory ||
      (option.isSome(props.values.authority) &&
        props.values.authority.value.trim() !== "")
    : true;
  const showIssuer = props.readOnly
    ? props.issuerMandatory ||
      (option.isSome(props.values.issuer) &&
        props.values.issuer.value.trim() !== "")
    : true;

  return (
    <Form data-test-id="personal_document">
      <FormSection
        heading={props.heading}
        errors={pipe(props.errors, option.map(nonEmptyArray.map(e => e.value)))}
      >
        {pipe(
          props.issue,
          option.fold(constNull, issue =>
            foldIssue(issue, {
              DifferentDocument: constNull,
              PrimaryDocumentExpired: () => expiredBanner,
              PrimaryDocumentLostOrStolen: () =>
                props.checkLostStolen ? lostOrStolenBanner : null,
              SecondaryDocumentExpired: () => expiredBanner,
              SecondaryDocumentLostOrStolen: () =>
                props.checkLostStolen ? lostOrStolenBanner : null,
              NoPrimaryDocument: constNull,
            })
          )
        )}
        {props.showDocumentType ? (
          <FormRow type="1-1">
            {documentTypeField}
            {idNumberField}
          </FormRow>
        ) : (
          <FormRow type="full">{idNumberField}</FormRow>
        )}
        <FormRow type="1-1">
          {dateOfIssuingField}
          {renderValidUntilField}
        </FormRow>
        {showAuthority && showIssuer ? (
          <FormRow type="1-1">
            {renderAuthorityFieldOrReadOnly}
            {rendeIssuerFieldOrReadOnly}
          </FormRow>
        ) : showAuthority ? (
          <FormRow type="full">{renderAuthorityFieldOrReadOnly}</FormRow>
        ) : showIssuer ? (
          <FormRow type="full">{rendeIssuerFieldOrReadOnly}</FormRow>
        ) : (
          <></>
        )}
      </FormSection>
    </Form>
  );
}
