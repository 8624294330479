import { FC, ReactElement, useMemo } from "react";
import { option, taskEither } from "fp-ts";
import { AddressOCR, AddressWrite } from "../domain";
import { Option } from "fp-ts/Option";
import {
  Box,
  Button,
  Heading,
  Space,
  Tooltip,
  InfoIcon,
  Launcher,
  PinIcon,
  Card,
  Body,
  Children,
  LocalizedString,
} from "design-system";
import { AddressForm } from "../AddressForm/AddressForm";

import { useFormatMessage } from "../../intl";
import { constFalse, constNull, constTrue, pipe } from "fp-ts/function";
import { palette } from "design-system/lib/styleConstants";
import {
  formStateFromAddressOCR,
  useDefaultCountryCode,
} from "../addressFormUtils";
import { AddressFormEdit } from "../AddressForm/AddressFormEdit";
import { TaskEither } from "fp-ts/TaskEither";
import { ReworkAddress } from "../../MortgageDashboard/domain";

type Props = {
  initialState: Option<AddressOCR>;
  isEditing: boolean;
  submitInvalid: boolean;
  onSave: (address: AddressWrite, isNew: boolean) => TaskEither<unknown, void>;
  onRemove: Option<() => unknown>;
  onEdit: Option<() => unknown>;
  onCancel: () => unknown;
  title: LocalizedString;
  subtitle: Option<LocalizedString>;
  hint: Option<LocalizedString>;
  disabled: boolean;
  tooltipContent: LocalizedString;
  "data-test-id"?: string;
  lockCountryEdit?: boolean;
  optionalFields?: boolean;
  reworkAddress: Option<ReworkAddress>;
  foreignSupport?: boolean;
  forceValidateForForeign?: boolean;
  hasResidency?: () => boolean;
  lockCzSk?: boolean;
} & (
  | {
      addNewContent: () => Children;
    }
  | {
      addNewContent?: never;
      addressDescription: LocalizedString;
      addAddressLabel: LocalizedString;
    }
);

type AddressWrapperProps = {
  isEditing: boolean;
  wrapper: (children: Children) => ReactElement;
  editingWrapper: (children: Children) => ReactElement;
};

const AddressWrapper: FC<AddressWrapperProps> = ({
  children,
  isEditing,
  wrapper,
  editingWrapper,
}) =>
  isEditing
    ? editingWrapper(children as Children)
    : wrapper(children as Children);

export function AddressBlock(props: Props) {
  const formatMessage = useFormatMessage();

  function foldStatus<T>(match: {
    whenAddNew: () => T;
    whenReadOnly: (address: AddressOCR) => T;
    whenEditing: () => T;
  }): T {
    if (props.isEditing) {
      return match.whenEditing();
    }
    return pipe(
      props.initialState,
      option.fold(match.whenAddNew, state => match.whenReadOnly(state))
    );
  }

  const displayTooltipContent = () => (
    <>
      <Space units={1} />
      <Tooltip content={props.tooltipContent}>
        <InfoIcon size="default" color={palette.blue600} />
      </Tooltip>
    </>
  );

  const controlButtons = foldStatus({
    whenReadOnly: () => (
      <>
        <Space fluid />
        {pipe(
          props.onEdit,
          option.fold(constNull, onEdit => (
            <Button
              variant="text"
              size="default"
              action={onEdit}
              label={formatMessage("Identification.additionalAddresses.edit")}
              disabled={props.disabled}
            />
          ))
        )}
        {pipe(
          props.onRemove,
          option.fold(constNull, onRemove => (
            <>
              <Space units={4} />
              <Button
                variant="text"
                size="default"
                action={onRemove}
                label={formatMessage(
                  "Identification.additionalAddresses.remove"
                )}
                disabled={props.disabled}
              />
            </>
          ))
        )}
      </>
    ),
    whenAddNew: displayTooltipContent,
    whenEditing: displayTooltipContent,
  });

  const defaultCountry = useDefaultCountryCode();

  const initialValues = useMemo(() => {
    return pipe(
      props.initialState,
      option.map(addressOCR =>
        formStateFromAddressOCR(addressOCR, defaultCountry)
      )
    );
  }, [props.initialState]);

  const saveAddress = (address: AddressWrite) =>
    props.onSave(
      address,
      pipe(props.initialState, option.fold(constTrue, constFalse))
    );

  return (
    <AddressWrapper
      isEditing={props.isEditing}
      wrapper={children => (
        <Box column data-test-id={props["data-test-id"]}>
          {children}
        </Box>
      )}
      editingWrapper={children => (
        <Card data-test-id={props["data-test-id"]}>
          <Box grow shrink column>
            {children}
          </Box>
        </Card>
      )}
    >
      <Box vAlignContent="center">
        <Box column shrink>
          <Heading size="x-small" weight="medium">
            {props.title}
          </Heading>
          {pipe(
            props.subtitle,
            option.fold(constNull, subtitle => (
              <>
                <Space units={2} />
                <Body size="small" weight="regular">
                  {subtitle}
                </Body>
              </>
            ))
          )}
        </Box>
        {controlButtons}
      </Box>
      <Space units={4} />
      {foldStatus({
        whenAddNew:
          props.addNewContent ||
          (() =>
            pipe(
              props.onEdit,
              option.fold(constNull, onEdit => (
                <Launcher
                  variant="small"
                  action={onEdit}
                  buttonLabel={props.addAddressLabel}
                  heading={props.addressDescription}
                  icon={PinIcon}
                  disabled={props.disabled}
                />
              ))
            )),
        whenEditing: () => (
          <AddressFormEdit
            optionalFields={props.optionalFields}
            onValidate={taskEither.right}
            onSuggestionSubmit={saveAddress}
            onSubmit={saveAddress}
            initialValues={initialValues}
            onCancel={props.onCancel}
            hint={props.hint}
            submitInvalid={props.submitInvalid}
            lockCountryEdit={props.lockCountryEdit}
            foreignSupport={props.foreignSupport}
            forceValidateForForeign={
              props.forceValidateForForeign != undefined
                ? props.forceValidateForForeign
                : true
            }
            hasResidency={props.hasResidency}
            lockCzSk={!!props.lockCzSk}
          />
        ),
        whenReadOnly: address => (
          <AddressForm
            readOnly
            values={formStateFromAddressOCR(address, defaultCountry)}
            hint={props.hint}
            errors={option.none}
            reworkAddress={props.reworkAddress}
            lockCzSk={!!props.lockCzSk}
          />
        ),
      })}
    </AddressWrapper>
  );
}
