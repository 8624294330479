import { useEffect, useRef, useState } from "react";
import { constant, constFalse, constNull, flow, pipe } from "fp-ts/function";
import { useFormatMessage } from "../../intl";
import { useCommand } from "../../useAPI";
import * as uploadIdApi from "../api";
import { PersonalInfoEditError, ResidencySubmitInput } from "../api";
import {
  array,
  boolean,
  eq,
  nonEmptyArray,
  option,
  record,
  semigroup,
  taskEither,
} from "fp-ts";
import {
  AddressWrite,
  AllCitizenships,
  ClientDataEdit,
  PersonalInfoError,
  PersonalProfileDocUploadSubmitError,
} from "../domain";
import {
  Action,
  ActionButtonGroup,
  Banner,
  Dialog,
  Divider,
  LocalizedString,
  Stack,
  unsafeLocalizedString,
  useForm,
  useIsMobileLayout,
  WarningIcon,
} from "design-system";
import {
  AdditionalPersonalDataProps,
  AddressSuggestionsProps,
  ClientDataForm,
} from "./ClientDataForm";
import {
  CoApplicantInput,
  CountryCode,
  DocumentIdentificationFlow,
  DocumentPurpose,
  foldTenant,
  GenericError,
  genericError,
  UploadDocumentFlowType,
} from "../../globalDomain";
import { Option } from "fp-ts/Option";
import { NonEmptyArray } from "fp-ts/NonEmptyArray";

import { TaskEither } from "fp-ts/TaskEither";
import { ReaderTaskEither } from "fp-ts/ReaderTaskEither";
import {
  clientExistencyCheckDataFromClientData,
  clientExistencyCheckDataFromPersonalData,
  ClientExistencyCheckDataPartial,
  ClientExistencyCommand,
  convertToCheckDataBn,
  useClientExistencyCheck,
} from "../../UploadDocuments/clientExistenceUtils";
import { useAppContext } from "../../useAppContext";
import { AdditionalChecks } from "./AdditionalChecks";
import { selectedMainApplicant } from "../../MortgageDashboard/mortgageDashboardUtils";
import { useValidators } from "../../Common/useValidators";
import { CheckClientDataResult } from "../../UploadDocuments/useCheckClientDataResult";
import { useParentSharedReducer } from "../../BranchExperience/useSharedReducer";
import { editDataAction, reducerConfig, shareWithClientAction } from "./state";
import { useBranchExperienceContext } from "../../BranchExperience/BranchExperienceContext";

import { useShareWithClientButtonProps } from "../../Common/ShareWithClientButton/useShareWithClientButtonProps";
import { useIsInPersonChannel } from "../../useChannel";
import { PersonalDataProcessingDisclaimer } from "../../Common/PersonalDataProcessingDisclaimer/PersonalDataProcessingDisclaimer";
import { usePortalStatusContext } from "../../PortalStatusContext";
import { YesNoRadioGroupField } from "../../Common/YesNoRadioGroup/YesNoRadioGroupField";

type Props = {
  extractedData: CheckClientDataResult;
  documentIdentificationFlow: DocumentIdentificationFlow;
  onContinue: (
    clientExistencyCheckData: Option<ClientExistencyCheckDataPartial>
  ) => TaskEither<unknown, unknown>;
  onUploadAgain: TaskEither<unknown, unknown>;
  onAbort: (reason: "GenericError" | "MaxAttemptsReached") => unknown;
  onShouldClientContinue: Option<
    (
      clientExists: boolean,
      duplicateContacts: boolean,
      hasBlockingNotes: boolean,
      personalNumberMatch: boolean,
      userID: Option<string>,
      clientNumber: Option<string>
    ) => TaskEither<unknown, boolean>
  >;
  banner?: JSX.Element;
  clientExistencyCommand: ClientExistencyCommand;
  submitDataCommand: ReaderTaskEither<
    uploadIdApi.PersonalInfoSubmitInput,
    PersonalProfileDocUploadSubmitError | PersonalInfoError | GenericError,
    unknown
  >;
  editPersonalInfoCommand: ReaderTaskEither<
    uploadIdApi.PersonalInfoEditInput,
    PersonalInfoEditError | GenericError,
    unknown
  >;
  submitResidencyCommand: ReaderTaskEither<
    ResidencySubmitInput,
    unknown,
    unknown
  >;
  documentsMismatch: boolean;
  onMismatchDialogDismiss: () => unknown;
  showMismatchDialog: () => unknown;
  noGoBack?: boolean;
  onReset: () => unknown;
  onDataChange: () => unknown;
  productType: Option<UploadDocumentFlowType>;
  hasParentPadding?: boolean;
  supportForeign: boolean;
  hasResidency: Option<boolean>;
  onResidencyChange: (isResident: Option<boolean>) => unknown;
  isWaitingForOpuAndCex?: boolean;
} & CoApplicantInput;

export type ClientDataErrors = Omit<PersonalInfoError, "generalError"> & {
  generalError: Option<NonEmptyArray<LocalizedString>>;
  additionalDocumentDetails: Option<NonEmptyArray<LocalizedString>>;
};

export function ConfirmClientData(props: Props) {
  const formatMessage = useFormatMessage();
  const isMobileLayout = useIsMobileLayout();
  const [canceled, setCanceled] = useState(false);

  const { definedNoExtract } = useValidators();

  const editCountryOfBirth = useCommand(uploadIdApi.countryOfBirthSubmit);
  const editSecondCitizenship = useCommand(uploadIdApi.secondCitizenshipSubmit);
  const clientExistencyCheck = useClientExistencyCheck(
    props.clientExistencyCommand,
    () => genericError
  );

  const {
    apiParameters: { tenant },
    config: { r1EnableForeignNonResidentSupport: nonResidentSupported },
  } = useAppContext();
  const isInPerson = useIsInPersonChannel();
  const isPersonalProfile = pipe(
    props.productType,
    option.exists(item => item === "PersonalProfile")
  );

  const [state, dispatch] = useParentSharedReducer(reducerConfig, {
    id: "notShared",
  });

  const { branchExperienceFeaturesActive } = useBranchExperienceContext();
  const { portalBlocked } = usePortalStatusContext();

  const [sectionErrors, setSectionErrors] = useState<Option<ClientDataErrors>>(
    option.none
  );

  const [isEditing, setIsEditing] = useState(false);
  const isForeign = pipe(
    props.extractedData.personalData.citizenship,
    option.fold(
      constFalse,
      citizenship => citizenship !== "SVK" && citizenship !== "CZE"
    )
  );

  const residencySupport =
    nonResidentSupported && isForeign && !isPersonalProfile;

  const showAdditionalChecks = foldTenant(
    tenant,
    constFalse,
    //SBL-170386 -> not only for SK
    constFalse
  );

  const askAdditionalPersonalData =
    isInPerson ||
    option.isNone(props.extractedData.personalData.countryOfBirth);

  const applicantIndex = pipe(
    props.coApplicant,
    option.fold(
      () => selectedMainApplicant.index,
      i => i.index
    )
  );

  const latestClientExistencyCheckData = useRef(
    clientExistencyCheckDataFromClientData({
      ...props.extractedData,
      applicantIndex,
    })
  );

  const genericClientDataErrors: ClientDataErrors = {
    personalData: option.none,
    permanentAddress: option.none,
    documentDetails: option.none,
    additionalDocumentDetails: option.none,
    generalError: option.some([
      formatMessage("Identification.UploadDocuments.genericError"),
    ]),
  };

  const mapMainDocumentErrors = (
    errors:
      | PersonalProfileDocUploadSubmitError
      | PersonalInfoError
      | GenericError
  ): ClientDataErrors => {
    if (
      GenericError.is(errors) ||
      pipe(
        !PersonalProfileDocUploadSubmitError.is(errors)
          ? pipe(errors, record.every(option.isNone))
          : false
      )
    ) {
      return genericClientDataErrors;
    }

    return {
      ...errors,
      additionalDocumentDetails: option.none,
      generalError: pipe(
        errors.generalError,
        option.alt(() =>
          pipe(
            PersonalProfileDocUploadSubmitError.is(errors) &&
              errors.compareDocs,
            boolean.fold(
              () =>
                option.of<NonEmptyArray<LocalizedString>>([
                  formatMessage("Identification.UploadDocuments.wrongData"),
                ]),
              () => option.none
            )
          )
        )
      ),
    };
  };

  const mapAdditionalDocumentErrors = (
    errors: PersonalInfoError | GenericError
  ): ClientDataErrors => {
    if (GenericError.is(errors) || pipe(errors, record.every(option.isNone))) {
      return genericClientDataErrors;
    }

    return {
      personalData: option.none,
      permanentAddress: option.none,
      documentDetails: option.none,
      additionalDocumentDetails: errors.documentDetails,
      generalError: pipe(
        errors.generalError,
        option.alt(() =>
          option.of<NonEmptyArray<LocalizedString>>([
            formatMessage("Identification.UploadDocuments.wrongData"),
          ])
        )
      ),
    };
  };

  function isSingleDocumentPurpose(
    flowType: DocumentIdentificationFlow
  ): flowType is DocumentPurpose {
    return flowType === "Primary" || flowType === "Secondary";
  }

  const submitCountryOfBirth = (
    countryOfBirth: Option<CountryCode>
  ): TaskEither<ClientDataErrors, unknown> =>
    pipe(
      countryOfBirth,
      option.fold(
        () => taskEither.right(undefined),
        countryOfBirth =>
          pipe(
            editCountryOfBirth({
              applicantIndex,
              countryOfBirth,
              idType: pipe(
                props.documentIdentificationFlow,
                option.fromPredicate(isSingleDocumentPurpose)
              ),
            }),
            taskEither.mapLeft(() => genericClientDataErrors)
          )
      )
    );

  const selectAddressSuggestion = (
    suggestion: Option<AddressWrite>
  ): TaskEither<ClientDataErrors, unknown> =>
    pipe(
      suggestion,
      option.fold(
        () => taskEither.right(undefined),
        suggestion =>
          pipe(
            onEditData(
              {
                permanentAddress: {
                  ...suggestion,
                },
              },
              props.documentIdentificationFlow === "Secondary"
                ? "Secondary"
                : "Primary",
              "permanentAddress"
            ),
            taskEither.mapLeft(() => genericClientDataErrors)
          )
      )
    );

  const submitSecondCitizenship = (
    secondCitizenship: Option<AllCitizenships>
  ): TaskEither<ClientDataErrors, unknown> =>
    pipe(
      secondCitizenship,
      option.fold(
        () => taskEither.right(undefined),
        secondCitizenship =>
          pipe(
            editSecondCitizenship({
              applicantIndex,
              secondCitizenship,
            }),
            taskEither.mapLeft(() => genericClientDataErrors)
          )
      )
    );

  useEffect(() => {
    pipe(
      props.extractedData.validationErrors,
      option.map(errors =>
        pipe(errors, mapMainDocumentErrors, option.some, setSectionErrors)
      )
    );
  }, []);

  const validateData = (
    residency: Option<boolean>
  ): TaskEither<ClientDataErrors, unknown> => {
    const mainSubmit = pipe(
      props.submitDataCommand({
        coApplicant: props.coApplicant,
        idType: pipe(
          props.documentIdentificationFlow,
          option.fromPredicate(isSingleDocumentPurpose),
          option.getOrElse<DocumentPurpose>(constant("Primary"))
        ),
      }),
      taskEither.mapLeft(mapMainDocumentErrors)
    );

    const additionalSubmit =
      props.documentIdentificationFlow === "PrimaryAndSecondary"
        ? pipe(
            props.submitDataCommand({
              coApplicant: props.coApplicant,
              idType: "Secondary",
            }),
            taskEither.mapLeft(mapAdditionalDocumentErrors)
          )
        : taskEither.right(undefined);

    const residencySubmit = pipe(
      residency,
      option.fold(
        () => taskEither.right(undefined),
        residency =>
          pipe(
            props.submitResidencyCommand({ residency: residency }),
            taskEither.mapLeft(() => genericClientDataErrors)
          )
      )
    );

    const validator = taskEither.getTaskValidation(
      semigroup.getStructSemigroup({
        personalData: semigroup.getFirstSemigroup<
          Option<NonEmptyArray<LocalizedString>>
        >(),
        permanentAddress: semigroup.getFirstSemigroup<
          Option<NonEmptyArray<LocalizedString>>
        >(),
        documentDetails: semigroup.getFirstSemigroup<
          Option<NonEmptyArray<LocalizedString>>
        >(),
        additionalDocumentDetails: semigroup.getLastSemigroup<
          Option<NonEmptyArray<LocalizedString>>
        >(),
        generalError: semigroup.getLastSemigroup<
          Option<NonEmptyArray<LocalizedString>>
        >(),
      })
    );

    return pipe(
      [mainSubmit, additionalSubmit, residencySubmit],
      array.sequence(validator),
      taskEither.mapLeft(e => ({
        ...e,
        generalError: pipe(
          e.generalError,
          option.chain(
            flow(
              array.uniq<LocalizedString>(eq.eqString),
              nonEmptyArray.fromArray
            )
          )
        ),
      }))
    );
  };

  const onContinue = (): TaskEither<ClientDataErrors, unknown> => {
    return isForeign
      ? taskEither.fromIO(() =>
          props.onContinue(latestClientExistencyCheckData.current)()
        )
      : pipe(
          props.onShouldClientContinue,
          option.fold(
            () => props.onContinue(latestClientExistencyCheckData.current),
            onShouldClientContinue =>
              pipe(
                latestClientExistencyCheckData.current,
                convertToCheckDataBn,
                taskEither.fromOption(constant(genericError)),
                taskEither.chain(clientExistencyCheck),
                taskEither.mapLeft(() => genericError),
                taskEither.chain(
                  ({
                    clientExists,
                    duplicateContacts,
                    hasBlockingNotes,
                    personalNumberMatch,
                    userID,
                    clientNumber,
                  }) =>
                    pipe(
                      onShouldClientContinue(
                        clientExists,
                        duplicateContacts,
                        hasBlockingNotes,
                        personalNumberMatch,
                        userID,
                        clientNumber
                      ),
                      taskEither.mapLeft(constant(genericError))
                    )
                ),
                taskEither.chain(shouldContinue =>
                  pipe(
                    shouldContinue,
                    boolean.fold(
                      () => taskEither.left(genericError),
                      () =>
                        pipe(
                          props.onContinue(
                            latestClientExistencyCheckData.current
                          ),
                          taskEither.mapLeft(() => genericError)
                        )
                    )
                  )
                )
              )
          ),
          taskEither.mapLeft(constant(genericClientDataErrors))
        );
  };

  const { fieldProps, handleSubmit } = useForm(
    {
      initialValues: {
        countryOfBirth: pipe(
          props.extractedData.personalData.countryOfBirth,
          option.filter(constant(askAdditionalPersonalData))
        ),
        addressSuggestion: option.none as Option<AddressWrite>,
        hasResidency: residencySupport ? props.hasResidency : option.none,
        secondCitizenshipField:
          props.extractedData.personalData.secondCitizenship,
        secondCitizenshipRadio: option.some(
          option.isSome(props.extractedData.personalData.secondCitizenship)
        ),
      },
      fieldValidators: values => ({
        countryOfBirth: askAdditionalPersonalData
          ? definedNoExtract<CountryCode>()
          : undefined,
        addressSuggestion:
          option.isSome(props.extractedData.addressSuggestions) &&
          !props.extractedData.canEdit
            ? definedNoExtract<AddressWrite>()
            : undefined,
        hasResidency: residencySupport
          ? definedNoExtract<boolean>()
          : undefined,
        secondCitizenshipRadio: definedNoExtract<boolean>(),
        secondCitizenshipField: pipe(
          values.secondCitizenshipRadio,
          option.getOrElse(constFalse)
        )
          ? definedNoExtract<AllCitizenships>()
          : undefined,
      }),
    },
    {
      onSubmit: ({
        countryOfBirth,
        addressSuggestion,
        hasResidency,
        secondCitizenshipRadio,
        secondCitizenshipField,
      }) => {
        const isCobSame = option
          .getEq(eq.eqString)
          .equals(
            countryOfBirth,
            props.extractedData.personalData.countryOfBirth
          );
        const hasSecondCitizenship = pipe(
          secondCitizenshipRadio,
          option.getOrElse(constFalse)
        );
        return pipe(
          state.id === "notShared" && !isCobSame
            ? pipe(countryOfBirth, submitCountryOfBirth)
            : taskEither.right(undefined),
          taskEither.chain(() => {
            return state.id === "notShared"
              ? selectAddressSuggestion(addressSuggestion)
              : taskEither.right(undefined);
          }),
          taskEither.chain(() => {
            return state.id === "notShared" && hasSecondCitizenship
              ? submitSecondCitizenship(secondCitizenshipField)
              : taskEither.right(undefined);
          }),
          taskEither.chain(() => {
            return pipe(
              sectionErrors,
              option.fold(
                () => taskEither.right(undefined),
                err => {
                  if (canceled) {
                    props.showMismatchDialog();
                  }
                  return taskEither.left(err);
                }
              )
            );
          }),
          taskEither.chain(() =>
            state.id === "notShared" && branchExperienceFeaturesActive
              ? taskEither.fromIO(() => dispatch(shareWithClientAction))
              : pipe(validateData(hasResidency), taskEither.chain(onContinue))
          ),
          taskEither.mapLeft(flow(option.some, setSectionErrors))
        );
      },
    }
  );

  const onEditData = (
    newClientData: ClientDataEdit,
    idType: DocumentPurpose,
    subform: keyof ClientDataEdit
  ) => {
    return pipe(
      props.editPersonalInfoCommand({
        clientData: newClientData,
        coApplicant: props.coApplicant,
        idType,
      }),
      taskEither.chain(() =>
        taskEither.fromIO(() => {
          pipe(
            sectionErrors,
            option.map(err => {
              const updatedSectionErrors = { ...err, [subform]: option.none };
              const { generalError, ...subformErrors } = updatedSectionErrors;
              const subformsHaveError = Object.values(subformErrors).some(
                option.isSome
              );
              if (subformsHaveError) {
                setSectionErrors(option.some(updatedSectionErrors));
              } else {
                setSectionErrors(option.none);
              }
            })
          );

          dispatch(editDataAction);
          if (newClientData.personalData) {
            latestClientExistencyCheckData.current = option.some(
              clientExistencyCheckDataFromPersonalData({
                ...newClientData.personalData,
                applicantIndex,
              })
            );
          }
        })
      )
    );
  };

  const documentsMismatchDialog = pipe(
    props.documentsMismatch,
    boolean.fold(constNull, () => {
      return (
        <Dialog
          variant="center"
          size="medium"
          title={formatMessage(
            "Identification.UploadDocuments.uploadedDocumentMismatchModal.title"
          )}
          subtitle={
            props.noGoBack === true
              ? unsafeLocalizedString("")
              : formatMessage(
                  "Identification.UploadDocuments.uploadedDocumentMismatchModal.subtitle"
                )
          }
          icon={WarningIcon}
          onDismiss={option.none}
          actions={[
            {
              variant: "text",
              label:
                props.noGoBack === true
                  ? formatMessage(
                      "Identification.UploadDocuments.uploadedDocumentMismatchModal.goBackLink.no.label"
                    )
                  : formatMessage(
                      "Identification.UploadDocuments.uploadedDocumentMismatchModal.goBackLink.label"
                    ),
              action:
                props.noGoBack === true
                  ? () => {
                      setCanceled(true);
                      return props.onMismatchDialogDismiss();
                    }
                  : () => flow(props.onReset, props.onMismatchDialogDismiss)(),
            },
            {
              variant: "primary",
              label: formatMessage(
                "Identification.UploadDocuments.uploadedDocumentMismatchModal.uploadAgainButton.label"
              ),
              action: flow(props.onUploadAgain, props.onMismatchDialogDismiss),
            },
          ]}
        />
      );
    })
  );

  const shareWithClientButtonProps = useShareWithClientButtonProps({
    branchExperienceState: state.id,
    action: handleSubmit,
    submitLabel: formatMessage("Identification.personalData.continue"),
    disabled: isEditing || portalBlocked,
  });

  const actions = pipe(
    [
      option.fromPredicate<Action>(
        constant(props.extractedData.canUploadAgain)
      )({
        variant: "secondary",
        label: formatMessage("Identification.UploadDocuments.uploadAgain"),
        action: props.onUploadAgain,
      }),
      option.some<Action>({
        variant: "loading",
        ...shareWithClientButtonProps,
        disabled: props.isWaitingForOpuAndCex,
      }),
    ],
    array.compact
  ) as [Action] | [Action, Action];

  const additionalPersonalDataProps: AdditionalPersonalDataProps = askAdditionalPersonalData
    ? {
        askAdditionalPersonalData: true,
        additionalPersonalData: {
          countryOfBirthFieldProps: {
            ...fieldProps("countryOfBirth"),
            onChange: (value: Option<CountryCode>) => {
              dispatch(editDataAction);
              fieldProps("countryOfBirth").onChange(value);
            },
          },
        },
      }
    : { askAdditionalPersonalData: false };

  const addressSuggestionsProps: AddressSuggestionsProps = pipe(
    props.extractedData.addressSuggestions,
    option.filter(() => !props.extractedData.canEdit),
    option.fold(
      (): AddressSuggestionsProps => ({ mustSelectAddressSuggestion: false }),
      suggestions => ({
        mustSelectAddressSuggestion: true,
        addressSuggestionProps: {
          ...fieldProps("addressSuggestion"),
          onChange: (value: Option<AddressWrite>) => {
            dispatch(editDataAction);
            fieldProps("addressSuggestion").onChange(value);
          },
        },
        addressSuggestions: suggestions,
      })
    )
  );

  return (
    <Stack
      units={isMobileLayout ? 6 : 10}
      column
      shrink
      style={props.hasParentPadding === false ? { padding: 24 } : undefined}
    >
      {props.banner}
      <ClientDataForm
        documentIdentificationFlow={
          props.documentIdentificationFlow === "PrimaryAndLivenessCheck"
            ? "Primary"
            : props.documentIdentificationFlow
        }
        extractedData={props.extractedData}
        canEdit={props.extractedData.canEdit && !portalBlocked}
        errors={sectionErrors}
        onEdit={onEditData}
        setIsEditing={setIsEditing}
        onDataChange={props.onDataChange}
        productType={props.productType}
        supportForeign={props.supportForeign}
        canAutoEdit={true}
        secondCitizenshipRadio={fieldProps("secondCitizenshipRadio")}
        secondCitizenshipField={fieldProps("secondCitizenshipField")}
        {...additionalPersonalDataProps}
        {...addressSuggestionsProps}
      />
      {showAdditionalChecks && <Divider />}
      {showAdditionalChecks && (
        <AdditionalChecks
          coApplicant={props.coApplicant}
          documentIdentificationFlow={props.documentIdentificationFlow}
          canUploadAgain={props.extractedData.canUploadAgain}
          onUploadAgain={props.onUploadAgain}
          onAbort={props.onAbort}
        />
      )}
      <PersonalDataProcessingDisclaimer />
      {/* The following fragment is a temporary workaround for: https://buildo.kaiten.io/space/29286/card/2311626 */}
      <>
        {state.id === "sharedWithClient" && (
          <Banner
            type="informative"
            title={option.none}
            actions={option.none}
            onDismiss={option.none}
            content={formatMessage("BranchExperience.waitingConfirmation")}
          />
        )}
      </>
      {residencySupport && (
        <YesNoRadioGroupField
          {...fieldProps("hasResidency")}
          label={unsafeLocalizedString("Client with residency")}
          onChange={value => {
            fieldProps("hasResidency").onChange(value);
            props.onResidencyChange(value);
          }}
        />
      )}

      <ActionButtonGroup hAlignContent="right" actions={actions} />
      {documentsMismatchDialog}
    </Stack>
  );
}
