import {
  AllCitizenships,
  AllSupportedCitizenships,
  Citizenship,
  PersonalData,
  PersonalDataOCR,
} from "../../domain";
import {
  ComputedFieldProps,
  DateField,
  ExtractFieldsOfType,
  Form,
  FormRow,
  FormSection,
  Issue,
  LocalizedString,
  RadioGroupField,
  ReadOnlyField,
  TextField,
  ValidableDate,
  validableDate,
} from "design-system";
import {
  useFormatMaritalStatus,
  useFormatMessage,
  useFormatSex,
} from "../../../intl";

import { constant, constFalse, identity, pipe } from "fp-ts/function";
import { array, nonEmptyArray, option, record } from "fp-ts";
import { Option } from "fp-ts/Option";
import { NonEmptyArray } from "fp-ts/NonEmptyArray";

import { CountryCode, MaritalStatus, Sex } from "../../../globalDomain";
import { MaritalStatusDropdown } from "../../../Common/MaritalStatusDropdown/MaritalStatusDropdown";
import { CitizenshipDropdown } from "./CitizenshipDropdown";
import { TitleDropdown } from "./TitleDropdown";
import { NonEmptyString } from "io-ts-types/lib/NonEmptyString";
import { ReadOnlyDateField } from "../../../Common/ReadOnlyDateField/ReadOnlyDateField";
import { CountryOfBirthDropdown } from "../CountryOfBirthDropdown";
import { ReworkPersonalData } from "../../../MortgageDashboard/domain";
import { useFormatReworkData } from "../../../MortgageDashboard/useFormatReworkData";
import { useQuery } from "../../../useAPI";
import * as idUploadApi from "../../../IdUpload/api";
import * as remoteData from "../../../RemoteData";
import { isForeign } from "../../utils";
import { DocumentType } from "../../../UploadDocuments/domain";
import { useAppContext } from "../../../useAppContext";

export type FormState = {
  title: Option<NonEmptyString>;
  titleAfter: Option<NonEmptyString>;
  name: string;
  surname: string;
  sex: Option<Sex>;
  maritalStatus: Option<MaritalStatus>;
  birthNumber: Option<NonEmptyString>;
  placeOfBirth: string;
  countryOfBirth: Option<CountryCode>;
  dateOfBirth: Option<ValidableDate>;
  citizenship: Option<AllSupportedCitizenships>;
  secondCitizenship: Option<AllCitizenships>;
  localPersonalNumber: Option<NonEmptyString>;
};

export function formStateFromPersonalDataOCR(
  personalData: PersonalDataOCR
): FormState {
  return {
    title: personalData.title,
    titleAfter: personalData.titleAfter,
    name: pipe(personalData.name, option.getOrElse(constant(""))),
    surname: pipe(personalData.surname, option.getOrElse(constant(""))),
    sex: personalData.sex,
    maritalStatus: personalData.maritalStatus,
    birthNumber: personalData.birthNumber,
    dateOfBirth: pipe(
      personalData.dateOfBirth,
      option.map(validableDate.fromDate)
    ),
    placeOfBirth: pipe(
      personalData.placeOfBirth,
      option.getOrElse(constant(""))
    ),
    countryOfBirth: personalData.countryOfBirth,
    citizenship: pipe(
      personalData.citizenship,
      option.filter(
        (citizenship): citizenship is Citizenship => citizenship !== "OTHER"
      )
    ),
    localPersonalNumber: personalData.localPersonalNumber,
    secondCitizenship: personalData.secondCitizenship,
  };
}

export function formStateFromPersonalData(
  personalData: PersonalData
): FormState {
  return {
    title: personalData.title,
    titleAfter: personalData.titleAfter,
    name: personalData.name,
    surname: personalData.surname,
    sex: option.some(personalData.sex),
    maritalStatus: personalData.maritalStatus,
    birthNumber: personalData.birthNumber,
    dateOfBirth: option.some(validableDate.fromDate(personalData.dateOfBirth)),
    placeOfBirth: personalData.placeOfBirth,
    countryOfBirth: personalData.countryOfBirth,
    citizenship: option.some(personalData.citizenship),
    localPersonalNumber: personalData.localPersonalNumber,
    secondCitizenship: personalData.secondCitizenship,
  };
}

type Props = {
  errors: Option<NonEmptyArray<Issue>>;
  heading?: React.ComponentProps<typeof FormSection>["heading"];
  reworkPersonalData: Option<ReworkPersonalData>;
  documentType?: DocumentType;
} & (
  | {
      readOnly: true;
      displayCountryOfBirth: boolean;
      values: FormState;
      fieldProps?: never;
      secondCitizenship: Option<AllCitizenships>; // specified explicitly for control when to show since it is not on any document so not really "personal data"
    }
  | {
      readOnly: false;
      displayCountryOfBirth: boolean;
      fieldProps: <K extends keyof FormState>(
        name: K
      ) => ComputedFieldProps<FormState[K]>;
      values?: never;
      supportForeign: boolean;
    }
);

export function PersonalDataForm(props: Props) {
  const {
    config: { r6NewSironMapping: newSironMapping },
  } = useAppContext();
  const formatMessage = useFormatMessage();
  const formatSex = useFormatSex();
  const formatMaritalStatus = useFormatMaritalStatus();

  const isSupportedForeign = pipe(
    props.readOnly
      ? props.values.citizenship
      : props.fieldProps("citizenship").value,
    isForeign
  );

  const { formatIssues, formatFeedback } = useFormatReworkData(
    props.reworkPersonalData
  );

  const renderTextFieldOrReadOnly = ({
    name,
    label,
    placeholder,
  }: {
    name: ExtractFieldsOfType<FormState, string>;
    label: LocalizedString;
    placeholder: LocalizedString;
  }) =>
    props.readOnly ? (
      <ReadOnlyField
        size="medium"
        label={label}
        value={props.values[name]}
        feedback={formatFeedback(name)}
      />
    ) : (
      <TextField
        {...props.fieldProps(name)}
        label={label}
        placeholder={placeholder}
        issues={formatIssues(name, props.fieldProps(name).issues)}
      />
    );

  const sexOptions = pipe(
    PersonalData.props.sex.keys,
    rec => record.keys(rec) as NonEmptyArray<PersonalData["sex"]>
  );

  const renderSexFieldOrReadOnly = props.readOnly ? (
    <ReadOnlyField
      size="medium"
      label={formatMessage("Identification.personalData.sex")}
      value={pipe(props.values["sex"], option.fold(constant(""), formatSex))}
      feedback={formatFeedback("sex")}
    />
  ) : (
    <RadioGroupField
      options={sexOptions}
      renderOption={formatSex}
      optionKey={o => o.toString()}
      isOptionDisabled={constFalse}
      renderOptionChildren={() => option.none}
      {...props.fieldProps("sex")}
      label={formatMessage("Identification.personalData.sex")}
      variant="horizontal"
      issues={formatIssues("sex", props.fieldProps("sex").issues)}
    />
  );

  const renderMaritalStatusFieldOrReadOnly = props.readOnly ? (
    <ReadOnlyField
      size="medium"
      label={formatMessage("Identification.personalData.maritalStatus")}
      value={pipe(
        props.values.maritalStatus,
        option.fold(constant(""), maritalStatus =>
          formatMaritalStatus(maritalStatus)
        )
      )}
      feedback={formatFeedback("maritalStatus")}
    />
  ) : (
    <MaritalStatusDropdown
      {...props.fieldProps("maritalStatus")}
      label={formatMessage("Identification.personalData.maritalStatus")}
      placeholder={formatMessage("Identification.personalData.maritalStatus")}
      clearable
      issues={formatIssues(
        "maritalStatus",
        props.fieldProps("maritalStatus").issues
      )}
      isForeign={isSupportedForeign}
    />
  );

  const renderCountryOfBirthFieldOrReadOnly = props.readOnly ? (
    <CountryOfBirthDropdown
      readOnly
      value={props.values.countryOfBirth}
      feedback={formatFeedback("countryOfBirth")}
    />
  ) : (
    <CountryOfBirthDropdown
      readOnly={false}
      {...props.fieldProps("countryOfBirth")}
      issues={formatIssues(
        "countryOfBirth",
        props.fieldProps("countryOfBirth").issues
      )}
    />
  );

  const countries = pipe(
    useQuery(idUploadApi.allCountries),
    ([result]) => result,
    remoteData.fold(constant([]), constant([]), identity)
  );

  const renderSecondCitizenship =
    props.readOnly &&
    newSironMapping &&
    option.isSome(props.secondCitizenship) ? (
      <ReadOnlyField
        size="medium"
        label={formatMessage("SecondCitizenship.label")}
        value={pipe(
          countries,
          array.findFirst(c =>
            pipe(
              props.secondCitizenship,
              option.fold(constFalse, v => c.countryCode === v)
            )
          ),
          option.map(c => c.label),
          option.getOrElse(constant(""))
        )}
      />
    ) : null;

  const renderCitizenshipFieldOrReadOnly = props.readOnly ? (
    <ReadOnlyField
      size="medium"
      label={formatMessage("Identification.personalData.citizenship")}
      value={pipe(countries, items =>
        pipe(
          items,
          array.findFirst(c =>
            pipe(
              props.values["citizenship"],
              option.fold(constFalse, v => c.countryCode === v)
            )
          ),
          option.map(c => c.label),
          option.getOrElse(constant(""))
        )
      )}
      feedback={formatFeedback("citizenship")}
    />
  ) : (
    <CitizenshipDropdown
      {...props.fieldProps("citizenship")}
      label={formatMessage("Identification.personalData.citizenship")}
      placeholder={formatMessage("Identification.personalData.citizenship")}
      clearable
      issues={formatIssues(
        "citizenship",
        props.fieldProps("citizenship").issues
      )}
      supportForeign={props.supportForeign}
    />
  );

  const renderDateOfBirthFieldOrReadonly = props.readOnly ? (
    <ReadOnlyDateField
      format="dd-mm-yyyy"
      label={formatMessage("Identification.personalData.birthDate")}
      value={pipe(props.values.dateOfBirth, option.flatten)}
      feedback={formatFeedback("dateOfBirth")}
    />
  ) : (
    <DateField
      {...props.fieldProps("dateOfBirth")}
      label={formatMessage("Identification.personalData.birthDate")}
      placeholder={formatMessage("Identification.personalData.birthDate")}
      issues={formatIssues(
        "dateOfBirth",
        props.fieldProps("dateOfBirth").issues
      )}
      width={"100%"}
    />
  );

  const renderTitleBeforeFieldOrReadOnly = props.readOnly ? (
    <ReadOnlyField
      size="medium"
      label={formatMessage("Identification.personalData.title")}
      value={pipe(props.values.title, option.getOrElse(constant("")))}
      feedback={formatFeedback("title")}
    />
  ) : (
    <TitleDropdown
      {...props.fieldProps("title")}
      type="Before"
      label={formatMessage("Identification.personalData.title")}
      placeholder={formatMessage("Identification.personalData.title")}
      clearable
      issues={formatIssues("title", props.fieldProps("title").issues)}
    />
  );

  const renderTitleAfterFieldOrReadOnly = props.readOnly ? (
    <ReadOnlyField
      size="medium"
      label={formatMessage("Identification.personalData.titleAfter")}
      value={pipe(props.values.titleAfter, option.getOrElse(constant("")))}
      feedback={formatFeedback("titleAfter")}
    />
  ) : (
    <TitleDropdown
      {...props.fieldProps("titleAfter")}
      type="After"
      label={formatMessage("Identification.personalData.titleAfter")}
      placeholder={formatMessage("Identification.personalData.titleAfter")}
      clearable
      issues={formatIssues("titleAfter", props.fieldProps("titleAfter").issues)}
    />
  );

  const nameField = renderTextFieldOrReadOnly({
    name: "name",
    label: formatMessage("Identification.personalData.name"),
    placeholder: formatMessage("Identification.personalData.name"),
  });

  const surnameField = renderTextFieldOrReadOnly({
    name: "surname",
    label: formatMessage("Identification.personalData.surname"),
    placeholder: formatMessage("Identification.personalData.surname"),
  });

  const birthNumberField = props.readOnly ? (
    <ReadOnlyField
      size="medium"
      label={formatMessage("Identification.personalData.birthNumber")}
      value={pipe(
        props.values["birthNumber"],
        option.getOrElse(() => "")
      )}
      feedback={formatFeedback("birthNumber")}
    />
  ) : (
    <TextField
      {...props.fieldProps("birthNumber")}
      onChange={s =>
        props
          .fieldProps("birthNumber")
          .onChange(
            s.length > 0 ? option.some(s as NonEmptyString) : option.none
          )
      }
      value={pipe(
        props.fieldProps("birthNumber").value,
        option.getOrElse(() => "")
      )}
      label={formatMessage("Identification.personalData.birthNumber")}
      placeholder={formatMessage("Identification.personalData.birthNumber")}
      issues={formatIssues(
        "birthNumber",
        props.fieldProps("birthNumber").issues
      )}
    />
  );

  const placeOfBirthField = renderTextFieldOrReadOnly({
    name: "placeOfBirth",
    label: formatMessage("Identification.personalData.birthPlace"),
    placeholder: formatMessage("Identification.personalData.birthPlace"),
  });

  const renderLocalPersonalNumberField =
    props.readOnly &&
    props.values &&
    props.values.localPersonalNumber &&
    option.isSome(props.values.localPersonalNumber) ? (
      <ReadOnlyField
        size="medium"
        label={formatMessage("Identification.personalData.localPersonalNumber")}
        value={pipe(
          props.values.localPersonalNumber,
          option.getOrElse(constant(""))
        )}
      />
    ) : null;

  return (
    <Form data-test-id="personal_data">
      <FormSection
        heading={props.heading}
        errors={pipe(props.errors, option.map(nonEmptyArray.map(e => e.value)))}
      >
        <FormRow type="1-1">
          {renderTitleBeforeFieldOrReadOnly}
          {renderTitleAfterFieldOrReadOnly}
        </FormRow>
        <FormRow type="1-1">
          {nameField}
          {surnameField}
        </FormRow>
        <FormRow type="full">{renderSexFieldOrReadOnly}</FormRow>
        {isSupportedForeign ? (
          props.documentType === "IDCard" && (
            <FormRow type="full">{renderMaritalStatusFieldOrReadOnly}</FormRow>
          )
        ) : (
          <FormRow type="full">{renderMaritalStatusFieldOrReadOnly}</FormRow>
        )}
        <FormRow type="1-1">
          {renderDateOfBirthFieldOrReadonly}
          {isSupportedForeign ? null : birthNumberField}
        </FormRow>
        <FormRow type="1-1">
          {placeOfBirthField}
          {props.displayCountryOfBirth
            ? renderCountryOfBirthFieldOrReadOnly
            : renderCitizenshipFieldOrReadOnly}
        </FormRow>
        {props.displayCountryOfBirth && (
          <FormRow type="1-1">
            {renderCitizenshipFieldOrReadOnly}
            {renderLocalPersonalNumberField}
          </FormRow>
        )}
        {!!renderSecondCitizenship && (
          <FormRow type="full">{renderSecondCitizenship}</FormRow>
        )}
        {!props.displayCountryOfBirth && (
          <FormRow type="full">{renderLocalPersonalNumberField}</FormRow>
        )}
      </FormSection>
    </Form>
  );
}
