import { useEffect, useMemo, useState } from "react";
import {
  fieldIssues,
  Issue,
  LocalizedString,
  unsafeLocalizedString,
  useForm,
  Validator,
  validators,
} from "design-system";
import {
  constant,
  constFalse,
  constTrue,
  constUndefined,
  flow,
  pipe,
} from "fp-ts/function";
import { useCommand } from "../../useAPI";
import * as identificationApi from "../api";
import { AddressWrite, AllSupportedCitizenships } from "../domain";
import { boolean, either, option, taskEither } from "fp-ts";
import { useFormatMessage } from "../../intl";
import { genericError, GenericError } from "../../globalDomain";
import { NonEmptyArray } from "fp-ts/NonEmptyArray";
import { NonEmptyString } from "io-ts-types/lib/NonEmptyString";
import {
  AddressFormState,
  autocompleteInputStatusFromRemoteData,
  getCitySuggestionLabel,
  useDefaultCountryCode,
} from "../addressFormUtils";
import { Option } from "fp-ts/Option";
import { AddressForm } from "./AddressForm";
import { useRemoteData } from "../../useRemoteData";
import * as remoteData from "../../RemoteData";
import { useValidators } from "../../Common/useValidators";
import { useDebounceState } from "../../Common/useDebounceState";
import { TaskEither } from "fp-ts/TaskEither";
import { AddressSuggestionForm } from "./AddressSuggestionForm";
import { isForeign_ } from "../utils";

const searchTermRegExp = /(.+),\s+(.+)/;
const streetNumberRegExp = /^[ÁáÉéÍíÓóÚúÝýĹĺŔŕÄäĚěÔôŐőŮůÜüČčĎďĽľŇňŘřŠšŤťŽža-zA-Z0-9,.\"';:\-_()\/€’„“”–—\s\t]{1,255}$/;

function splitSearchTerm(
  searchTerm: string
): Option<{ city: NonEmptyString; provinceName: NonEmptyString }> {
  if (!searchTermRegExp.test(searchTerm)) return option.none;

  const blocks = searchTerm.match(searchTermRegExp);

  return pipe(
    option.fromNullable(blocks),
    option.map(blocks => ({
      city: blocks[1] as NonEmptyString,
      provinceName: blocks[2] as NonEmptyString,
    }))
  );
}

function validateStreetNumberRegex(streetNumber: string) {
  if (!streetNumberRegExp.test(streetNumber)) return option.none;
  return option.some(streetNumber);
}

type Props = {
  initialValues: Option<AddressFormState>;
  onValidate: Validator<AddressWrite>;
  onSuggestionSubmit: (address: AddressWrite) => TaskEither<unknown, void>;
  onSubmit: (address: AddressWrite) => TaskEither<unknown, void>;
  onCancel?: () => unknown;
  submitInvalid?: boolean;
  hint: Option<LocalizedString>;
  lockCountryEdit?: boolean;
  optionalFields?: boolean;
  forceValidateForForeign: boolean;
  foreignSupport?: boolean;
  hasResidency?: () => boolean;
  lockCzSk: boolean;
};

export function AddressFormEdit(props: Props) {
  const isInitialForeign = pipe(
    props.initialValues,
    option.fold(constFalse, values =>
      isForeign_(values.country as AllSupportedCitizenships)
    )
  );

  const formatMessage = useFormatMessage();
  const [skipValidation, setSkipValidation] = useState(
    isInitialForeign && !props.forceValidateForForeign
  );
  const validateConfirmation =
    props.hasResidency != undefined ? !props.hasResidency() : false;

  const [manualSkipValidation] = useState(false);
  const [suggestions, setSuggestions] = useState<
    Option<NonEmptyArray<AddressWrite>>
  >(option.none);
  const addressValidate = useCommand(identificationApi.addressValidation);
  const ignoreAddressValidation = useCommand(
    identificationApi.ignoreAddressValidation
  );

  const formatError = (
    error: identificationApi.AddressValidationError | GenericError
  ): NonEmptyArray<Issue> => {
    switch (error.id) {
      case "GenericError":
      case "InvalidFlowId":
        return fieldIssues.errors([
          formatMessage("Identification.validationAddress.genericError"),
        ]);
      case "InvalidAddress":
        return fieldIssues.errors([
          props.submitInvalid
            ? formatMessage(
                "Identification.validationAddress.probablyInvalidAddress"
              )
            : formatMessage("Identification.validationAddress.invalidAddress"),
        ]);
    }
  };

  const addressValidator: Validator<AddressWrite> = (address: AddressWrite) =>
    pipe(
      addressValidate({ address }),
      taskEither.bimap(
        error => {
          if (error.id) {
            if (props.submitInvalid && error.id === "InvalidAddress") {
              setSkipValidation(true);
            }
            return formatError({
              id: error.id,
            });
          }
          if (error.addresses) {
            setSuggestions(option.some(error.addresses));
            return formatError({
              id: "InvalidAddress",
            });
          }
          return formatError(genericError);
        },
        ({ zipCode }) => ({
          ...address,
          zipCode: option.isSome(zipCode)
            ? zipCode
            : pipe(
                address.zipCode,
                either.fromOption(constUndefined),
                either.map(zipCode => zipCode.trim()),
                either.chainW(NonEmptyString.decode),
                // either.orElse(() => NonEmptyString.decode(zipCode)),
                option.fromEither
              ),
        })
      )
    );

  const defaultCountryCode = useDefaultCountryCode();

  const initialValues: AddressFormState = useMemo(
    () =>
      pipe(
        props.initialValues,
        option.fold(
          constant({
            streetName: "",
            city: "",
            provinceName: "",
            country: defaultCountryCode,
            streetNumber: "",
            zipCode: "",
            confirmed: false as boolean,
          }),
          initialValues => {
            return {
              ...initialValues,
              city: getCitySuggestionLabel({
                cityName: unsafeLocalizedString(initialValues.city),
                provinceName: unsafeLocalizedString(initialValues.provinceName),
              }),
              confirmed: initialValues.confirmed,
            };
          }
        )
      ),
    [props.initialValues]
  );

  const { nonBlankString, latinCharactersOnly, digitsOnly } = useValidators();

  const checkOptionalityAndValidate = (validator: Validator<any>) => {
    if (props.optionalFields) {
      return validators.validateIfNonEmpty(validator);
    } else return validators.inSequence(nonBlankString, validator);
  };
  const workAroundAlwaysRightValidator = <T extends any>() =>
    validators.fromPredicate<T>(constTrue, formatMessage("GenericError"));

  const {
    values: formState,
    setValues: setFormState,
    fieldProps,
    handleSubmit,
    formErrors,
  } = useForm(
    {
      initialValues,
      fieldValidators: ({ zipCode, city }) => ({
        streetName: skipValidation
          ? undefined
          : checkOptionalityAndValidate(latinCharactersOnly),
        streetNumber: skipValidation
          ? undefined
          : checkOptionalityAndValidate(
              validators.inSequence(
                nonBlankString,
                flow(
                  validateStreetNumberRegex,
                  validators.defined(
                    formatMessage("Form.fieldError.invalidStreetNumber")
                  )
                )
              )
            ),
        city: skipValidation
          ? flow(
              city => splitSearchTerm(city ? city : ""),
              option.map(splits =>
                splits.provinceName
                  ? splits
                  : {
                      city: splits.city,
                      provinceName: "",
                    }
              ),
              option.getOrElse(() => ({
                city: city,
                provinceName: "",
              })),
              workAroundAlwaysRightValidator<{
                city: string;
                provinceName: string;
              }>()
            )
          : checkOptionalityAndValidate(
              validators.inSequence(
                latinCharactersOnly,
                flow(
                  splitSearchTerm,
                  validators.defined(
                    formatMessage(
                      "Identification.validationAddress.invalidCityFormatError"
                    )
                  )
                )
              )
            ),
        zipCode: skipValidation
          ? undefined
          : zipCode
          ? validators.inSequence(
              validators.nonBlankString(
                formatMessage("Form.fieldError.digitsOnly")
              ),
              digitsOnly
            )
          : undefined,
        confirmed: validateConfirmation
          ? validators.checked(
              formatMessage(
                "Identification.permamentAddress.confirmation.needed"
              )
            )
          : undefined,
      }),
    },
    {
      onSubmit: addressFields => {
        const address: AddressWrite = {
          ...addressFields,
          zipCode: pipe(
            addressFields.zipCode,
            NonEmptyString.decode,
            option.fromEither
          ),
          streetName: pipe(
            addressFields.streetName,
            NonEmptyString.decode,
            option.fromEither
          ),
          streetNumber: pipe(
            addressFields.streetNumber,
            NonEmptyString.decode,
            option.fromEither
          ),
          city: pipe(
            addressFields.city.city,
            NonEmptyString.decode,
            option.fromEither
          ),
          provinceName: pipe(
            addressFields.city.provinceName,
            NonEmptyString.decode,
            option.fromEither
          ),
        };
        return pipe(
          (!props.submitInvalid || !skipValidation) &&
            !manualSkipValidation &&
            !skipValidation,
          boolean.fold(
            () => taskEither.right(address),
            () => addressValidator(address)
          ),
          taskEither.mapLeft(err => {
            return err;
          }),
          taskEither.chain(props.onValidate),
          taskEither.chainFirst(
            flow(
              props.onSubmit,
              taskEither.mapLeft(constant(formatError(genericError)))
            )
          ),
          taskEither.chain(validatedAddress =>
            taskEither.fromIO((): unknown =>
              fieldProps("zipCode").onChange(
                pipe(validatedAddress.zipCode, option.getOrElse(constant("")))
              )
            )
          )
        );
      },
    }
  );

  const noSuggestions = taskEither.right(option.none);

  const fetchstreetSuggestions = useCommand(
    identificationApi.streetSuggestions,
    {
      skipTracking: true,
    }
  );

  const fetchCitySuggestions = useCommand(identificationApi.citySuggestions, {
    skipTracking: true,
  });
  const cityAndProvince = splitSearchTerm(formState.city);

  const streetQueryTerms = useDebounceState(formState.streetName, 500);
  const streetSuggestions = useRemoteData(
    useMemo(
      () =>
        !skipValidation && streetQueryTerms.length >= 2
          ? pipe(
              fetchstreetSuggestions(
                pipe(
                  cityAndProvince,
                  option.fold(
                    () => ({
                      streetName: streetQueryTerms,
                      zipCode: formState.zipCode,
                      cityName: formState.city,
                      provinceName: "",
                      country: formState.country,
                    }),
                    cityAndProvince => ({
                      streetName: streetQueryTerms,
                      zipCode: formState.zipCode,
                      cityName: cityAndProvince.city,
                      provinceName: cityAndProvince.provinceName,
                      country: formState.country,
                    })
                  )
                )
              ),
              taskEither.map(option.some)
            )
          : noSuggestions,
      [streetQueryTerms]
    )
  );

  const cityQueryTerms = useDebounceState(formState.city, 500);
  const citySuggestions = useRemoteData(
    useMemo(
      () =>
        !skipValidation && cityQueryTerms.length >= 2
          ? pipe(
              fetchCitySuggestions({
                searchTerm: cityQueryTerms.replace(/^.*,\s*/, ""),
                country: formState.country,
              }),
              taskEither.map(option.some)
            )
          : noSuggestions,
      [cityQueryTerms]
    )
  );

  useEffect(() => {
    const skipValidate =
      isForeign_(formState.country as AllSupportedCitizenships) &&
      !props.forceValidateForForeign;
    if (skipValidation !== skipValidate) {
      setSkipValidation(skipValidate);
    }
  }, [formState]);

  useEffect(() => {
    if (manualSkipValidation) {
      pipe(
        ignoreAddressValidation(),
        taskEither.map(() => {
          handleSubmit();
        })
      )();
    }
  }, [manualSkipValidation]);

  const readonlyFalse = false;
  return (
    <>
      {pipe(
        suggestions,
        option.fold(
          () => (
            <AddressForm
              lockCzSk={props.lockCzSk}
              displayConfirmation={
                props.hasResidency != undefined
                  ? !props.hasResidency()
                  : undefined
              }
              readOnly={readonlyFalse}
              supportForeign={props.foreignSupport}
              fieldProps={fieldProps}
              streetSuggestions={{
                status: autocompleteInputStatusFromRemoteData(
                  pipe(
                    streetSuggestions,
                    remoteData.map(
                      option.getOrElse(
                        constant<identificationApi.IDStreetSuggestionsOutput>(
                          []
                        )
                      )
                    )
                  ),
                  suggestion => ({
                    value: suggestion,
                    label: unsafeLocalizedString(suggestion.streetName),
                  })
                ),
                onSelect: ({ value }) =>
                  setFormState({
                    streetName: value.streetName,
                  }),
              }}
              citiesSuggestions={{
                status: autocompleteInputStatusFromRemoteData(
                  pipe(
                    citySuggestions,
                    remoteData.map(
                      option.getOrElse(
                        constant<identificationApi.IDCitySuggestionsOutput>([])
                      )
                    )
                  ),
                  suggestion => ({
                    value: suggestion,
                    label: getCitySuggestionLabel(suggestion),
                  })
                ),
                onSelect: suggestion => {
                  setFormState({
                    city: getCitySuggestionLabel(suggestion.value),
                    zipCode: "",
                  });
                },
              }}
              hint={props.hint}
              errors={formErrors}
              cancelButton={
                props.onCancel && {
                  action: props.onCancel,
                  label: formatMessage("Identification.address.cancel"),
                }
              }
              submitButton={{
                action: handleSubmit,
                label: skipValidation
                  ? formatMessage("Identification.address.saveInvalid")
                  : formatMessage("Identification.address.save"),
              }}
              lockCountryEdit={props.lockCountryEdit}
            />
          ),
          suggestions => (
            <AddressSuggestionForm
              onSubmit={props.onSuggestionSubmit}
              onCancel={props.onCancel}
              addressSuggestions={suggestions}
              errorMessage={formatMessage(
                "Identification.validationAddress.invalidAddressSelectSuggestion"
              )}
            />
          )
        )
      )}
    </>
  );
}
