import {
  Form,
  FormRow,
  FormSection,
  ComputedFieldProps,
  LocalizedString,
} from "design-system";

import { AddressWrite } from "../domain";
import { NonEmptyArray } from "fp-ts/NonEmptyArray";
import { AddressSuggestionsDropdown } from "../AddressForm/AddressSuggestionsDropdown";
import { option } from "fp-ts";

import { Option } from "fp-ts/Option";

type Props = {
  suggestionProps: ComputedFieldProps<Option<AddressWrite>>;
  addressSuggestions: NonEmptyArray<AddressWrite>;
  errorMessage: LocalizedString;
};

export function AddressSuggestionsForm(props: Props) {
  return (
    <Form data-test-id="address">
      <FormSection errors={option.some([props.errorMessage])}>
        <FormRow type="full">
          <AddressSuggestionsDropdown
            {...props.suggestionProps}
            addresses={props.addressSuggestions}
          />
        </FormRow>
      </FormSection>
    </Form>
  );
}
