import {
  Box,
  DateField,
  Form,
  FormErrors,
  FormRow,
  FormSection,
  LocalizedString,
  ReadOnlyField,
  Space,
  TextField,
  useForm,
  ValidableDate,
  validableDate,
  validators,
} from "design-system";

import {
  constant,
  constFalse,
  constTrue,
  constVoid,
  pipe,
} from "fp-ts/function";
import { useFormatMessage } from "../../intl";
import { array, nonEmptyArray, option, task, taskEither } from "fp-ts";
import { Task } from "fp-ts/Task";
import { TaskEither } from "fp-ts/TaskEither";
import { PersonalDocumentProofOfAddress } from "../domain";
import { CoApplicantInput } from "../../globalDomain";
import { Option } from "fp-ts/Option";
import { DocumentUploadField } from "../../UploadDocuments/DocumentUploadField";
import { NonEmptyString } from "io-ts-types/lib/NonEmptyString";

import * as uploadApi from "../api";
import { useCommand } from "../../useAPI";
import { DocumentType, ScannedDocument } from "../../UploadDocuments/domain";
import { useValidators } from "../../Common/useValidators";
import { useEffect, useState } from "react";
import { base64FileSize } from "./base64FileSize";
import { ShareWithClientButton } from "../../Common/ShareWithClientButton/ShareWithClientButton";
import { RestoreApplicationData } from "../../UKontoSecondPart/api";

type Error = "SizeExceeding" | "FailureUpload";

type Props = {
  documentType: DocumentType;
  onSave: (
    proofOfAddress: PersonalDocumentProofOfAddress
  ) => TaskEither<unknown, unknown>;
  error: Option<LocalizedString>;
  isEditingForm: boolean;
  disabled: boolean;
  sharedWithClient: boolean;
  onFormChange?: () => unknown;
  showLocalPersonalNumber: boolean;
  isForeign?: boolean;
  restoredData?: RestoreApplicationData;
  isUploadAgain?: boolean;
} & CoApplicantInput;

function _foldDocumentType(documentType: DocumentType) {
  return function <T>(match: {
    whenIDCard: () => T;
    whenPassport: () => T;
    whenDrivingLicense: () => T;
    whenLongTermResidencePermit: () => T;
    whenPermanentResidencePermit: () => T;
    whenTemporaryResidencePermit: () => T;
  }) {
    switch (documentType) {
      case "IDCard":
        return match.whenIDCard();
      case "Passport":
        return match.whenPassport();
      case "DrivingLicense":
        return match.whenDrivingLicense();
      case "LongTermResidencePermit":
        return match.whenLongTermResidencePermit();
      case "PermanentResidencePermit":
        return match.whenPermanentResidencePermit();
      case "TemporaryResidencePermit":
        return match.whenTemporaryResidencePermit();
    }
  };
}

export function ProofOfAddressForm(props: Props) {
  const formatMessage = useFormatMessage();
  const foldDocumentType = _foldDocumentType(
    props.isForeign === true ? "Passport" : props.documentType
  ); //the flow is same as for passport in this case

  const [error, setError] = useState<Option<Error>>(option.none);
  const uploadDocument = useCommand(uploadApi.proofOfAddressDocumentSubmit);
  const removeDocument = useCommand(uploadApi.proofOfAddressDocumentRemove);

  const documentHasTwoSides = foldDocumentType({
    whenIDCard: constFalse,
    whenPassport: constTrue,
    whenDrivingLicense: constFalse,
    whenLongTermResidencePermit: constFalse,
    whenPermanentResidencePermit: constFalse,
    whenTemporaryResidencePermit: constFalse,
  });

  const { defined, validDate, maxLength } = useValidators();

  const minimumDate = new Date("01-01-1900");
  const maxMB = 20000000;
  const showLocalPersonalNumber = props.showLocalPersonalNumber;
  /* localBirthNumberEnabled will be off for a while,
  see https://jira.internal.unicredit.eu/browse/SBL-122824?focusedCommentId=781742&page=com.atlassian.jira.plugin.system.issuetabpanels:comment-tabpanel#comment-781742
  Vodrazkova Lucie (UniCredit CZ&SK) added a comment
  Hi Tica Alexandru Mihai (UniCredit Services - AT),

  Apologies. I´ve just discussed this field with my colleague and It seems that It´s more complex and the decision some
  time ago was to hide this field on both Banker and client side and not to validate till some other parts of the solution will be resolved.
  May I kindly ask you to reverse this fix and hide the field on both banker and client side?
*/

  const { handleSubmit, fieldProps, values } = useForm(
    {
      initialValues:
        props.restoredData && !props.isUploadAgain
          ? option.isSome(props.restoredData.proofOfAddress)
            ? {
                idNumber: option.isSome(
                  props.restoredData.proofOfAddress.value.idNumber
                )
                  ? props.restoredData.proofOfAddress.value.idNumber.value
                  : "",
                dateOfIssuing: option.some(
                  validableDate.fromDate(
                    props.restoredData.proofOfAddress.value.dateOfIssuing
                  )
                ),
                validUntil: (option.isSome(
                  props.restoredData.proofOfAddress.value.validUntil
                )
                  ? option.some(
                      validableDate.fromDate(
                        props.restoredData.proofOfAddress.value.validUntil.value
                      )
                    )
                  : option.none) as Option<ValidableDate>,
                issuer: option.isSome(
                  props.restoredData.proofOfAddress.value.issuer
                )
                  ? option.some(
                      props.restoredData.proofOfAddress.value.issuer.value
                    )
                  : (option.none as Option<string>),
                firstDocumentSide: (option.isSome(
                  props.restoredData.proofOfAddressDocs
                ) &&
                option.isSome(props.restoredData.proofOfAddressDocs.value.first)
                  ? option.some(
                      props.restoredData.proofOfAddressDocs.value.first.value
                    )
                  : option.none) as Option<ScannedDocument>,
                secondDocumentSide: (option.isSome(
                  props.restoredData.proofOfAddressDocs
                ) &&
                option.isSome(
                  props.restoredData.proofOfAddressDocs.value.second
                )
                  ? option.some(
                      props.restoredData.proofOfAddressDocs.value.second.value
                    )
                  : option.none) as Option<ScannedDocument>,
                localPersonalNumber: option.isSome(
                  props.restoredData.proofOfAddress.value.localPersonalNumber
                )
                  ? props.restoredData.proofOfAddress.value.localPersonalNumber
                      .value
                  : "",
              }
            : {
                idNumber: "",
                dateOfIssuing: option.none as Option<ValidableDate>,
                validUntil: option.none as Option<ValidableDate>,
                issuer: option.none as Option<string>,
                firstDocumentSide: (option.isSome(
                  props.restoredData.proofOfAddressDocs
                ) &&
                option.isSome(props.restoredData.proofOfAddressDocs.value.first)
                  ? option.some(
                      props.restoredData.proofOfAddressDocs.value.first.value
                    )
                  : option.none) as Option<ScannedDocument>,
                secondDocumentSide: (option.isSome(
                  props.restoredData.proofOfAddressDocs
                ) &&
                option.isSome(
                  props.restoredData.proofOfAddressDocs.value.second
                )
                  ? option.some(
                      props.restoredData.proofOfAddressDocs.value.second.value
                    )
                  : option.none) as Option<ScannedDocument>,
                localPersonalNumber: "",
              }
          : {
              idNumber: "",
              dateOfIssuing: option.none as Option<ValidableDate>,
              validUntil: option.none as Option<ValidableDate>,
              issuer: option.none as Option<string>,
              firstDocumentSide: option.none as Option<ScannedDocument>,
              secondDocumentSide: option.none as Option<ScannedDocument>,
              localPersonalNumber: "",
            },
      fieldValidators: ({ validUntil }) => ({
        idNumber: maxLength(99),
        dateOfIssuing: validators.inSequence(
          defined<Option<Date>>(),
          validDate,
          validators.fromPredicate<Date>(
            p => p > minimumDate,
            formatMessage("Form.fieldError.issuingBeforeMinimumDate")
          ),
          validators.fromPredicate<Date>(
            p => p <= new Date(),
            formatMessage("Form.fieldError.issuingAfterToday")
          ),
          validators.fromPredicate<Date>(
            p =>
              option.isSome(validUntil) && option.isSome(validUntil.value)
                ? validUntil.value.value > p
                : true,
            formatMessage("Form.fieldError.validUntilBeforeIssuing")
          )
        ),
        validUntil: validators.validateIfDefined(
          validators.inSequence(
            validators.validDate(
              formatMessage("Form.fieldError.invalidDateFormat")
            ),
            validators.fromPredicate(
              p => p > new Date(),
              formatMessage("Form.fieldError.validDateExpired")
            )
          )
        ),
        firstDocumentSide: validators.defined<ScannedDocument>(
          formatMessage("Form.fieldError.required")
        ),
        secondDocumentSide: documentHasTwoSides
          ? validators.definedNoExtract<ScannedDocument>(
              formatMessage("Form.fieldError.required")
            )
          : undefined,
        localPersonalNumber: showLocalPersonalNumber
          ? validators.inSequence(
              validators.nonBlankString(
                formatMessage("Form.fieldError.required")
              ),
              validators.validBirthNumber(
                formatMessage(
                  "Identification.personalData.birthNumberFormatError"
                )
              )
            )
          : undefined,
      }),
    },
    {
      onSubmit: ({ firstDocumentSide, secondDocumentSide, ...info }) =>
        props.onSave({
          ...info,
          idNumber: pipe(
            info.idNumber,
            NonEmptyString.decode,
            option.fromEither
          ),
          localPersonalNumber: showLocalPersonalNumber
            ? pipe(
                info.localPersonalNumber,
                NonEmptyString.decode,
                option.fromEither
              )
            : option.none,
        }),
    }
  );

  useEffect(() => {
    if (props.onFormChange) {
      props.onFormChange();
    }
  }, [values]);

  const formatError = (error: Error): LocalizedString => {
    switch (error) {
      case "FailureUpload":
        return formatMessage("Identification.UploadDocuments.failure.message");
      case "SizeExceeding":
        return formatMessage(
          "Identification.UploadDocuments.failure.sizeExceeding"
        );
    }
  };
  const documentType: LocalizedString = foldDocumentType({
    whenIDCard: () =>
      formatMessage("Identification.personalData.confirmationOfAddress"),
    whenDrivingLicense: () =>
      formatMessage("Identification.personalData.confirmationOfAddress"),
    whenPassport: () =>
      formatMessage("Identification.personalData.residentPermit"),
    whenLongTermResidencePermit: () =>
      formatMessage("Identification.personalData.residentPermit"),
    whenPermanentResidencePermit: () =>
      formatMessage("Identification.personalData.residentPermit"),
    whenTemporaryResidencePermit: () =>
      formatMessage("Identification.personalData.residentPermit"),
  });

  const firstDocumentLabel: LocalizedString = foldDocumentType({
    whenIDCard: () => formatMessage("Identification.proofOfAddress.document"),
    whenDrivingLicense: () =>
      formatMessage("Identification.proofOfAddress.document"),
    whenPassport: () =>
      formatMessage("Identification.proofOfAddress.residentPermitFront"),
    whenLongTermResidencePermit: () =>
      formatMessage("Identification.proofOfAddress.residentPermitFront"),
    whenPermanentResidencePermit: () =>
      formatMessage("Identification.proofOfAddress.residentPermitFront"),
    whenTemporaryResidencePermit: () =>
      formatMessage("Identification.proofOfAddress.residentPermitFront"),
  });

  const secondDocumentLabel: LocalizedString = formatMessage(
    "Identification.proofOfAddress.residentPermitBack"
  );

  const onDocumentUpload = (
    side: "First" | "Second",
    fileContent: ScannedDocument
  ): Task<unknown> => {
    const onChangeDocument = fieldProps(
      side === "First" ? "firstDocumentSide" : "secondDocumentSide"
    ).onChange;

    return pipe(
      fileContent,
      taskEither.fromPredicate(
        scannedDocument => base64FileSize(scannedDocument.base64) < maxMB,
        constant<Error>("SizeExceeding")
      ),
      taskEither.chain(file =>
        pipe(
          uploadDocument({
            side,
            fileContent: file.base64,
            coApplicant: props.coApplicant,
          }),
          taskEither.mapLeft(constant<Error>("FailureUpload"))
        )
      ),
      taskEither.fold(
        m => task.fromIO(() => setError(option.some(m))),
        () =>
          task.fromIO(() => {
            setError(option.none);
            onChangeDocument(option.some(fileContent));
          })
      )
    );
  };

  const onDocumentRemove = (side: "First" | "Second"): Task<unknown> => {
    const onChangeDocument = fieldProps(
      side === "First" ? "firstDocumentSide" : "secondDocumentSide"
    ).onChange;

    return pipe(
      removeDocument({ side, coApplicant: props.coApplicant }),
      taskEither.fold(
        () => task.fromIO(constVoid),
        () =>
          task.fromIO(() => {
            setError(option.none);
            onChangeDocument(option.none);
          })
      )
    );
  };

  const onDocumentChange = (side: "First" | "Second") => (
    fileContent: Option<ScannedDocument>
  ): unknown => {
    return pipe(
      fileContent,
      option.fold(
        () => onDocumentRemove(side),
        file => onDocumentUpload(side, file)
      )
    )();
  };

  return (
    <Form>
      <FormSection>
        <FormRow type="full">
          <ReadOnlyField
            size="medium"
            label={formatMessage("Identification.personalData.documentType")}
            value={documentType}
          />
        </FormRow>
        <FormRow type="1-1">
          <TextField
            {...fieldProps("idNumber")}
            label={formatMessage("Identification.personalData.idNumber")}
            placeholder={formatMessage("Identification.personalData.idNumber")}
            disabled={props.disabled || fieldProps("idNumber").disabled}
          />
          <>
            {showLocalPersonalNumber && (
              <TextField
                {...fieldProps("localPersonalNumber")}
                label={formatMessage(
                  "Identification.personalData.localPersonalNumber"
                )}
                placeholder={formatMessage(
                  "Identification.personalData.localPersonalNumber"
                )}
                disabled={
                  props.disabled || fieldProps("localPersonalNumber").disabled
                }
              />
            )}
          </>
        </FormRow>
        <FormRow type="1-1">
          <DateField
            {...fieldProps("dateOfIssuing")}
            label={formatMessage("Identification.personalData.dateOfIssuing")}
            placeholder={formatMessage(
              "Identification.personalData.dateOfIssuing"
            )}
            displayFormat="YYYY-MM-DD"
            disabled={props.disabled || fieldProps("dateOfIssuing").disabled}
            width={"100%"}
          />
          <DateField
            {...fieldProps("validUntil")}
            label={formatMessage("Identification.personalData.validUntil")}
            placeholder={formatMessage(
              "Identification.personalData.validUntil"
            )}
            displayFormat="YYYY-MM-DD"
            disabled={props.disabled || fieldProps("validUntil").disabled}
            width={"100%"}
          />
        </FormRow>
        <FormRow type="full">
          <DocumentUploadField
            {...fieldProps("firstDocumentSide")}
            onChange={onDocumentChange("First")}
            label={firstDocumentLabel}
            disabled={
              props.disabled || fieldProps("firstDocumentSide").disabled
            }
            disableMobileUpload
            coApplicant={props.coApplicant}
          />
        </FormRow>
        {documentHasTwoSides && (
          <FormRow type="full">
            <DocumentUploadField
              {...fieldProps("secondDocumentSide")}
              onChange={onDocumentChange("Second")}
              label={secondDocumentLabel}
              disabled={
                props.disabled || fieldProps("secondDocumentSide").disabled
              }
              disableMobileUpload
              coApplicant={props.coApplicant}
            />
          </FormRow>
        )}
        <Space units={5} />
        <FormErrors
          errors={pipe(
            error,
            option.map(formatError),
            e => array.compact([e, props.error]),
            nonEmptyArray.fromArray
          )}
        />
        <Box hAlignContent="right">
          <ShareWithClientButton
            branchExperienceState={
              props.sharedWithClient ? "sharedWithClient" : "notShared"
            }
            submitLabel={formatMessage("Continue")}
            action={handleSubmit}
            disabled={props.isEditingForm || props.disabled}
          />
        </Box>
      </FormSection>
    </Form>
  );
}
