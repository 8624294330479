import { DropdownOption, unsafeLocalizedString } from "design-system";
import { pipe } from "fp-ts/function";
import { array, option } from "fp-ts";
import { Option } from "fp-ts/Option";
import titlesAfterBefore from "./titleAfterBefore.json";
import { useAppContext } from "../../../useAppContext";

import { NonEmptyString } from "io-ts-types/lib/NonEmptyString";
import {
  DropdownField,
  DropdownFieldProps,
} from "../../../Common/DropdownField/DropdownField";

type Props = Omit<
  DropdownFieldProps<NonEmptyString>,
  "options" | "value" | "onChange" | "searchable"
> & {
  type: "After" | "Before";
  value: Option<NonEmptyString>;
  onChange: (titleAfter: Option<NonEmptyString>) => unknown;
};

export function TitleDropdown(props: Props) {
  const {
    apiParameters: { tenant },
  } = useAppContext();

  const titlesList = titlesAfterBefore[props.type][tenant];
  const options = titlesList.map(title => ({
    value: title as NonEmptyString,
    label: unsafeLocalizedString(title),
  }));

  const value = pipe(
    props.value,
    option.chain(value =>
      pipe(
        options,
        array.findFirst(o => o.value === value)
      )
    )
  );

  const onChange = (value: Option<DropdownOption<NonEmptyString>>) => {
    props.onChange(
      pipe(
        value,
        option.map(v => v.value)
      )
    );
  };

  return (
    <DropdownField
      {...props}
      options={options}
      value={value}
      onChange={onChange}
      searchable
    />
  );
}
