import {
  Body,
  Box,
  ContentRow,
  FormErrors,
  Space,
  Stack,
  useIsMobileLayout,
  LocalizedString,
} from "design-system";
import { useFormatMessage } from "../../intl";
import * as idClasses from "../../UploadDocuments/UploadId.treat";
import OKExampleImage from "./ok.png";
import PartialExampleImage from "./partial.png";
import DarkExampleImage from "./dark.png";
import SunglassesExampleImage from "./sunglasses.png";
import { UploadImageButton } from "../../UploadDocuments/UploadImageButton";
import { boolean, option, taskEither } from "fp-ts";
import { lazy, Suspense, useState } from "react";
import { SelfieCheckStatus } from "./types";
import { MobileRecipientOptionType } from "../../UploadDocuments/state";
import { FraudCheckDialog } from "../../UploadDocuments/FraudCheckDialog";
import { constNull, pipe } from "fp-ts/function";
import { NextButton } from "../../Common/NextButton";
import {
  CoApplicantInput,
  DocumentIdentificationFlow,
  GenericError,
  genericError,
  UploadDocumentFlowType,
} from "../../globalDomain";
import { Option } from "fp-ts/Option";
import { RemoteMobileDeviceDialog } from "../../UploadDocuments/RemoteMobileDeviceDialog";
import { SelfieCheckOverview } from "./SelfieCheckOverview";
import * as t from "io-ts";

const MobileCheckFlow = lazy(
  () => import("../../UploadDocuments/MobileIdUpload/MobileCheckFlow")
);

const MissingSelfieError = t.type({
  id: t.literal("Identification.selfiecheck.missingSelfieError"),
});
type MissingSelfieError = t.TypeOf<typeof MissingSelfieError>;
const missingSelfieError: MissingSelfieError = {
  id: "Identification.selfiecheck.missingSelfieError",
};

const ExampleImage = (props: {
  description: LocalizedString;
  image: string;
  isOk?: boolean;
}) => (
  <Box column>
    <Box className={idClasses.imageWrapper} hAlignContent="center">
      <img src={props.image} alt={props.description} height={150} />
    </Box>
    <Space units={2} />
    <Body size="xx-small" weight="regular">
      {props.description}
    </Body>
  </Box>
);

type Props = {
  resetProcess: () => unknown;
  onFailure: (reason: "GenericError" | "MaxAttemptsReached") => unknown;
  onContinue: () => unknown;
  canUploadAgain: boolean;
  onUploadAgain: () => unknown;
  documentIdentificationFlow: DocumentIdentificationFlow;
  productType: Option<UploadDocumentFlowType>;
} & CoApplicantInput;

function foldStatus<T>(
  status: SelfieCheckStatus,
  match: {
    whenReady: () => T;
    whenChooseRecipient: () => T;
    whenFraudCheck: (
      status: Extract<SelfieCheckStatus, { id: "FraudCheck" }>
    ) => T;
    whenOverview: () => T;
    whenSelfieCheckOnSameDevice: () => T;
  }
): T {
  switch (status.id) {
    case "Ready":
      return match.whenReady();
    case "ChooseRecipient":
      return match.whenChooseRecipient();
    case "FraudCheck":
      return match.whenFraudCheck(status);
    case "Overview":
      return match.whenOverview();
    case "CheckOnSameDevice":
      return match.whenSelfieCheckOnSameDevice();
  }
}

export function SelfieCheck(props: Props) {
  const formatMessage = useFormatMessage();
  const isMobileLayout = useIsMobileLayout();

  const [status, setStatus] = useState<SelfieCheckStatus>({
    id: "Ready",
  });

  const [error, setError] = useState<Option<GenericError | MissingSelfieError>>(
    option.none
  );

  const toReady = () =>
    setStatus({
      id: "Ready",
    });

  const toChooseRecipient = () =>
    setStatus({
      id: "ChooseRecipient",
    });

  const toFraudCheck = (recipient: MobileRecipientOptionType) =>
    setStatus({
      id: "FraudCheck",
      recipient,
    });

  const toOverview = () =>
    setStatus({
      id: "Overview",
    });

  const toCheckOnSameDevice = () =>
    setStatus({
      id: "CheckOnSameDevice",
    });

  const Placeholder = (
    <ContentRow type="1-1">
      <UploadImageButton
        image={option.none}
        onClick={toChooseRecipient}
        label={formatMessage("Identification.selfieCheck.selfie")}
      />
      <Space fluid />
    </ContentRow>
  );
  const WrapInUI = (component: JSX.Element) => (
    <Box column grow shrink>
      <Body size="small" weight="regular">
        {formatMessage("Identification.selfieCheck.description")}
      </Body>
      <Space units={8} />
      <Box>
        <Box column>
          <Body size="medium" weight="medium">
            {formatMessage("Identification.selfieCheck.howToTake")}
          </Body>
          <Space units={6} />
          <Stack units={10} grow shrink column={isMobileLayout}>
            <ExampleImage
              description={formatMessage("Identification.selfieCheck.imageOk")}
              image={OKExampleImage}
              isOk
            />
            <ExampleImage
              description={formatMessage(
                "Identification.selfieCheck.imageDark"
              )}
              image={DarkExampleImage}
            />
            <ExampleImage
              description={formatMessage(
                "Identification.selfieCheck.notCentered"
              )}
              image={PartialExampleImage}
            />
            <ExampleImage
              description={formatMessage(
                "Identification.selfieCheck.notRecognisable"
              )}
              image={SunglassesExampleImage}
            />
          </Stack>
        </Box>
      </Box>
      <Space units={10} />
      {component}
      <Space units={10} />
      <FormErrors
        errors={pipe(
          error,
          option.map(error => [formatMessage(error.id)])
        )}
      />
      <Space units={5} />
      <Box hAlignContent="right">
        <NextButton
          action={taskEither.fromIO(() =>
            pipe(
              status.id === "Overview",
              boolean.fold(
                () => setError(option.some(missingSelfieError)),
                () => props.onContinue()
              )
            )
          )}
        />
      </Box>
    </Box>
  );
  return foldStatus(status, {
    whenReady: () => WrapInUI(Placeholder),
    whenChooseRecipient: () =>
      WrapInUI(
        <RemoteMobileDeviceDialog
          onDismiss={toReady}
          onSelectRecipient={toFraudCheck}
          onUploadFromSameDevice={toCheckOnSameDevice}
          onMockScannerUpload={option.none}
          mobileRecipientType="Client"
          type="SelfieFraudCheck"
          coApplicant={props.coApplicant}
        />
      ),
    whenFraudCheck: status =>
      WrapInUI(
        <FraudCheckDialog
          documentIdentificationFlow={props.documentIdentificationFlow}
          fraudCheck="SelfieFraudCheck"
          canUploadAgain={props.canUploadAgain}
          recipient={status.recipient}
          coApplicant={option.none}
          onDismiss={toReady}
          onError={reason => {
            switch (reason) {
              case "GenericError":
                return setError(option.some(genericError));
              case "MaxAttemptsReached":
                return props.onFailure("MaxAttemptsReached");
            }
          }}
          onAbort={() => props.onFailure("GenericError")}
          onUploadAgain={props.onUploadAgain}
          onFraudCheck={toChooseRecipient}
          onContinue={toOverview}
        />
      ),
    whenOverview: () => WrapInUI(<SelfieCheckOverview />),
    whenSelfieCheckOnSameDevice: () => (
      <Suspense fallback={constNull}>
        <MobileCheckFlow
          type="SelfieFraudCheck"
          isSameDevice
          documentIdentificationFlow={props.documentIdentificationFlow}
          onComplete={option.some(props.onContinue)}
          coApplicant={props.coApplicant}
          uploadDocumentFlowType={props.productType}
        />
      </Suspense>
    ),
  });
}
