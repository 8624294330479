import { DropdownOption } from "design-system";
import { AllSupportedCitizenships, Citizenship } from "../../domain";
import { constant, identity, pipe } from "fp-ts/function";
import { record, nonEmptyArray, array, option } from "fp-ts";
import { NonEmptyArray } from "fp-ts/NonEmptyArray";
import { useFormatCitizenship } from "../../../intl";
import { Option } from "fp-ts/Option";
import {
  DropdownField,
  DropdownFieldProps,
} from "../../../Common/DropdownField/DropdownField";
import { useQuery } from "../../../useAPI";
import * as idUploadApi from "../../api";
import * as remoteData from "../../../RemoteData";

type Props = Omit<
  DropdownFieldProps<AllSupportedCitizenships>,
  "options" | "value" | "onChange" | "searchable"
> & {
  value: Option<AllSupportedCitizenships>;
  onChange: (citizenship: Option<AllSupportedCitizenships>) => unknown;
  supportForeign: boolean;
};

export function CitizenshipDropdown(props: Props) {
  const countries = pipe(
    useQuery(idUploadApi.allCountries),
    ([result]) => result,
    remoteData.fold(constant([]), constant([]), identity)
  );

  const formatCitizenship = useFormatCitizenship();

  const options = pipe(
    props.supportForeign ? AllSupportedCitizenships.keys : Citizenship.keys,
    rec => record.keys(rec) as NonEmptyArray<AllSupportedCitizenships>,
    nonEmptyArray.map(citizenship => ({
      value: citizenship,
      label: formatCitizenship(citizenship, countries),
    }))
  );

  const value = pipe(
    props.value,
    option.chain(value =>
      pipe(
        options,
        array.findFirst(o => o.value === value)
      )
    )
  );

  const onChange = (
    value: Option<DropdownOption<AllSupportedCitizenships>>
  ) => {
    props.onChange(
      pipe(
        value,
        option.map(v => v.value)
      )
    );
  };

  return (
    <DropdownField
      {...props}
      options={options}
      value={value}
      onChange={onChange}
      searchable
    />
  );
}
