import * as t from "io-ts";
import { apiCall } from "../../APICall";
import { Eq } from "fp-ts/Eq";
import { eq, option } from "fp-ts";
import {
  DateFromSQLString,
  DocumentPurpose,
  optionFromUndefined,
} from "../../globalDomain";
import { NonEmptyString } from "io-ts-types/lib/NonEmptyString";
import {
  AddressOCR,
  AllCitizenships,
  AllSupportedCitizenships,
  PersonalDataOCR,
  PersonalDocumentOCR,
} from "../domain";
import {
  ApplicationLimitError,
  eqWithApplicantIndex,
  withApplicantIndex,
} from "../../MortgageDashboard/domain";
import { DocumentType } from "../../UploadDocuments/domain";

export const CheckExistingClientInput = withApplicantIndex(
  t.type(
    {
      dateOfBirth: DateFromSQLString,
      lastAndFirstName: NonEmptyString,
      personalNumber: NonEmptyString,
      citizenship: AllSupportedCitizenships,
    },
    "CheckExistingClientInput"
  )
);
export type CheckExistingClientInput = t.TypeOf<
  typeof CheckExistingClientInput
>;
export const eqCheckExistingClientInput: Eq<CheckExistingClientInput> = eqWithApplicantIndex(
  eq.getStructEq({
    dateOfBirth: eq.eqDate,
    lastAndFirstName: eq.eqString,
    personalNumber: eq.eqString,
    citizenship: eq.eqString,
  })
);

export const CheckExistingClientOutput = t.type(
  {
    existingClient: t.boolean,
    duplicateContacts: t.boolean,
    hasBlockingNotes: t.boolean,
    multipleUsers: t.boolean,
    personalNumberMatch: t.boolean,
    clientNumber: optionFromUndefined(NonEmptyString),
    userID: optionFromUndefined(NonEmptyString),
  },
  "CheckExistingClientOutput"
);

export type CheckExistingClientOutput = t.TypeOf<
  typeof CheckExistingClientOutput
>;

export const checkExistingClient = apiCall({
  path: ["clients", "checkExisting"],
  inputCodec: CheckExistingClientInput,
  inputEq: eqCheckExistingClientInput,
  outputCodec: CheckExistingClientOutput,
  errorCodec: ApplicationLimitError,
});

const GetClientDataForConfirmationInput = t.type(
  {
    idType: optionFromUndefined(DocumentPurpose),
  },
  "GetClientDataForConfirmationInput"
);

const eqGetClientDataForConfirmationInput = eq.getStructEq({
  idType: option.getEq(eq.eqStrict),
});

const GetClientDataForConfirmationOutput = t.type(
  {
    documentType: DocumentType,
    personalData: t.intersection([
      PersonalDataOCR,
      t.type({ secondCitizenship: optionFromUndefined(AllCitizenships) }),
    ]),
    documentDetails: PersonalDocumentOCR,
    secondaryDocumentType: optionFromUndefined(DocumentType),
    secondaryDocumentDetails: optionFromUndefined(PersonalDocumentOCR),
    permanentAddress: optionFromUndefined(AddressOCR),
  },
  "GetClientDataForConfirmationInput"
);

export const getClientDataForConfirmation = apiCall({
  path: [
    "packages",
    "uKonto",
    "identification",
    "potentialClient",
    "getClientDataForConfirmation",
  ],
  inputCodec: GetClientDataForConfirmationInput,
  inputEq: eqGetClientDataForConfirmationInput,
  outputCodec: GetClientDataForConfirmationOutput,
});
