import { CountryCode } from "../../globalDomain";
import {
  ComputedFieldProps,
  InlineMessage,
  LocalizedString,
  ReadOnlyField,
} from "design-system";

import { useFormatMessage } from "../../intl";
import { mapCountriesToOptions } from "../addressFormUtils";
import { pipe, constant, identity } from "fp-ts/function";
import { array, option } from "fp-ts";
import { useQuery } from "../../useAPI";
import * as remoteData from "../../RemoteData";
import * as identificationApi from "../api";
import { DropdownField } from "../../Common/DropdownField/DropdownField";
import { ComponentProps } from "react";

type Props =
  | ({
      readOnly: false;
      supportForeign: boolean;
      lockCzSk: boolean;
    } & ComputedFieldProps<CountryCode>)
  | {
      readOnly: true;
      value: CountryCode;
      lockCzSk: boolean;
      feedback?: {
        type: ComponentProps<typeof InlineMessage>["type"];
        message: LocalizedString;
      };
    };

export function CountryDropdown(props: Props) {
  const formatMessage = useFormatMessage();

  const countries = pipe(
    useQuery(
      props.lockCzSk
        ? identificationApi.countries
        : props.readOnly
        ? identificationApi.allCountries
        : props.supportForeign
        ? identificationApi.allForeignSupportedCountries
        : identificationApi.countries
    ),
    ([result]) => result,
    remoteData.fold(constant([]), constant([]), identity)
  );

  const options = mapCountriesToOptions(countries);

  return props.readOnly ? (
    <ReadOnlyField
      size="medium"
      value={pipe(
        countries,
        array.findFirst(c => c.countryCode === props.value),
        option.map(c => c.label),
        option.getOrElse(constant(""))
      )}
      label={formatMessage("Identification.address.country")}
      feedback={props.feedback}
    />
  ) : (
    <DropdownField
      {...props}
      value={pipe(
        options,
        array.findFirst(o => o.value === props.value)
      )}
      onChange={value => {
        if (option.isSome(value)) {
          props.onChange(value.value.value);
        }
      }}
      label={formatMessage("Identification.address.country")}
      placeholder={formatMessage("Identification.address.country")}
      options={options}
      searchable
      clearable={false}
    />
  );
}
