import { useQuery } from "../../useAPI";
import * as api from "../api";
import { Box, ErrorBanner, Loader } from "design-system";
import { constant, constNull, pipe } from "fp-ts/function";
import { option } from "fp-ts";
import * as classes from "../../UploadDocuments/UploadSummary.treat";
import { srcFromBase64 } from "../../UploadDocuments/utils";
import * as remoteData from "../../RemoteData";
import { useFormatMessage } from "../../intl";

export function SelfieCheckOverview() {
  const [fraudCheckSelfie] = useQuery(api.fraudCheckSelfie);

  const formatMessage = useFormatMessage();
  return pipe(
    fraudCheckSelfie,
    remoteData.fold(
      () => <Loader />,
      () => <ErrorBanner children={formatMessage("GenericError")} />,
      ({ fileContent }) =>
        pipe(
          fileContent,
          option.fold(constNull, fileContent => (
            <Box shrink basis="50%">
              <img
                className={classes.image}
                src={pipe(
                  srcFromBase64(fileContent),
                  option.getOrElse(constant(""))
                )}
              />
            </Box>
          ))
        )
    )
  );
}
