import { option, taskEither } from "fp-ts";
import { AddressWrite } from "../domain";
import {
  Box,
  Divider,
  ErrorBanner,
  Stack,
  Body,
  LoadingButton,
  Banner,
} from "design-system";
import { AddressBlock } from "./AddressBlock";
import { useFormatMessage } from "../../intl";
import * as identificationApi from "../api";
import { useCommand } from "../../useAPI";
import { pipe, constNull, constant, flow } from "fp-ts/function";
import { TaskEither } from "fp-ts/TaskEither";
import { PersonalDataProcessingDisclaimer } from "../../Common/PersonalDataProcessingDisclaimer/PersonalDataProcessingDisclaimer";
import { CoApplicantInput, genericError } from "../../globalDomain";
import { addressWriteToAddressOCR } from "../addressFormUtils";
import { palette } from "design-system/lib/styleConstants";
import { useParentSharedReducer } from "../../BranchExperience/useSharedReducer";
import {
  shareWithClientAction,
  reducerConfig,
  setErrorAction,
  setCurrentAddressAction,
  cancelEditingAction,
  editCurrentAddressAction,
  submitAddress,
  resetSubmitAddress,
} from "./state";
import { useBranchExperienceContext } from "../../BranchExperience/BranchExperienceContext";
import { ShareWithClientButton } from "../../Common/ShareWithClientButton/ShareWithClientButton";
import { usePortalStatusContext } from "../../PortalStatusContext";

type Props = {
  isWaitingForOpuAndCex?: boolean;
  onContinue: TaskEither<unknown, unknown>;
  onEditingChange: (isEditing: boolean) => void;
  foreignSupport?: boolean;
} & CoApplicantInput;

export function AdditionalAddresses(props: Props) {
  const formatMessage = useFormatMessage();

  const addressSubmit = useCommand(identificationApi.addressSubmit);

  const { branchExperienceFeaturesActive } = useBranchExperienceContext();
  const { portalBlocked } = usePortalStatusContext();

  const [state, dispatch] = useParentSharedReducer(reducerConfig, {
    shared: false,
    formInEditing: false,
    error: option.none,
    currentAddress: option.none,
    submitting: false,
  });

  const hasAddresses = option.isSome(state.currentAddress);

  const onSubmit = (): TaskEither<unknown, unknown> => {
    const currentAddressBody = pipe(
      state.currentAddress,
      option.map<AddressWrite, identificationApi.IDAddressSubmitInput>(
        address => ({
          addressType: "Current",
          address,
          coApplicant: props.coApplicant,
        })
      )
    );
    if (branchExperienceFeaturesActive && !state.shared && hasAddresses) {
      return taskEither.rightIO(() => dispatch(shareWithClientAction()));
    }

    return pipe(
      taskEither.bracket(
        taskEither.fromIO(() => dispatch(submitAddress())),
        () =>
          pipe(
            currentAddressBody,
            option.fold(
              constant(pipe(props.onContinue)),
              flow(
                addressSubmit,
                taskEither.chain(() => props.onContinue),
                taskEither.mapLeft(() => dispatch(setErrorAction(genericError)))
              )
            )
          ),
        () => taskEither.fromIO(() => dispatch(resetSubmitAddress()))
      )
    );
  };

  const CurrentAddressForm = (
    <AddressBlock
      data-test-id="current_address"
      foreignSupport={props.foreignSupport}
      forceValidateForForeign={false}
      initialState={pipe(
        state.currentAddress,
        option.map(addressWriteToAddressOCR)
      )}
      isEditing={state.formInEditing}
      submitInvalid={false}
      onSave={address =>
        taskEither.fromIO(() => {
          props.onEditingChange(false);
          dispatch(setCurrentAddressAction(option.some(address)));
        })
      }
      onRemove={option.some(() =>
        dispatch(setCurrentAddressAction(option.none))
      )}
      onEdit={option.some(() => {
        props.onEditingChange(true);
        dispatch(editCurrentAddressAction());
      })}
      onCancel={() => {
        props.onEditingChange(false);
        dispatch(cancelEditingAction());
      }}
      title={formatMessage(
        "Identification.additionalAddresses.currentAndContactAddress"
      )}
      subtitle={option.none}
      addressDescription={formatMessage(
        "Identification.additionalAddresses.currentAddressDescription"
      )}
      addAddressLabel={formatMessage(
        "Identification.additionalAddresses.addCurrentAddress"
      )}
      hint={option.none}
      disabled={state.formInEditing || state.submitting || portalBlocked}
      tooltipContent={formatMessage(
        "Identification.additionalAddresses.currentAddressTooltipContent"
      )}
      reworkAddress={option.none}
      lockCzSk={true}
    />
  );

  return (
    <Box grow hAlignContent="center" shrink>
      <Stack units={6} column shrink>
        <Body size="medium" weight="regular" color={palette.neutral700}>
          {formatMessage("Identification.additionalAddresses.description")}
        </Body>
        {CurrentAddressForm}
        <Divider width="100%" />
        <PersonalDataProcessingDisclaimer disabledLink={state.formInEditing} />
        {pipe(
          state.error,
          option.fold(constNull, () => (
            <ErrorBanner children={formatMessage("GenericError")} />
          ))
        )}
        {/* The following fragment is a temporary workaround for: https://buildo.kaiten.io/space/29286/card/2311626 */}
        <>
          {state.shared && (
            <Banner
              type="informative"
              title={option.none}
              actions={option.none}
              onDismiss={option.none}
              content={formatMessage("BranchExperience.waitingConfirmation")}
            />
          )}
        </>
        <Box hAlignContent="right">
          {hasAddresses ? (
            <ShareWithClientButton
              submitLabel={formatMessage("Continue")}
              branchExperienceState={
                state.shared ? "sharedWithClient" : "notShared"
              }
              action={onSubmit()}
              disabled={state.formInEditing || portalBlocked}
            />
          ) : (
            <LoadingButton
              variant="primary"
              size="default"
              labels={{
                normal: formatMessage(
                  "Identification.additionalAddresses.continueWithoutAddresses"
                ),
                success: formatMessage(
                  "Identification.additionalAddresses.continueWithoutAddresses"
                ),
                loading: formatMessage(
                  "Identification.additionalAddresses.continueWithoutAddresses"
                ),
                error: formatMessage(
                  "Identification.additionalAddresses.continueWithoutAddresses"
                ),
              }}
              action={onSubmit()}
              disabled={
                state.formInEditing ||
                props.isWaitingForOpuAndCex ||
                portalBlocked
              }
            />
          )}
        </Box>
      </Stack>
    </Box>
  );
}
