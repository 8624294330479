import { DropdownOption } from "design-system";
import { constant, pipe } from "fp-ts/function";
import { array, option } from "fp-ts";
import { Option } from "fp-ts/Option";
import { useQuery } from "../../../useAPI";
import * as api from "../../api";
import * as remoteData from "../../../RemoteData";
import {
  DropdownField,
  DropdownFieldProps,
} from "../../../Common/DropdownField/DropdownField";

type Props = Omit<
  DropdownFieldProps<string>,
  "options" | "value" | "onChange" | "searchable"
> & {
  value: Option<string>;
  onChange: (titleAfter: Option<string>) => unknown;
};

export function AuthorityDropdown(props: Props) {
  const [authorities] = useQuery(api.documentAuthorities);

  const options = pipe(
    authorities,
    remoteData.fold(constant([]), constant([]), authorities =>
      authorities.map(authority => ({
        value: authority,
        label: authority,
      }))
    )
  );

  const value = pipe(
    props.value,
    option.chain(value =>
      pipe(
        options,
        array.findFirst(o => o.value.toUpperCase() === value.toUpperCase())
      )
    )
  );

  const onChange = (value: Option<DropdownOption<string>>) => {
    props.onChange(
      pipe(
        value,
        option.map(v => v.value)
      )
    );
  };

  return (
    <DropdownField
      {...props}
      options={options}
      value={value}
      onChange={onChange}
      searchable
      disabled={props.disabled}
    />
  );
}
