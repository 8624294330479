import { ComputedFieldProps, ReadOnlyField } from "design-system";

import { useFormatMessage } from "../../intl";
import { mapCountriesToOptions } from "../addressFormUtils";
import { pipe, constant, identity, constFalse } from "fp-ts/function";
import { array, option } from "fp-ts";
import { Option } from "fp-ts/Option";
import { useQuery } from "../../useAPI";
import * as remoteData from "../../RemoteData";
import * as identificationApi from "../api";
import { CountryCode } from "../../globalDomain";
import { selectedDropdownOption } from "../../Common/selectDropdownOption";
import { DropdownField } from "../../Common/DropdownField/DropdownField";
import { ComponentProps } from "react";

type Props = (
  | ({ readOnly: false } & ComputedFieldProps<Option<CountryCode>>)
  | { readOnly: true; value: Option<CountryCode> }
) & {
  feedback?: ComponentProps<typeof ReadOnlyField>["feedback"];
  issues?: ComponentProps<typeof DropdownField>["issues"];
};

export function CountryOfBirthDropdown(props: Props) {
  const formatMessage = useFormatMessage();

  const countries = pipe(
    useQuery(identificationApi.allCountries),
    ([result]) => result,
    remoteData.fold(constant([]), constant([]), identity)
  );

  const options = mapCountriesToOptions(countries);

  return props.readOnly ? (
    <ReadOnlyField
      size="medium"
      value={pipe(
        countries,
        array.findFirst(c =>
          pipe(
            props.value,
            option.fold(constFalse, v => c.countryCode === v)
          )
        ),
        option.map(c => c.label),
        option.getOrElse(constant(""))
      )}
      label={formatMessage("Identification.countryOfBirth.label")}
      feedback={props.feedback}
    />
  ) : (
    <DropdownField
      {...props}
      value={selectedDropdownOption(props.value, options)}
      onChange={value => {
        if (option.isSome(value)) {
          props.onChange(option.some(value.value.value));
        }
      }}
      label={formatMessage("Identification.countryOfBirth.label")}
      placeholder={formatMessage("Identification.countryOfBirth.placeholder")}
      options={options}
      searchable
      clearable={false}
      issues={props.issues}
    />
  );
}
