import * as t from "io-ts";
import { sharedReducerConfig } from "../../BranchExperience/useSharedReducer";

const NotSharedState = t.type({ id: t.literal("notShared") });
const SharedState = t.type({ id: t.literal("sharedWithClient") });

const State = t.union([NotSharedState, SharedState]);
type State = t.TypeOf<typeof State>;

const ShareWithClientAction = t.type({
  id: t.literal("ShareWithClient"),
});
export const shareWithClientAction: Action = {
  id: "ShareWithClient",
};
const EditDataAction = t.type({
  id: t.literal("EditData"),
});
export const editDataAction: Action = {
  id: "EditData",
};
const Action = t.union([ShareWithClientAction, EditDataAction]);
type Action = t.TypeOf<typeof Action>;

function reducer(_: State, action: Action): State {
  switch (action.id) {
    case "ShareWithClient":
      return { id: "sharedWithClient" };
    case "EditData":
      return { id: "notShared" };
  }
}

export const reducerConfig = sharedReducerConfig(
  "ConfirmClientData",
  State,
  Action,
  reducer
);
