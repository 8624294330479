import { AllSupportedCitizenships } from "./domain";
import { Option } from "fp-ts/Option";
import { option } from "fp-ts";
import { pipe } from "fp-ts/function";

export function isForeign(citizenship: Option<AllSupportedCitizenships>) {
  return pipe(
    citizenship,
    option.fold(
      () => false, //default cz/sk
      isForeign_
    )
  );
}

export function isForeign_(citizenship: AllSupportedCitizenships) {
  return citizenship !== "SVK" && citizenship !== "CZE";
}

export function isCzSk(citizenship: Option<AllSupportedCitizenships>) {
  return pipe(
    citizenship,
    option.fold(
      () => true, //default cz/sk
      citizenship => citizenship === "SVK" || citizenship === "CZE"
    )
  );
}
