import * as t from "io-ts";
import { sharedReducerConfig } from "../../BranchExperience/useSharedReducer";
import { AddressWrite } from "../domain";
import { option } from "fp-ts";
import { Option } from "fp-ts/Option";
import { GenericError, optionFromUndefined } from "../../globalDomain";

const State = t.type({
  formInEditing: t.boolean,
  error: optionFromUndefined(GenericError),
  currentAddress: optionFromUndefined(AddressWrite),
  shared: t.boolean,
  submitting: t.boolean,
});
type State = t.TypeOf<typeof State>;

const ShareWithClientAction = t.type({
  id: t.literal("ShareWithClient"),
});

const SetErrorAction = t.type({
  id: t.literal("SetError"),
  payload: GenericError,
});

const SetCurrentAddressAction = t.type({
  id: t.literal("SetCurrentAddress"),
  payload: t.type({
    address: optionFromUndefined(AddressWrite),
  }),
});

const CancelEditingAction = t.type({
  id: t.literal("CancelEditing"),
});

const SetEditingCurrentAddressAction = t.type({
  id: t.literal("SetEditingCurrentAddress"),
});

const SetSubmitAddress = t.type({
  id: t.literal("SetSubmitAddress"),
});

const ResetSubmitAddress = t.type({
  id: t.literal("ResetSubmitAddress"),
});

const Action = t.union([
  ShareWithClientAction,
  SetErrorAction,
  SetCurrentAddressAction,
  CancelEditingAction,
  SetEditingCurrentAddressAction,
  SetSubmitAddress,
  ResetSubmitAddress,
]);

type Action = t.TypeOf<typeof Action>;

export function shareWithClientAction(): Action {
  return {
    id: "ShareWithClient",
  };
}

export function setCurrentAddressAction(address: Option<AddressWrite>): Action {
  return {
    id: "SetCurrentAddress",
    payload: {
      address,
    },
  };
}

export function setErrorAction(error: GenericError): Action {
  return {
    id: "SetError",
    payload: error,
  };
}

export function cancelEditingAction(): Action {
  return {
    id: "CancelEditing",
  };
}

export function editCurrentAddressAction(): Action {
  return {
    id: "SetEditingCurrentAddress",
  };
}

export function submitAddress(): Action {
  return {
    id: "SetSubmitAddress",
  };
}

export function resetSubmitAddress(): Action {
  return {
    id: "ResetSubmitAddress",
  };
}

function reducer(state: State, action: Action): State {
  switch (action.id) {
    case "SetCurrentAddress":
      return {
        ...state,
        formInEditing: false,
        currentAddress: action.payload.address,
        shared: false,
        submitting: false,
      };
    case "SetError":
      return {
        ...state,
        formInEditing: false,
        error: option.some(action.payload),
        submitting: false,
      };
    case "ShareWithClient":
      return {
        ...state,
        formInEditing: false,
        error: option.none,
        shared: true,
        submitting: false,
      };
    case "CancelEditing":
      return {
        ...state,
        formInEditing: false,
        submitting: false,
      };
    case "SetEditingCurrentAddress":
      return {
        ...state,
        error: option.none,
        formInEditing: true,
        submitting: false,
      };
    case "SetSubmitAddress":
      return {
        ...state,
        submitting: true,
      };
    case "ResetSubmitAddress":
      return {
        ...state,
        submitting: false,
      };
  }
}

export const reducerConfig = sharedReducerConfig(
  "AdditionalAddresses",
  State,
  Action,
  reducer
);
