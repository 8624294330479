import * as t from "io-ts";
import { Eq } from "fp-ts/Eq";
import {
  CountryCode,
  DateFromSQLString,
  ForeignCountries,
  MaritalStatus,
  optionFromArray,
  optionFromUndefined,
  Sex,
  Tenant,
} from "../globalDomain";
import { NonEmptyString } from "io-ts-types/lib/NonEmptyString";
import { eq, option } from "fp-ts";
import { optionFromNullable } from "io-ts-types/lib/optionFromNullable";
import { ClientDataCheck, DocumentType } from "../UploadDocuments/domain";
import { withFallback } from "io-ts-types/lib/withFallback";
import { nonEmptyArray } from "io-ts-types/nonEmptyArray";
import { LocalizedString } from "design-system";

export const AddressType = t.keyof({
  Current: true,
  Contact: true,
});
export type AddressType = t.TypeOf<typeof AddressType>;
export const eqAddressType: Eq<AddressType> = eq.eqStrict;

export const StreetSuggestion = t.type(
  {
    streetName: t.string,
    zipCode: t.string,
  },
  "StreetSuggestion"
);
export type StreetSuggestion = t.TypeOf<typeof StreetSuggestion>;

export const CitySuggestion = t.type(
  {
    cityName: LocalizedString,
    provinceName: LocalizedString,
  },
  "CitySuggestion"
);
export type CitySuggestion = t.TypeOf<typeof CitySuggestion>;

export const Address = t.type(
  {
    streetName: optionFromUndefined(NonEmptyString),
    streetNumber: optionFromUndefined(NonEmptyString),
    city: optionFromUndefined(NonEmptyString),
    provinceName: optionFromUndefined(NonEmptyString),
    zipCode: withFallback(optionFromUndefined(NonEmptyString), option.none), // fallback due to SBL-24916
    country: CountryCode,
  },
  "Address"
);
export type Address = t.TypeOf<typeof Address>;

// when submitting an address the province is required
export const AddressWrite = t.type(
  {
    streetName: optionFromUndefined(NonEmptyString),
    streetNumber: optionFromUndefined(NonEmptyString),
    city: optionFromUndefined(NonEmptyString),
    provinceName: optionFromUndefined(NonEmptyString),
    zipCode: optionFromUndefined(NonEmptyString),
    country: CountryCode,
  },
  "Address"
);
export type AddressWrite = t.TypeOf<typeof AddressWrite>;

export const eqAddressWrite: Eq<AddressWrite> = eq.getStructEq({
  streetName: option.getEq(eq.eqString),
  streetNumber: option.getEq(eq.eqString),
  city: option.getEq(eq.eqString),
  provinceName: option.getEq(eq.eqString),
  zipCode: option.getEq(eq.eqString),
  country: eq.eqString,
});

export const AddressOCR = t.type(
  {
    streetName: optionFromUndefined(NonEmptyString),
    streetNumber: optionFromUndefined(t.string),
    city: optionFromUndefined(NonEmptyString),
    provinceName: withFallback(
      // fallback due to SBL-32898
      optionFromUndefined(NonEmptyString),
      option.none
    ),
    zipCode: optionFromUndefined(NonEmptyString),
    country: optionFromUndefined(CountryCode),
  },
  "AddressOCR"
);
export type AddressOCR = t.TypeOf<typeof AddressOCR>;

export const Citizenship = t.keyof({
  CZE: true,
  SVK: true,
});
export type Citizenship = t.TypeOf<typeof Citizenship>;

//supported foreign citizenships
export const CitizenshipForeign = t.keyof({
  AUT: true,
  BEL: true,
  BGR: true,
  CYP: true,
  DEU: true,
  DNK: true,
  EST: true,
  ESP: true,
  FIN: true,
  FRA: true,
  GRC: true,
  HRV: true,
  HUN: true,
  IRL: true,
  ITA: true,
  LTU: true,
  LUX: true,
  LVA: true,
  MLT: true,
  NLD: true,
  POL: true,
  PRT: true,
  ROU: true,
  SWE: true,
  SVN: true,
});
export type CitizenshipForeign = t.TypeOf<typeof CitizenshipForeign>;

export const AllSupportedCitizenships = t.keyof({
  ...Citizenship.keys,
  ...CitizenshipForeign.keys,
});
export type AllSupportedCitizenships = t.TypeOf<
  typeof AllSupportedCitizenships
>;

export const CountryOption = t.type(
  {
    tenant: optionFromNullable(Tenant),
    label: LocalizedString,
    countryCode: CountryCode,
  },
  "CountryOption"
);
export type CountryOption = t.TypeOf<typeof CountryOption>;

export const AllCitizenships = t.keyof({
  ...Citizenship.keys,
  ...ForeignCountries.keys,
});
export type AllCitizenships = t.TypeOf<typeof AllCitizenships>;

export const PersonalData = t.type({
  title: optionFromUndefined(NonEmptyString),
  titleAfter: optionFromUndefined(NonEmptyString),
  name: NonEmptyString,
  surname: NonEmptyString,
  sex: Sex,
  maritalStatus: optionFromUndefined(MaritalStatus),
  dateOfBirth: DateFromSQLString,
  birthNumber: optionFromUndefined(NonEmptyString),
  placeOfBirth: NonEmptyString,
  countryOfBirth: optionFromUndefined(CountryCode),
  citizenship: AllSupportedCitizenships,
  secondCitizenship: optionFromUndefined(AllCitizenships),
  localPersonalNumber: optionFromUndefined(NonEmptyString),
});
export type PersonalData = t.TypeOf<typeof PersonalData>;
export const eqPersonalData: Eq<PersonalData> = eq.getStructEq({
  title: option.getEq(eq.eqString),
  titleAfter: option.getEq(eq.eqString),
  name: eq.eqString,
  surname: eq.eqString,
  sex: eq.eqString,
  maritalStatus: option.getEq(eq.eqString),
  dateOfBirth: eq.eqDate,
  birthNumber: option.getEq(eq.eqString),
  placeOfBirth: eq.eqString,
  countryOfBirth: option.getEq(eq.eqString),
  citizenship: eq.eqString,
});

export const CitizenshipOrOther = t.keyof({
  ...AllSupportedCitizenships.keys,
  ...ForeignCountries.keys,
  OTHER: true,
});
export type CitizenshipOrOther = t.TypeOf<typeof CitizenshipOrOther>;

export const PersonalDataOCR = t.type({
  title: optionFromUndefined(NonEmptyString),
  titleAfter: optionFromUndefined(NonEmptyString),
  name: optionFromUndefined(NonEmptyString),
  surname: optionFromUndefined(NonEmptyString),
  sex: optionFromUndefined(Sex),
  maritalStatus: optionFromUndefined(MaritalStatus),
  dateOfBirth: optionFromUndefined(DateFromSQLString),
  birthNumber: optionFromUndefined(NonEmptyString),
  placeOfBirth: optionFromUndefined(NonEmptyString),
  countryOfBirth: optionFromUndefined(CountryCode),
  citizenship: withFallback(
    optionFromUndefined(CitizenshipOrOther),
    option.some("OTHER" as const)
  ),
  localPersonalNumber: optionFromUndefined(NonEmptyString),
  secondCitizenship: optionFromUndefined(AllCitizenships), // not OCRed, but for compat
});
export type PersonalDataOCR = t.TypeOf<typeof PersonalDataOCR>;

export const PersonalDocument = t.type({
  idNumber: NonEmptyString,
  dateOfIssuing: DateFromSQLString,
  validUntil: optionFromUndefined(DateFromSQLString),
  authority: optionFromUndefined(t.string),
  authorityMandatory: t.boolean,
  issuer: optionFromUndefined(t.string),
  issuerMandatory: t.boolean,
});
export type PersonalDocument = t.TypeOf<typeof PersonalDocument>;
export const eqPersonalDocument: Eq<PersonalDocument> = eq.getStructEq({
  idNumber: eq.eqString,
  dateOfIssuing: eq.eqStrict,
  validUntil: option.getEq(eq.eqStrict),
  authority: option.getEq(eq.eqString),
  authorityMandatory: eq.eqBoolean,
  issuer: option.getEq(eq.eqString),
  issuerMandatory: eq.eqBoolean,
});

export const PersonalDocumentOCR = t.type({
  idNumber: optionFromUndefined(NonEmptyString),
  dateOfIssuing: optionFromUndefined(DateFromSQLString),
  validUntil: optionFromUndefined(DateFromSQLString),
  authority: optionFromUndefined(NonEmptyString),
  authorityMandatory: t.boolean,
  issuer: optionFromUndefined(NonEmptyString),
  issuerMandatory: t.boolean,
});
export type PersonalDocumentOCR = t.TypeOf<typeof PersonalDocumentOCR>;

export const PersonalDocumentProofOfAddress = t.type({
  idNumber: optionFromUndefined(NonEmptyString),
  dateOfIssuing: DateFromSQLString,
  validUntil: optionFromUndefined(DateFromSQLString),
  issuer: optionFromUndefined(t.string),
  localPersonalNumber: optionFromUndefined(NonEmptyString),
});
export type PersonalDocumentProofOfAddress = t.TypeOf<
  typeof PersonalDocumentProofOfAddress
>;
export const eqPersonalDocumentOfAddress: Eq<PersonalDocumentProofOfAddress> = eq.getStructEq(
  {
    idNumber: option.getEq(eq.eqString),
    dateOfIssuing: eq.eqStrict,
    validUntil: option.getEq(eq.eqStrict),
    issuer: option.getEq(eq.eqString),
    localPersonalNumber: option.getEq(eq.eqString),
  }
);
export const PersonalInfoError = t.strict({
  personalData: optionFromArray(LocalizedString),
  documentDetails: optionFromArray(LocalizedString),
  permanentAddress: optionFromArray(LocalizedString),
  generalError: optionFromArray(LocalizedString),
});
export type PersonalInfoError = t.TypeOf<typeof PersonalInfoError>;

export const PersonalProfileDocUploadSubmitError = t.intersection([
  PersonalInfoError,
  t.strict({ compareDocs: t.boolean }),
]);
export type PersonalProfileDocUploadSubmitError = t.TypeOf<
  typeof PersonalProfileDocUploadSubmitError
>;

export const ClientDataOCR = t.type({
  documentType: DocumentType,
  personalData: PersonalDataOCR,
  documentDetails: PersonalDocumentOCR,
  permanentAddress: optionFromUndefined(AddressOCR),
  addressSuggestions: optionFromUndefined(nonEmptyArray(AddressWrite)),
  validationErrors: withFallback(
    optionFromUndefined(PersonalInfoError),
    option.none
  ),
});
export type ClientDataOCR = t.TypeOf<typeof ClientDataOCR>;

export const ClientDataResult = t.union([
  t.keyof({
    UploadAgain: true,
    EditData: true,
    Continue: true,
    Abort: true,
    ContinueWithWarning: true,
    EditDataWithWarning: true,
  }),
  ClientDataCheck,
]);
export type ClientDataResult = t.TypeOf<typeof ClientDataResult>;

export const FraudCheckResult = t.union(
  [
    ClientDataResult,
    t.keyof({
      Redo: true,
    }),
  ],
  "FraudCheckResult"
);
export type FraudCheckResult = t.TypeOf<typeof FraudCheckResult>;

export const ClientDataEdit = t.partial({
  personalData: PersonalData,
  documentDetails: PersonalDocument,
  permanentAddress: Address,
});
export type ClientDataEdit = t.TypeOf<typeof ClientDataEdit>;

export const AddressCountries = t.array(CountryOption, "AddressCountries");
export type AddressCountries = t.TypeOf<typeof AddressCountries>;

export const AllCountries = t.array(CountryOption, "AllCountries");
export type AllCountries = t.TypeOf<typeof AllCountries>;
