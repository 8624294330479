import { AllSupportedCitizenships, PersonalData } from "../../domain";
import { option, taskEither } from "fp-ts";
import { pipe } from "fp-ts/function";
import {
  Box,
  Button,
  LocalizedString,
  Space,
  useForm,
  Validator,
  validators,
} from "design-system";
import { useFormatMessage } from "../../../intl";
import { FormState, PersonalDataForm } from "./PersonalDataForm";

import { Option } from "fp-ts/Option";
import { CountryCode, Sex } from "../../../globalDomain";
import { useValidators } from "../../../Common/useValidators";
import { isForeign } from "../../utils";
import { NonEmptyString } from "io-ts-types/lib/NonEmptyString";
import { DocumentType } from "../../../UploadDocuments/domain";

const nameForbiddenChars = /[\^<>\\$#ß*%`˙~đĐ\[\]®™€×¤{}÷]/;

type Props = {
  initialValues: FormState;
  onValidate: Validator<PersonalData>;
  onSave: (personalData: PersonalData) => unknown;
  onCancel: () => unknown;
  displayCountryOfBirth: boolean;
  supportForeign: boolean;
  documentType: DocumentType;
};

export function PersonalDataFormEdit(props: Props) {
  const formatMessage = useFormatMessage();

  const {
    nonBlankString,
    defined,
    definedNoExtract,
    validBirthDate,
    validBirthNumber,
    maxLength,
  } = useValidators();

  const nameValidator = <T extends string>(
    errorMessage: LocalizedString
  ): Validator<T> =>
    validators.fromPredicate(s => !nameForbiddenChars.test(s), errorMessage);

  const { fieldProps, handleSubmit, formErrors } = useForm(
    {
      initialValues: props.initialValues,
      fieldValidators: values => {
        return {
          name: validators.inSequence(
            nonBlankString,
            validators.maxLength(
              25,
              formatMessage("Identification.personalData.nameLengthError")
            ),
            nameValidator(
              formatMessage("Form.fieldError.disableSpecialCharacters")
            )
          ),
          surname: validators.inSequence(
            nonBlankString,
            validators.maxLength(
              25,
              formatMessage("Identification.personalData.surnameLengthError")
            ),
            nameValidator(
              formatMessage("Form.fieldError.disableSpecialCharacters")
            )
          ),
          sex: defined<Sex>(),
          citizenship: defined<AllSupportedCitizenships>(),
          dateOfBirth: validators.inSequence(
            defined<Option<Date>>(),
            validBirthDate
          ),
          placeOfBirth: validators.inSequence(nonBlankString, maxLength(30)),
          countryOfBirth: props.displayCountryOfBirth
            ? definedNoExtract<CountryCode>()
            : undefined,
          birthNumber: isForeign(values.citizenship)
            ? undefined
            : validators.inSequence(
                defined<NonEmptyString>(),
                validBirthNumber
              ),
        };
      },
    },
    {
      onSubmit: values =>
        pipe(
          {
            ...values,
            birthNumber: NonEmptyString.is(values.birthNumber)
              ? option.some(values.birthNumber)
              : values.birthNumber,
            secondCitizenship: option.none,
          },
          props.onValidate,
          taskEither.chain(values =>
            taskEither.fromIO(() => props.onSave(values))
          )
        ),
    }
  );

  return (
    <Box column>
      <PersonalDataForm
        fieldProps={fieldProps}
        displayCountryOfBirth={props.displayCountryOfBirth}
        readOnly={false}
        errors={formErrors}
        reworkPersonalData={option.none}
        supportForeign={props.supportForeign}
        documentType={props.documentType}
      />
      <Space units={5} />
      <Box grow shrink vAlignContent="center">
        <Button
          variant="text"
          size="default"
          action={props.onCancel}
          label={formatMessage("Cancel")}
        />
        <Space fluid />
        <Button
          variant="primary"
          size="default"
          action={handleSubmit}
          label={formatMessage("Save")}
        />
      </Box>
    </Box>
  );
}
