import { useFormatMessage } from "../../intl";
import {
  DropdownOption,
  Form,
  FormRow,
  Space,
  AutocompleteInputField,
  SuggestionsStatus,
  Banner,
  ReadOnlyField,
  FormSection,
  TextField,
  ComputedFieldProps,
  LocalizedString,
  Issue,
  CheckboxField,
} from "design-system";
import { option, nonEmptyArray } from "fp-ts";
import { pipe } from "fp-ts/function";
import { Option } from "fp-ts/Option";
import { CitySuggestion, StreetSuggestion } from "../domain";
import { AddressFormState } from "../addressFormUtils";
import { NonEmptyArray } from "fp-ts/NonEmptyArray";
import { CountryDropdown } from "./CountryDropdown";
import { TaskEither } from "fp-ts/TaskEither";
import { ReworkAddress } from "../../MortgageDashboard/domain";
import { ComponentProps } from "react";

type Props = (
  | {
      readOnly: true;
      values: AddressFormState;
      fieldProps?: never;
      errors: Option<NonEmptyArray<Issue>>;
      reworkAddress: Option<ReworkAddress>;
      displayConfirmation?: boolean;
    }
  | {
      readOnly: false;
      fieldProps: <K extends keyof AddressFormState>(
        name: K
      ) => ComputedFieldProps<AddressFormState[K]>;
      values?: never;
      citiesSuggestions: {
        status: SuggestionsStatus<CitySuggestion>;
        onSelect: (suggestion: DropdownOption<CitySuggestion>) => unknown;
      };
      streetSuggestions: {
        status: SuggestionsStatus<StreetSuggestion>;
        onSelect: (suggestion: DropdownOption<StreetSuggestion>) => unknown;
      };
      errors: Option<NonEmptyArray<Issue>>;
      submitButton: {
        label: LocalizedString;
        action: TaskEither<unknown, unknown>;
        disabled?: boolean;
      };
      cancelButton?: {
        label: LocalizedString;
        action: () => unknown;
        disabled?: boolean;
      };
      lockCountryEdit?: boolean;
      supportForeign?: boolean;
      displayConfirmation?: boolean;
    }
) & {
  lockCzSk: boolean;
  hint: Option<LocalizedString>;
};

export function AddressForm(props: Props) {
  const formatMessage = useFormatMessage();

  const reworkFeedback: ComponentProps<typeof ReadOnlyField>["feedback"] = {
    type: "warning",
    message: formatMessage("Mortgage.Dashboard.ReworkData.fieldChangedMessage"),
  };

  const renderHint = pipe(
    props.hint,
    option.map(hint => (
      <>
        <Space units={2} />
        <Banner
          type="informative"
          content={hint}
          title={option.none}
          actions={option.none}
          onDismiss={option.none}
        />
      </>
    )),
    option.toNullable
  );

  const readonlyCountryOverride = !props.readOnly && !!props.lockCountryEdit;
  const countryField = props.readOnly ? (
    <CountryDropdown
      readOnly
      value={props.values.country}
      lockCzSk={props.lockCzSk}
      feedback={pipe(
        props.reworkAddress,
        option.chain(r => r.country),
        option.map(() => reworkFeedback),
        option.toUndefined
      )}
    />
  ) : (
    <CountryDropdown
      readOnly={readonlyCountryOverride}
      supportForeign={!!props.supportForeign}
      lockCzSk={props.lockCzSk}
      {...props.fieldProps("country")}
    />
  );

  const streetNameField = props.readOnly ? (
    <ReadOnlyField
      size="medium"
      value={props.values.streetName}
      label={formatMessage("Identification.address.streetName")}
      feedback={pipe(
        props.reworkAddress,
        option.chain(r => r.streetName),
        option.map(() => reworkFeedback),
        option.toUndefined
      )}
    />
  ) : (
    <AutocompleteInputField
      {...props.fieldProps("streetName")}
      label={formatMessage("Identification.address.streetName")}
      suggestionsStatus={props.streetSuggestions.status}
      onSelectSuggestion={props.streetSuggestions.onSelect}
      placeholder={formatMessage("Identification.address.streetName")}
      loadingMessage={formatMessage("LoadingEllipsis")}
    />
  );

  const confirmedField = props.readOnly ? (
    <></>
  ) : (
    <CheckboxField
      {...props.fieldProps("confirmed")}
      label={formatMessage("Identification.permamentAddress.confirmation")}
    />
  );

  const streetNumberField = props.readOnly ? (
    <ReadOnlyField
      size="medium"
      value={props.values.streetNumber}
      label={formatMessage("Identification.address.streetNumber")}
      feedback={pipe(
        props.reworkAddress,
        option.chain(r => r.streetNumber),
        option.map(() => reworkFeedback),
        option.toUndefined
      )}
    />
  ) : (
    <TextField
      {...props.fieldProps("streetNumber")}
      label={formatMessage("Identification.address.streetNumber")}
      placeholder={formatMessage("Identification.address.streetNumber")}
    />
  );

  const cityField = props.readOnly ? (
    <ReadOnlyField
      size="medium"
      value={props.values.city}
      label={formatMessage("Identification.address.city")}
      feedback={pipe(
        props.reworkAddress,
        option.chain(r => r.city),
        option.map(() => reworkFeedback),
        option.toUndefined
      )}
    />
  ) : (
    <AutocompleteInputField
      {...props.fieldProps("city")}
      label={formatMessage("Identification.address.city")}
      suggestionsStatus={props.citiesSuggestions.status}
      onSelectSuggestion={props.citiesSuggestions.onSelect}
      placeholder={formatMessage("Identification.address.cityPlaceholder")}
      loadingMessage={formatMessage("LoadingEllipsis")}
    />
  );

  const zipCodeField = props.readOnly ? (
    <ReadOnlyField
      size="medium"
      value={props.values.zipCode}
      label={formatMessage("Identification.address.zipCode")}
      feedback={pipe(
        props.reworkAddress,
        option.chain(r => r.zipCode),
        option.map(() => reworkFeedback),
        option.toUndefined
      )}
    />
  ) : (
    <TextField
      {...props.fieldProps("zipCode")}
      label={formatMessage("Identification.address.zipCode")}
      placeholder={formatMessage("Identification.address.zipCode")}
    />
  );

  const formActions = props.readOnly
    ? {}
    : {
        cancelButton: props.cancelButton,
        submitButton: props.submitButton,
      };

  const needConfirmation =
    props.displayConfirmation != undefined && props.displayConfirmation;

  return (
    <Form data-test-id="address" {...formActions}>
      <FormSection
        errors={pipe(props.errors, option.map(nonEmptyArray.map(e => e.value)))}
      >
        <FormRow type="full">{countryField}</FormRow>
        <FormRow type="1-1">
          {cityField}
          {streetNameField}
        </FormRow>
        <FormRow type="1-1">
          {streetNumberField}
          {zipCodeField}
        </FormRow>
        {needConfirmation && <FormRow type="full">{confirmedField}</FormRow>}
        {renderHint}
      </FormSection>
    </Form>
  );
}
