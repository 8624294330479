import {
  Form,
  FormRow,
  FormSection,
  useForm,
  LocalizedString,
} from "design-system";

import { AddressWrite } from "../domain";
import { AddressSuggestionFormState } from "../addressFormUtils";
import { NonEmptyArray } from "fp-ts/NonEmptyArray";
import { TaskEither } from "fp-ts/TaskEither";
import { AddressSuggestionsDropdown } from "./AddressSuggestionsDropdown";
import { constant, pipe } from "fp-ts/function";
import { option, taskEither } from "fp-ts";
import { useFormatMessage } from "../../intl";
import { useValidators } from "../../Common/useValidators";

type Props = {
  onSubmit: (address: AddressWrite) => TaskEither<unknown, void>;
  onCancel?: () => unknown;
  addressSuggestions: NonEmptyArray<AddressWrite>;
  errorMessage: LocalizedString;
};

export function AddressSuggestionForm(props: Props) {
  const formatMessage = useFormatMessage();

  const { defined } = useValidators();

  const initialValues: AddressSuggestionFormState = {
    address: option.none,
  };
  const { fieldProps, handleSubmit } = useForm(
    {
      initialValues,
      fieldValidators: _ => ({
        address: defined(),
      }),
    },
    {
      onSubmit: ({ address }) =>
        pipe(
          props.onSubmit(address),
          taskEither.mapLeft(
            constant([
              formatMessage("Identification.validationAddress.genericError"),
            ])
          )
        ),
    }
  );

  const cancelButton = props.onCancel && {
    action: props.onCancel,
    label: formatMessage("Identification.address.cancel"),
  };

  return (
    <Form
      data-test-id="address"
      cancelButton={cancelButton}
      submitButton={{
        action: handleSubmit,
        label: formatMessage("Identification.address.save"),
      }}
    >
      <FormSection errors={option.some([props.errorMessage])}>
        <FormRow type="full">
          <AddressSuggestionsDropdown
            {...fieldProps("address")}
            addresses={props.addressSuggestions}
          />
        </FormRow>
      </FormSection>
    </Form>
  );
}
