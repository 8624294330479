import { PersonalDocument } from "../../domain";
import { option, taskEither, either } from "fp-ts";
import { useFormatMessage } from "../../../intl";
import { PersonalDocumentForm } from "./PersonalDocumentForm";
import {
  useForm,
  Box,
  Button,
  Space,
  Validator,
  validators,
  LocalizedString,
} from "design-system";
import { FormState } from "../PersonalDocumentForm/PersonalDocumentForm";
import { Option } from "fp-ts/Option";
import { DocumentType } from "../../../UploadDocuments/domain";
import { useValidators } from "../../../Common/useValidators";
import { flow, pipe } from "fp-ts/function";

type Props = {
  documentType: DocumentType;
  initialValues: FormState;
  onValidate: Validator<PersonalDocument>;
  onSave: (personalDocument: PersonalDocument) => unknown;
  onCancel: () => unknown;
  authorityMandatory: boolean;
  issuerMandatory: boolean;
};

export function PersonalDocumentFormEdit(props: Props) {
  const formatMessage = useFormatMessage();

  const { nonBlankString, defined, validDate } = useValidators();

  const { fieldProps, handleSubmit, formErrors } = useForm(
    {
      initialValues: props.initialValues,
      fieldValidators: ({ validUntil }) => ({
        idNumber: nonBlankString,
        authority: props.authorityMandatory
          ? validators.inSequence(
              validators.definedNoExtract(
                formatMessage("Form.fieldError.required")
              ),
              validators.validateIfDefined(nonBlankString)
            )
          : undefined,
        issuer: props.issuerMandatory
          ? validators.inSequence(
              validators.definedNoExtract(
                formatMessage("Form.fieldError.required")
              ),
              validators.validateIfDefined(nonBlankString)
            )
          : undefined,
        dateOfIssuing: validators.inSequence(
          defined<Option<Date>>(),
          validDate,
          validators.fromPredicate<Date>(
            p => p <= new Date(),
            formatMessage("Form.fieldError.issuingAfterToday")
          ),
          validators.fromPredicate<Date>(p => {
            return option.isSome(validUntil) && option.isSome(validUntil.value)
              ? validUntil.value.value > p
              : true;
          }, formatMessage("Form.fieldError.validUntilBeforeIssuing"))
        ),
        validUntil: pipe(
          props.initialValues.validUntil,
          option.fold(
            () =>
              validators.validator(
                option.fold(
                  () =>
                    either.right<LocalizedString, Option<Date>>(option.none),
                  () => either.left(formatMessage("Form.fieldError.notBlank"))
                )
              ),
            () =>
              validators.inSequence(
                defined(),
                validDate,
                validators.fromPredicate<Date>(
                  p => p > new Date(),
                  formatMessage("Form.fieldError.validDateExpired")
                ),
                validDate => taskEither.of(option.some(validDate))
              )
          )
        ),
      }),
    },
    {
      onSubmit: flow(
        props.onValidate,
        taskEither.chain(values =>
          taskEither.fromIO(() => props.onSave(values))
        )
      ),
    }
  );

  return (
    <Box column>
      <PersonalDocumentForm
        documentType={props.documentType}
        fieldProps={fieldProps}
        readOnly={false}
        errors={formErrors}
        canEditValidUntilField={option.isSome(props.initialValues.validUntil)}
        issue={option.none}
        reworkDocumentDetails={option.none}
        reworkClientProfile={option.none}
        authorityMandatory={props.authorityMandatory}
        issuerMandatory={props.issuerMandatory}
      />
      <Space units={5} />
      <Box grow shrink vAlignContent="center">
        <Button
          variant="text"
          size="default"
          action={props.onCancel}
          label={formatMessage("Cancel")}
        />
        <Space fluid />
        <Button
          variant="primary"
          size="default"
          action={handleSubmit}
          label={formatMessage("Save")}
        />
      </Box>
    </Box>
  );
}
