import {
  Box,
  Banner,
  Space,
  Heading,
  Divider,
  LocalizedString,
} from "design-system";
import { useFormatMessage } from "../../intl";
import { pipe, constVoid, constant, flow } from "fp-ts/function";
import { option, taskEither, task, boolean } from "fp-ts";
import { useCommand } from "../../useAPI";
import * as identificationApi from "../api";
import { AddressWrite, PersonalDocumentProofOfAddress } from "../domain";
import { ProofOfAddressForm } from "./ProofOfAddressForm";
import { TaskEither } from "fp-ts/TaskEither";

import {
  CoApplicantInput,
  GenericError,
  UploadDocumentFlowType,
} from "../../globalDomain";
import { DocumentType } from "../../UploadDocuments/domain";
import { addressWriteToAddress } from "../addressFormUtils";
import { AddressBlock } from "../Addresses/AddressBlock";
import { useParentSharedReducer } from "../../BranchExperience/useSharedReducer";
import {
  setErrorAction,
  setPermanentAddressAction,
  setEditingAction,
  shareWithClientAction,
  proofOfAddressChangeAction,
  reducerConfig,
} from "./state";
import { useBranchExperienceContext } from "../../BranchExperience/BranchExperienceContext";
import { ProofOfAddressSubmitError } from "../api";
import { ReaderTaskEither } from "fp-ts/ReaderTaskEither";
import { Option } from "fp-ts/Option";
import { NonEmptyString } from "io-ts-types/lib/NonEmptyString";
import * as ukontoApi from "../../UKontoSecondPart/api";
import { RestoreApplicationData } from "../../UKontoSecondPart/api";
import { ShareWithClientButton } from "../../Common/ShareWithClientButton/ShareWithClientButton";

type Props = {
  documentType: DocumentType;
  next: ReaderTaskEither<Option<NonEmptyString>, unknown, unknown>;
  showLocalPersonalNumber: boolean;
  isForeign: boolean;
  productType: Option<UploadDocumentFlowType>;
  restoredData?: RestoreApplicationData;
  isUkonto?: boolean;
  isUploadAgain?: boolean;
  isWithResidency?: () => boolean;
} & CoApplicantInput;

type Error =
  | "MissingPermanentAddressError"
  | "GenericError"
  | ProofOfAddressSubmitError;

export function PermanentAddress(props: Props) {
  const formatMessage = useFormatMessage();
  const permanentAddressSubmit = useCommand(
    identificationApi.permanentAddressSubmit
  );
  const proofOfAddressSubmit = useCommand(
    identificationApi.proofOfAddressSubmit
  );
  const permanentAddressUkontoSubmit = useCommand(
    ukontoApi.permanentAddressSubmit
  );
  const proofOfAddressUkontoSubmit = useCommand(ukontoApi.proofOfAddressSubmit);

  const isUkonto = props.isUkonto ? props.isUkonto : false;

  const [state, dispatch] = useParentSharedReducer(
    reducerConfig,
    props.restoredData && !props.isUploadAgain
      ? {
          shared: false,
          formInEditing: false,
          permanentAddress: option.isSome(props.restoredData.permanentAddress)
            ? option.some(props.restoredData.permanentAddress.value)
            : option.none,
          // proofOfAddress is actually in this state just for sharing data with client
          // it should not be used for other purposes
          proofOfAddress: {
            // documentType, in the same way, is used only for sharing, it is static as it never change
            // it comes from UploadDocuments extracted data
            // Child component should receive differently this kind of information
            // at the moment this is the cleanest solution to avoid putting a lot of useless informations into states
            documentType: props.documentType,
            isForeign: props.isForeign,
            document: option.isSome(props.restoredData.proofOfAddress)
              ? option.some(props.restoredData.proofOfAddress.value)
              : option.none,
          },
          error: option.none,
        }
      : {
          shared: false,
          formInEditing: false,
          permanentAddress: option.none,
          // proofOfAddress is actually in this state just for sharing data with client
          // it should not be used for other purposes
          proofOfAddress: {
            // documentType, in the same way, is used only for sharing, it is static as it never change
            // it comes from UploadDocuments extracted data
            // Child component should receive differently this kind of information
            // at the moment this is the cleanest solution to avoid putting a lot of useless informations into states
            documentType: props.documentType,
            document: option.none,
            isForeign: props.isForeign,
          },
          error: option.none,
        }
  );

  const { branchExperienceFeaturesActive } = useBranchExperienceContext();

  const setPermanentAddress = flow(setPermanentAddressAction, dispatch);
  const setError = flow(setErrorAction, dispatch);
  const setEditPermanentAddress = flow(setEditingAction, dispatch);

  const formatError = (error: Error): LocalizedString => {
    switch (error) {
      case "MissingPermanentAddressError":
        return formatMessage("Identification.permanentAddress.missingAddress");
      case "GenericError":
        return formatMessage("Identification.permanentAddress.genericError");
      default:
        return error.error;
    }
  };
  const submitPermanentAddress = (address: AddressWrite) => {
    pipe(
      pipe(
        isUkonto,
        boolean.fold(
          () =>
            permanentAddressSubmit({
              permanentAddress: address,
              coApplicant: props.coApplicant,
            }),
          () =>
            permanentAddressUkontoSubmit({
              permanentAddress: address,
              coApplicant: props.coApplicant,
            })
        )
      ),
      taskEither.fold(
        () => task.fromIO(constVoid),
        () =>
          task.fromIO(() =>
            setPermanentAddress(option.some(addressWriteToAddress(address)))
          )
      )
    )();
  };

  const onProofOfAddressChange = () => {
    if (state.shared) {
      dispatch(proofOfAddressChangeAction());
    }
  };

  const submitProofOfAddress = (
    proofOfAddress: Option<PersonalDocumentProofOfAddress>
  ): TaskEither<unknown, unknown> => {
    if (option.isSome(proofOfAddress)) {
      setError(option.none);
    }
    return pipe(
      state.permanentAddress,
      taskEither.fromOption(constant<Error>("MissingPermanentAddressError")),
      taskEither.chain(() => {
        if (branchExperienceFeaturesActive && !state.shared) {
          return taskEither.rightIO(() =>
            dispatch(
              shareWithClientAction({
                ...state.proofOfAddress,
                document: proofOfAddress,
              })
            )
          );
        }
        return option.isSome(proofOfAddress)
          ? pipe(
              pipe(
                isUkonto,
                boolean.fold(
                  () =>
                    proofOfAddressSubmit({
                      proofOfAddress: proofOfAddress.value,
                      coApplicant: props.coApplicant,
                    }),
                  () =>
                    proofOfAddressUkontoSubmit({
                      proofOfAddress: proofOfAddress.value,
                      coApplicant: props.coApplicant,
                    })
                )
              ),
              taskEither.mapLeft(e =>
                GenericError.is(e)
                  ? ("GenericError" as Error)
                  : (e as ProofOfAddressSubmitError)
              ),
              taskEither.chain(() =>
                pipe(
                  props.next(proofOfAddress.value.localPersonalNumber),
                  taskEither.mapLeft(constant<Error>("GenericError"))
                )
              )
            )
          : taskEither.fromIO(() => props.next(option.none)());
      }),
      taskEither.orElse(e => taskEither.leftIO(() => setError(option.some(e))))
    );
  };

  const displayProofOfAddress = props.isWithResidency
    ? props.isWithResidency()
    : false;

  return (
    <Box grow shrink hAlignContent="center">
      <Box column grow>
        <AddressBlock
          data-test-id="permanent_address"
          initialState={pipe(
            state.permanentAddress,
            option.map(value => ({
              ...value,
              country: option.some(value.country),
              city: value.city,
              streetNumber: value.streetNumber,
              streetName: value.streetName,
            }))
          )}
          isEditing={state.formInEditing}
          submitInvalid
          onSave={address =>
            taskEither.fromIO(() => submitPermanentAddress(address))
          }
          onRemove={option.some(() => setPermanentAddress(option.none))}
          onEdit={option.some(() => setEditPermanentAddress(true))}
          onCancel={() => setEditPermanentAddress(false)}
          title={formatMessage("Identification.permanentAddress.title")}
          subtitle={option.none}
          hint={option.none}
          disabled={false}
          tooltipContent={formatMessage(
            "Identification.permanentAddress.tooltipContent"
          )}
          addNewContent={() => (
            <Banner
              type="warning"
              content={formatMessage("Identification.permanentAddress.info")}
              title={option.none}
              actions={option.some([
                {
                  label: formatMessage("Identification.permanentAddress.add"),
                  action: () => setEditPermanentAddress(true),
                  variant: "secondary",
                },
              ])}
              onDismiss={option.none}
            />
          )}
          lockCountryEdit
          reworkAddress={option.none}
          hasResidency={props.isWithResidency}
        />
        <Space units={15} />
        <Divider />
        {displayProofOfAddress && (
          <>
            <Space units={5} />
            <Banner
              type="informative"
              title={option.none}
              content={formatMessage("Identification.proofOfAddress.info")}
              actions={option.none}
              onDismiss={option.none}
            />
            <Space units={5} />
            <Heading size="x-small" weight="medium">
              {formatMessage("Identification.proofOfAddress.title")}
            </Heading>
            <Space units={5} />
            <ProofOfAddressForm
              documentType={props.documentType}
              onSave={proofOfAddress =>
                submitProofOfAddress(option.some(proofOfAddress))
              }
              error={pipe(state.error, option.map(formatError))}
              isEditingForm={state.formInEditing}
              coApplicant={props.coApplicant}
              disabled={false}
              sharedWithClient={state.shared}
              onFormChange={onProofOfAddressChange}
              showLocalPersonalNumber={props.showLocalPersonalNumber}
              isForeign={props.isForeign}
              restoredData={props.restoredData}
              isUploadAgain={props.isUploadAgain}
            />
          </>
        )}
        <Space units={5} />
        {!displayProofOfAddress && (
          <Box hAlignContent="right">
            <ShareWithClientButton
              branchExperienceState={
                state.shared ? "sharedWithClient" : "notShared"
              }
              submitLabel={formatMessage("Continue")}
              action={
                option.isNone(state.permanentAddress)
                  ? taskEither.fromIO(constVoid)
                  : submitProofOfAddress(option.none)
              }
              disabled={option.isNone(state.permanentAddress)}
            />
          </Box>
        )}
        {state.shared && (
          <Banner
            type="informative"
            title={option.none}
            actions={option.none}
            onDismiss={option.none}
            content={formatMessage("BranchExperience.waitingConfirmation")}
          />
        )}
      </Box>
    </Box>
  );
}
