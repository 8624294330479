import { useEffect, useState } from "react";
import { useFormatDocumentType, useFormatMessage } from "../../intl";
import {
  Banner,
  Body,
  Box,
  Button,
  ComputedFieldProps,
  Divider,
  fieldIssues,
  FormErrors,
  Heading,
  Issues,
  Space,
  Stack,
  useIsMobileLayout,
  useIsTouchScreen,
} from "design-system";
import { constNull, constTrue, constVoid, flow, pipe } from "fp-ts/function";
import { nonEmptyArray, option, taskEither } from "fp-ts";
import { AddressForm } from "../AddressForm/AddressForm";
import { Option } from "fp-ts/Option";
import {
  Address,
  AddressWrite,
  AllCitizenships,
  ClientDataEdit,
  ClientDataOCR,
  PersonalData,
  PersonalDocument,
  PersonalDocumentOCR,
} from "../domain";
import { PersonalDataFormEdit } from "./PersonalDataForm/PersonalDataFormEdit";
import { PersonalDocumentFormEdit } from "./PersonalDocumentForm/PersonalDocumentFormEdit";
import {
  FormState as PersonalDataFormState,
  formStateFromPersonalData,
  formStateFromPersonalDataOCR,
  PersonalDataForm,
} from "./PersonalDataForm/PersonalDataForm";
import {
  FormState as PersonalDocumentFormState,
  formStateFromPersonalDocument,
  formStateFromPersonalDocumentOCR,
  PersonalDocumentForm,
} from "./PersonalDocumentForm/PersonalDocumentForm";
import {
  AddressFormState,
  addressWriteToAddress,
  formStateFromAddress,
  formStateFromAddressOCR,
  useDefaultCountryCode,
} from "../addressFormUtils";
import { AddressFormEdit } from "../AddressForm/AddressFormEdit";
import { TaskEither } from "fp-ts/TaskEither";
import {
  CountryCode,
  DocumentIdentificationFlow,
  DocumentPurpose,
  GenericError,
  UploadDocumentFlowType,
} from "../../globalDomain";
import { NonEmptyArray } from "fp-ts/NonEmptyArray";

import * as uploadIdApi from "../api";
import { ClientDataErrors } from "./ConfirmClientData";
import { AdditionalPersonalDataForm } from "./AdditionalPersonalDataForm/AdditionalPersonalDataForm";

import { DocumentType } from "../../UploadDocuments/domain";
import { ExtractedDataResult } from "../../UploadDocuments/types";
import { AddressSuggestionsForm } from "./AddressSuggestionsForm";
import { isForeign } from "../utils";
import { SecondCitizenshipField } from "./PersonalDataForm/SecondCitizenshipField";
import { useAppContext } from "../../useAppContext";

export type AdditionalPersonalDataProps =
  | {
      askAdditionalPersonalData: true;
      additionalPersonalData: {
        countryOfBirthFieldProps: ComputedFieldProps<Option<CountryCode>>;
      };
    }
  | {
      askAdditionalPersonalData: false;
    };

export type AddressSuggestionsProps =
  | {
      mustSelectAddressSuggestion: true;
      addressSuggestionProps: ComputedFieldProps<Option<AddressWrite>>;
      addressSuggestions: NonEmptyArray<AddressWrite>;
    }
  | { mustSelectAddressSuggestion: false };

export type SecondCitizenshipProps =
  | {
      secondCitizenshipRadio: ComputedFieldProps<Option<boolean>>;
      secondCitizenshipField: ComputedFieldProps<Option<AllCitizenships>>;
      isChild?: false;
    }
  | {
      secondCitizenshipField: Option<AllCitizenships>;
      isChild: true;
    };

type Props = {
  documentIdentificationFlow: Exclude<
    DocumentIdentificationFlow,
    "PrimaryAndLivenessCheck"
  >;
  extractedData: ExtractedDataResult;
  canEdit: boolean;
  onEdit: (
    newData: ClientDataEdit,
    idType: DocumentPurpose,
    subform: keyof ClientDataEdit
  ) => TaskEither<GenericError | uploadIdApi.PersonalInfoEditError, unknown>;
  setIsEditing: (state: boolean) => unknown;
  errors: Option<ClientDataErrors>;
  onDataChange: () => unknown;
  productType: Option<UploadDocumentFlowType>;
  supportForeign?: boolean;
  canAutoEdit: boolean;
  isChild?: boolean;
} & AdditionalPersonalDataProps &
  AddressSuggestionsProps &
  SecondCitizenshipProps;

type EditedData = {
  personalData: Option<PersonalData>;
  documentDetails: Option<PersonalDocument>;
  additionalDocumentDetails: Option<PersonalDocument>;
  permanentAddress: Option<Address>;
};

type Form = keyof ClientDataOCR | "additionalDocumentDetails";

export function ClientDataForm(props: Props) {
  const {
    config: { r6NewSironMapping: newSironMapping },
  } = useAppContext();
  const formatMessage = useFormatMessage();
  const formatDocumentType = useFormatDocumentType();
  const isMobileLayout = useIsMobileLayout();

  const [formInEdit, setFormInEdit] = useState<Option<Form>>(option.none);

  useEffect(() => {
    if (
      option.isSome(props.extractedData.addressSuggestions) &&
      props.canEdit
    ) {
      editForm("permanentAddress")();
    }
  }, []);

  const [editedData, setEditedData] = useState<EditedData>({
    personalData: option.none,
    documentDetails: option.none,
    additionalDocumentDetails: option.none,
    permanentAddress: option.none,
  });
  const documentPurposeFromIdentificationFlow =
    props.documentIdentificationFlow === "PrimaryAndSecondary"
      ? "Primary"
      : props.documentIdentificationFlow;

  const editForm = (form: Form) => () => {
    if (option.isNone(formInEdit)) {
      setFormInEdit(option.some(form));
      props.setIsEditing(true);
    }
  };

  const onCancelEdit = () => {
    setFormInEdit(option.none);
    props.setIsEditing(false);
  };

  const isEditing = (form: Form) =>
    pipe(
      formInEdit,
      option.exists(f => f === form)
    );

  const personalDataFormState: PersonalDataFormState = pipe(
    editedData.personalData,
    option.fold(
      () => formStateFromPersonalDataOCR(props.extractedData.personalData),
      formStateFromPersonalData
    )
  );

  const getDocumentDetailsFormState = (
    document: PersonalDocumentOCR,
    isAdditional: boolean
  ): PersonalDocumentFormState => {
    const getFormState = option.fold(
      () => formStateFromPersonalDocumentOCR(document),
      formStateFromPersonalDocument
    );
    return getFormState(
      isAdditional
        ? editedData.additionalDocumentDetails
        : editedData.documentDetails
    );
  };

  const defaultCountry = useDefaultCountryCode();

  const isPersonalProfile = pipe(
    props.productType,
    option.exists(item => item === "PersonalProfile")
  );

  const permanentAddress: Option<AddressFormState> = pipe(
    props.extractedData.permanentAddress,
    option.map(permanentAddressFromOCR => {
      return pipe(
        editedData.permanentAddress,
        option.fold(
          () =>
            formStateFromAddressOCR(permanentAddressFromOCR, defaultCountry),
          formStateFromAddress
        )
      );
    })
  );

  const setPermanentAddress = (
    values: AddressWrite
  ): TaskEither<Issues, AddressWrite> =>
    pipe(
      values,
      addressWriteToAddress,
      onFormSave("permanentAddress", documentPurposeFromIdentificationFlow),
      taskEither.bimap(formatEditErrors, () => values)
    );

  const formatEditErrors = (
    errors: uploadIdApi.PersonalInfoEditError | GenericError
  ): Issues => {
    if (GenericError.is(errors)) {
      return fieldIssues.errors([
        formatMessage("Identification.UploadDocuments.genericError"),
      ]);
    }
    return fieldIssues.errors(errors);
  };

  function onFormSave<T extends keyof ClientDataEdit>(
    subform: T,
    idType: DocumentPurpose
  ): (
    newEditedData: NonNullable<ClientDataEdit[T]>
  ) => TaskEither<GenericError | uploadIdApi.PersonalInfoEditError, void> {
    return newEditedData => {
      if (!props.canEdit) return taskEither.fromIO(constVoid);
      const newData: ClientDataEdit = { [subform]: newEditedData };
      const localSubForm =
        subform === "documentDetails"
          ? props.documentIdentificationFlow === "PrimaryAndSecondary"
            ? idType === "Primary"
              ? "documentDetails"
              : "additionalDocumentDetails"
            : "documentDetails"
          : subform;
      return pipe(
        props.onEdit(newData, idType, subform),
        taskEither.chain(() =>
          taskEither.fromIO(() => {
            props.onDataChange();
            setEditedData({
              ...editedData,
              [localSubForm]: option.some(newEditedData),
            });
            onCancelEdit();
          })
        )
      );
    };
  }

  const renderDocumentDetailsForm = (
    personalDocument: PersonalDocumentOCR,
    documentType: DocumentType,
    isAdditional: boolean
  ) => {
    const formKey = isAdditional
      ? "additionalDocumentDetails"
      : "documentDetails";

    const idType = isAdditional
      ? "Secondary"
      : documentPurposeFromIdentificationFlow;
    return (
      <>
        <Box vAlignContent="center">
          <Heading size="x-small" weight="medium">
            {formatDocumentType(documentType)}
          </Heading>
          <Space fluid />
          {props.canEdit && !isEditing(formKey) && (
            <Button
              variant="text"
              size="default"
              label={formatMessage("Identification.clientData.edit")}
              action={editForm(formKey)}
              disabled={option.isSome(formInEdit) && !isEditing(formKey)}
            />
          )}
        </Box>
        <Space units={10} />
        {/* // TODO(gabro): can we merge document title here? */}
        {isEditing(formKey) ? (
          <PersonalDocumentFormEdit
            documentType={documentType}
            initialValues={getDocumentDetailsFormState(
              personalDocument,
              isAdditional
            )}
            onValidate={values =>
              pipe(
                values,
                onFormSave("documentDetails", idType),
                taskEither.bimap(formatEditErrors, () => values)
              )
            }
            onSave={onCancelEdit}
            onCancel={onCancelEdit}
            authorityMandatory={personalDocument.authorityMandatory}
            issuerMandatory={personalDocument.issuerMandatory}
          />
        ) : (
          <PersonalDocumentForm
            documentType={documentType}
            readOnly
            values={getDocumentDetailsFormState(personalDocument, isAdditional)}
            errors={pipe(
              props.errors,
              option.chain(e =>
                isAdditional ? e.additionalDocumentDetails : e.documentDetails
              ),
              option.map(fieldIssues.errors)
            )}
            issue={option.none}
            reworkDocumentDetails={option.none}
            reworkClientProfile={option.none}
            authorityMandatory={personalDocument.authorityMandatory}
            issuerMandatory={personalDocument.issuerMandatory}
          />
        )}
      </>
    );
  };

  const isTouchScreen = useIsTouchScreen();

  useEffect(() => {
    if (
      isForeign(personalDataFormState.citizenship) &&
      props.canAutoEdit &&
      (personalDataFormState.placeOfBirth.length === 0 ||
        option.isNone(personalDataFormState.sex))
    ) {
      editForm("personalData")();
    }
  }, []);

  return (
    <Box column grow shrink>
      {!isTouchScreen && (
        <>
          <Body size="small" weight="regular">
            {formatMessage("Identification.personalData.confirmDescription", {
              name: personalDataFormState.name,
            })}
          </Body>
          <Space units={isMobileLayout ? 8 : 14} />
        </>
      )}
      <Box vAlignContent="center">
        <Heading size="x-small" weight="medium">
          {formatMessage("Identification.personalData.personalData")}
        </Heading>
        <Space fluid />
        {props.canEdit && !isEditing("personalData") && (
          <Button
            variant="text"
            size="default"
            label={formatMessage("Identification.clientData.edit")}
            action={editForm("personalData")}
            disabled={option.isSome(formInEdit) && !isEditing("personalData")}
          />
        )}
      </Box>
      <Space units={6} />
      {isEditing("personalData") ? (
        <PersonalDataFormEdit
          initialValues={personalDataFormState}
          onValidate={values =>
            pipe(
              props.askAdditionalPersonalData
                ? {
                    ...values,
                    countryOfBirth:
                      props.additionalPersonalData.countryOfBirthFieldProps
                        .value,
                  }
                : values,
              onFormSave("personalData", documentPurposeFromIdentificationFlow),
              taskEither.bimap(formatEditErrors, () => values)
            )
          }
          onSave={onCancelEdit}
          onCancel={onCancelEdit}
          displayCountryOfBirth={!props.askAdditionalPersonalData}
          supportForeign={props.supportForeign === true}
          documentType={props.extractedData.documentType}
        />
      ) : (
        <PersonalDataForm
          readOnly
          values={personalDataFormState}
          errors={pipe(
            props.errors,
            option.chain(e => e.personalData),
            option.map(nonEmptyArray.map(fieldIssues.error))
          )}
          displayCountryOfBirth={!props.askAdditionalPersonalData}
          reworkPersonalData={option.none}
          documentType={props.extractedData.documentType}
          secondCitizenship={option.none}
        />
      )}
      {newSironMapping && (
        <>
          <Space units={6} />
          {pipe(
            props.productType,
            option.fold(constTrue, type => type !== "PersonalProfile")
          ) &&
            (!!props.isChild ? (
              <SecondCitizenshipField {...props} readonly />
            ) : (
              <SecondCitizenshipField {...props} />
            ))}
        </>
      )}
      <Space units={isMobileLayout ? 8 : 15} />
      {props.askAdditionalPersonalData && (
        <>
          <Divider width="100%" />
          <Space units={isMobileLayout ? 8 : 15} />
          <Heading size="x-small" weight="medium">
            {formatMessage("Identification.additionalPersonalData.title")}
          </Heading>
          <Space units={6} />
          <AdditionalPersonalDataForm
            countryOfBirthFieldProps={{
              ...props.additionalPersonalData.countryOfBirthFieldProps,
              onChange: value => {
                props.onDataChange();
                props.additionalPersonalData.countryOfBirthFieldProps.onChange(
                  value
                );
              },
            }}
          />
          <Space units={isMobileLayout ? 8 : 15} />
        </>
      )}
      <Divider width="100%" />
      <Space units={isMobileLayout ? 8 : 15} />
      <Heading size="x-small" weight="medium">
        {formatMessage("Identification.personalData.documents")}
      </Heading>
      <Space units={2} />
      <Body size="medium" weight="regular">
        {formatMessage("Identification.personalData.documentsDescription")}
      </Body>
      <Space units={10} />
      <Stack units={10} column>
        {renderDocumentDetailsForm(
          props.extractedData.documentDetails,
          props.extractedData.documentType,
          false
        )}
        {pipe(
          props.extractedData.additionalDocumentDetails,
          option.fold(constNull, details =>
            pipe(
              props.extractedData.additionalDocumentType,
              option.fold(constNull, type =>
                renderDocumentDetailsForm(details, type, true)
              )
            )
          )
        )}
      </Stack>
      <Space units={14} />
      {pipe(
        permanentAddress,
        option.fold(
          () => (
            <>
              {!isPersonalProfile && (
                <Box column>
                  <Space units={10} />
                  <Banner
                    type="informative"
                    title={option.none}
                    content={formatMessage(
                      "Identification.personalData.permanentAddressBannerInfo"
                    )}
                    actions={option.none}
                    onDismiss={option.none}
                  />
                  <Space units={10} />
                </Box>
              )}
            </>
          ),
          permanentAddress => (
            <>
              <Divider width="100%" />
              <Space units={14} />
              <Box vAlignContent="center">
                <Heading size="x-small" weight="medium">
                  {formatMessage(
                    "Identification.personalData.permanentAddress"
                  )}
                </Heading>
                <Space fluid />
                {props.canEdit && !isEditing("permanentAddress") && (
                  <Button
                    variant="text"
                    size="default"
                    label={formatMessage("Identification.clientData.edit")}
                    action={editForm("permanentAddress")}
                    disabled={
                      option.isSome(formInEdit) &&
                      !isEditing("permanentAddress")
                    }
                  />
                )}
              </Box>
              <Space units={10} />
              {isEditing("permanentAddress") ? (
                <AddressFormEdit
                  initialValues={option.some(permanentAddress)}
                  onValidate={setPermanentAddress}
                  onSuggestionSubmit={flow(
                    setPermanentAddress,
                    taskEither.chain(() => taskEither.fromIO(onCancelEdit))
                  )}
                  onSubmit={() => taskEither.fromIO(onCancelEdit)}
                  onCancel={onCancelEdit}
                  hint={option.none}
                  lockCountryEdit
                  forceValidateForForeign={false}
                  lockCzSk={false}
                />
              ) : props.mustSelectAddressSuggestion ? (
                <AddressSuggestionsForm
                  suggestionProps={props.addressSuggestionProps}
                  addressSuggestions={props.addressSuggestions}
                  errorMessage={formatMessage(
                    "Identification.validationAddress.invalidExtractedAddressSuggestion"
                  )}
                />
              ) : (
                <AddressForm
                  readOnly
                  values={permanentAddress}
                  hint={option.none}
                  errors={pipe(
                    props.errors,
                    option.chain(e => e.permanentAddress),
                    option.map(nonEmptyArray.map(fieldIssues.error))
                  )}
                  reworkAddress={option.none}
                  lockCzSk={false}
                />
              )}
              <Space units={isMobileLayout ? 8 : 15} />
            </>
          )
        )
      )}
      <FormErrors
        errors={pipe(
          props.errors,
          option.chain(e => e.generalError)
        )}
      />
    </Box>
  );
}
