import * as t from "io-ts";
import { eq } from "fp-ts";
import { apiCall } from "../../APICall";
import { constFalse, constTrue } from "fp-ts/function";
import { NonEmptyString } from "io-ts-types/lib/NonEmptyString";
import { optionFromNullable } from "io-ts-types/lib/optionFromNullable";

const CEXType = t.type({
  fullName: t.string,
  id: NonEmptyString,
});

export type CEXType = t.TypeOf<typeof CEXType>;

const OPUType = t.type({
  branchCode: NonEmptyString,
  branchName: t.string,
  branchAddress: t.string,
});

export type OPUType = t.TypeOf<typeof OPUType>;

const getBranchInfoOutput = t.type({
  branchCode: NonEmptyString,
  branchAddress: t.string,
  cexCode: optionFromNullable(NonEmptyString),
});

type getBranchInfoOutput = t.TypeOf<typeof getBranchInfoOutput>;

export const getBankerBranchInfo = apiCall({
  path: ["authorization", "banker", "getBranchInfo"],
  inputCodec: t.void,
  inputEq: eq.fromEquals(constFalse),
  outputCodec: getBranchInfoOutput,
});

export const get3PBranchInfo = apiCall({
  path: ["authorization", "3P", "getBranchInfo"],
  inputCodec: t.void,
  inputEq: eq.fromEquals(constFalse),
  outputCodec: getBranchInfoOutput,
});

const GetCEXListOutput = t.array(CEXType);

type GetCEXListOutput = t.TypeOf<typeof GetCEXListOutput>;

export const getCEXList = apiCall({
  path: ["utilities", "barcex", "noauth", "list"],
  inputCodec: t.void,
  inputEq: eq.fromEquals(constTrue),
  outputCodec: GetCEXListOutput,
});

const GetOPUListOutput = t.array(OPUType);

type GetOPUListOutput = t.TypeOf<typeof GetOPUListOutput>;

export const getOPUList = apiCall({
  path: ["utilities", "branchcollection", "noauth", "list"],
  inputCodec: t.void,
  inputEq: eq.fromEquals(constTrue),
  outputCodec: GetOPUListOutput,
});

const EditBranchInfoInput = t.type({
  branchCode: NonEmptyString,
  cexCode: NonEmptyString,
});

type EditBranchInfoInput = t.TypeOf<typeof EditBranchInfoInput>;

export const editBankerBranchInfo = apiCall({
  path: ["authorization", "banker", "editBranchInfo"],
  inputCodec: EditBranchInfoInput,
  inputEq: eq.fromEquals(constFalse),
  outputCodec: t.unknown,
});

export const edit3PBranchInfo = apiCall({
  path: ["authorization", "3P", "editBranchInfo"],
  inputCodec: EditBranchInfoInput,
  inputEq: eq.fromEquals(constFalse),
  outputCodec: t.unknown,
});
