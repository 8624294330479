import { useEffect, useState } from "react";
import { useFormatMessage } from "../../intl";
import {
  Box,
  Card,
  Stack,
  Heading,
  Button,
  Dialog,
  FormSection,
  FormRow,
  Form,
  ReadOnlyField,
  DropdownOption,
  Banner,
  ContentRow,
  useForm,
} from "design-system";
import * as remoteData from "../../RemoteData";

import { option, taskEither } from "fp-ts";
import { Option } from "fp-ts/Option";
import { constNull, pipe, flow, constant } from "fp-ts/function";
import * as opuAndCexApi from "./api";
import { useValidators } from "../../Common/useValidators";
import { DropdownField } from "../../Common/DropdownField/DropdownField";
import { useCommand, useQuery } from "../../useAPI";
import { sequenceS } from "fp-ts/Apply";
import { NonEmptyString } from "io-ts-types/lib/NonEmptyString";
import { usePortalStatusContext } from "../../PortalStatusContext";

type Props = {
  onOpuAndCexComplete: (success: Option<NonEmptyString>) => void;
  showOpuAndCexError: boolean;
  isThirdParty?: boolean;
  disableEdit: boolean;
};

export const BankerOPUAndCEXCard = (props: Props) => {
  const { defined } = useValidators();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const { portalBlocked } = usePortalStatusContext();
  const formatMessage = useFormatMessage();
  const [branchInfo, refreshBranchInfo] = useQuery(
    props.isThirdParty
      ? opuAndCexApi.get3PBranchInfo
      : opuAndCexApi.getBankerBranchInfo
  );
  const [cexList, refreshCexList] = useQuery(opuAndCexApi.getCEXList);
  const [opuList, refreshOpuList] = useQuery(opuAndCexApi.getOPUList);
  const updateBranchInfo = useCommand(
    props.isThirdParty
      ? opuAndCexApi.edit3PBranchInfo
      : opuAndCexApi.editBankerBranchInfo
  );

  const refreshOPUAndCEXData = () => {
    refreshBranchInfo();
    refreshCexList();
    refreshOpuList();
  };

  const opuToLabel = (opu: opuAndCexApi.OPUType) =>
    formatMessage("Identification.OPUandCEX.OPU.info", {
      branchCode: opu.branchCode,
      branchAddress: opu.branchAddress,
    });

  const opuToDropdownOption = (
    opu: opuAndCexApi.OPUType
  ): DropdownOption<NonEmptyString> => ({
    value: opu.branchCode,
    label: opuToLabel(opu),
  });

  const cexToLabel = (cex: opuAndCexApi.CEXType) =>
    formatMessage("Identification.OPUandCEX.CEX.info", {
      cexId: cex.id,
      cexName: cex.fullName,
    });

  const cexToDropdownOption = (
    cex: opuAndCexApi.CEXType
  ): DropdownOption<NonEmptyString> => ({
    value: cex.id,
    label: cexToLabel(cex),
  });

  const { handleSubmit, fieldProps, setValues, values } = useForm(
    {
      initialValues: {
        opu: option.none as Option<DropdownOption<NonEmptyString>>,
        cex: option.none as Option<DropdownOption<NonEmptyString>>,
      },
      fieldValidators: _ => ({
        opu: defined(),
        cex: defined(),
      }),
    },
    {
      onSubmit: flow(
        data =>
          updateBranchInfo({
            branchCode: data.opu.value,
            cexCode: data.cex.value,
          }),
        taskEither.chain(() =>
          taskEither.fromIO(() => {
            refreshBranchInfo();
            setIsDialogOpen(false);
          })
        )
      ),
    }
  );
  const setFormValuesToFetchedValues = () => {
    pipe(
      sequenceS(remoteData.remoteData)({
        branchInfo,
        cexList,
        opuList,
      }),
      remoteData.fold(constNull, constNull, data => {
        const {
          branchInfo: { branchCode, cexCode: cexCodeOption },
          cexList,
          opuList,
        } = data;

        const fullCEX = pipe(
          cexCodeOption,
          option.fold(constNull, cexCode =>
            cexList.find(cexItem => cexItem.id === cexCode)
          )
        );
        const fullOPU = opuList.find(
          opuItem => opuItem.branchCode === branchCode
        );

        setValues({
          opu: !fullOPU
            ? values.opu
            : option.some(opuToDropdownOption(fullOPU)),
          cex: !fullCEX
            ? values.cex
            : option.some(cexToDropdownOption(fullCEX)),
        });
      })
    );
  };
  useEffect(() => {
    setFormValuesToFetchedValues();
  }, [branchInfo, cexList, opuList]);
  const dismissModal = () => {
    setFormValuesToFetchedValues();
    setIsDialogOpen(false);
  };

  const renderReadonlyForm = (data: { opuLabel: string; cexLabel: string }) => (
    <FormSection>
      <FormRow type="1-1">
        <ReadOnlyField
          label={formatMessage("Identification.OPUandCEX.OPU.title")}
          value={data.opuLabel}
          size="medium"
        />
        <ReadOnlyField
          label={formatMessage("Identification.OPUandCEX.CEX.title")}
          value={data.cexLabel}
          size="medium"
        />
      </FormRow>
    </FormSection>
  );

  const opuOptions: Array<DropdownOption<NonEmptyString>> = pipe(
    opuList,
    remoteData.fold(constant([]), constant([]), list =>
      list.map(opuToDropdownOption)
    )
  );

  const cexOptions: Array<DropdownOption<NonEmptyString>> = pipe(
    cexList,
    remoteData.fold(constant([]), constant([]), list =>
      list.map(cexToDropdownOption)
    )
  );

  const opuFieldProps = fieldProps("opu");
  const cexFieldProps = fieldProps("cex");

  useEffect(() => {
    pipe(
      branchInfo,
      remoteData.fold(constNull, constNull, data => {
        const { cexCode: cexCodeOption } = data;
        props.onOpuAndCexComplete(cexCodeOption);
      })
    );
  }, [branchInfo]);

  return (
    <>
      {pipe(
        sequenceS(remoteData.remoteData)({
          branchInfo,
          cexList,
          opuList,
        }),
        remoteData.fold(
          constNull,
          () => (
            <ContentRow type="lateral-margins">
              <Box column grow shrink>
                <Banner
                  type="error"
                  title={option.none}
                  content={formatMessage(
                    "Identification.OPUandCEX.requestError"
                  )}
                  actions={option.some([
                    {
                      label: formatMessage(
                        "Identification.OPUandCEX.requestError.reloadButton"
                      ),
                      action: refreshOPUAndCEXData,
                      variant: "secondary",
                    },
                  ])}
                />
              </Box>
            </ContentRow>
          ),
          data => {
            const {
              branchInfo: { branchCode, cexCode: cexCodeOption },
              cexList,
              opuList,
            } = data;

            const fullCEX = pipe(
              cexCodeOption,
              option.fold(constNull, cexCode =>
                cexList.find(cexItem => cexItem.id === cexCode)
              )
            );

            const fullOPU =
              opuList.find(opuItem => opuItem.branchCode === branchCode) ||
              null;
            return (
              <>
                <ContentRow type="lateral-margins">
                  <Card>
                    <Stack shrink column hAlignContent="right">
                      <Stack column units={4} hAlignContent="left">
                        <Heading size="x-small" weight="medium">
                          {formatMessage("Identification.OPUandCEX.title")}
                        </Heading>
                        {renderReadonlyForm({
                          opuLabel: fullOPU ? opuToLabel(fullOPU) : "-",
                          cexLabel: fullCEX ? cexToLabel(fullCEX) : "-",
                        })}
                        {props.showOpuAndCexError && (
                          <Banner
                            type="error"
                            title={option.none}
                            content={formatMessage(
                              "Identification.OPUandCEX.IncorrectInfo"
                            )}
                            actions={option.none}
                          />
                        )}
                      </Stack>
                      <Button
                        variant="secondary"
                        label={formatMessage(
                          "Identification.OPUandCEX.editButton"
                        )}
                        size="default"
                        action={() => setIsDialogOpen(true)}
                        disabled={props.disableEdit || portalBlocked}
                      />
                    </Stack>
                  </Card>
                </ContentRow>
              </>
            );
          }
        )
      )}
      {isDialogOpen && (
        <Dialog
          variant="left"
          title={formatMessage("Identification.OPUandCEX.edit.title")}
          onDismiss={option.some(dismissModal)}
          actions={[
            {
              variant: "secondary",
              label: formatMessage("Cancel"),
              action: dismissModal,
            },
            {
              variant: "primary",
              label: formatMessage("Save"),
              action: handleSubmit,
            },
          ]}
          size="small"
        >
          <Form>
            <FormSection>
              <FormRow type="3-1">
                <DropdownField
                  {...opuFieldProps}
                  label={formatMessage("Identification.OPUandCEX.OPU.title")}
                  placeholder={formatMessage(
                    "Identification.OPUandCEX.OPU.placeholder"
                  )}
                  options={opuOptions}
                  clearable={false}
                  searchable
                  dialogDescendant
                />
                <div />
              </FormRow>
              <FormRow type="3-1">
                <DropdownField
                  {...cexFieldProps}
                  label={formatMessage("Identification.OPUandCEX.CEX.title")}
                  placeholder={formatMessage(
                    "Identification.OPUandCEX.CEX.placeholder"
                  )}
                  options={cexOptions}
                  clearable={false}
                  searchable
                  dialogDescendant
                />
                <div />
              </FormRow>
            </FormSection>
          </Form>
        </Dialog>
      )}
    </>
  );
};
